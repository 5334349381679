import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';

import {
  formattedCurrency,
  goToReferenceId,
  listReferenceTable,
} from '../../../../utils/utils';
import UITablePagination from '../../../UI/Table/Pagination/UITablePagination';
import useApi from '../../../../utils/useApi';

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const initialReference = {
  referenceTable: null,
  referenceId: null,
};

const CashierLogisticTable = (children) => {
  const { data, loading, error, mobile } = children;
  const history = useHistory();

  const [reference, setReference] = useState(initialReference);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [paymentLoad, paymentInfo] = useApi({
    method: 'get',
    url: '/payments/methods',
  });

  function changeReferenceValues(values) {
    const { referenceTable, referenceId } = values;
    setReference({ referenceTable, referenceId });
  }

  // ação para click direito e esquerdo
  const [clickRight, setClickRight] = useState(initialClick);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickRight = (event, value) => {
    event.preventDefault();
    changeReferenceValues(value);
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  const handleClickLeft = (event, value) => {
    changeReferenceValues(value);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const goToPage = (referenceTableId, referenceId) => {
    const URL = goToReferenceId(referenceTableId, referenceId);
    history.push(URL);
  };

  useEffect(() => {
    paymentLoad();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Método pagamento</TableCell>
            <TableCell>Tipo de conta</TableCell>
            <TableCell>Concuido em</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <CircularProgress />}
          {data &&
            paymentInfo.data &&
            data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((value) => (
                <TableRow
                  tabIndex={-1}
                  onDoubleClick={() =>
                    goToPage(
                      value.reference.referenceTable,
                      value.reference.referenceId,
                    )
                  }
                  onContextMenu={
                    !mobile
                      ? (ev) => handleClickRight(ev, value.reference)
                      : undefined
                  }
                  style={{ cursor: 'context-menu' }}
                  onClick={
                    mobile
                      ? (ev) => handleClickLeft(ev, value.reference)
                      : undefined
                  }
                  hover
                  key={value.move}
                >
                  <TableCell component="th" scope="row">
                    {value.move}
                  </TableCell>
                  <TableCell>{formattedCurrency(value.value)}</TableCell>
                  <TableCell>
                    {
                      paymentInfo.data.find(
                        (method) => method.id === value.reference.paymentMethod,
                      ).name
                    }
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {listReferenceTable(
                      value.reference.referenceTable || value.referenceTable,
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {value.moment}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={() =>
                        goToReferenceId(
                          value.reference.referenceTable,
                          value.reference.referenceId,
                        )
                      }
                    >
                      <IconButton title="Ir para transação">
                        <CallMadeIcon />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          <Menu
            keepMounted
            open={clickRight.mouseY !== null}
            onClose={handleCloseClickRight}
            anchorReference="anchorPosition"
            anchorPosition={
              clickRight.mouseY !== null && clickRight.mouseX !== null
                ? {
                    top: clickRight.mouseY,
                    left: clickRight.mouseX,
                  }
                : undefined
            }
          >
            <MenuItem
              onClick={() =>
                goToPage(reference.referenceTable, reference.referenceId)
              }
            >
              Ir para transação
            </MenuItem>
          </Menu>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleCloseClickLeft()}
          >
            <MenuItem
              onClick={() =>
                goToPage(reference.referenceTable, reference.referenceId)
              }
            >
              Ir para transação
            </MenuItem>
          </Menu>
          {error && (
            <TableRow>
              <TableCell>Sem resultados encotrados</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <UITablePagination
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default CashierLogisticTable;
