import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import StoreContext from '../../Store/context';
import UIDialogTitle from '../Dialog/Title/UIDialogTitle';

const UIModalCashierOpen = () => {
  const { credencials, setCredencials, modalCashierOpen, setModalCashierOpen } =
    useContext(StoreContext);
  const { open, cashierId, isOpen } = modalCashierOpen;
  const history = useHistory();

  function closeModal() {
    setModalCashierOpen({ open: false, cashierId: null, isOpen: null });
  }

  function onSubmit() {
    setCredencials({ ...credencials, cashSession: cashierId });
    closeModal();
    history.push('/home/caixas/detalhes');
  }

  return (
    <Dialog fullWidth open={open} onClose={closeModal}>
      <UIDialogTitle id="form-dialog-title" onClose={closeModal}>
        Atenção!
      </UIDialogTitle>
      <DialogContent>
        <Typography paragraph>
          Ao ir para a tela de {isOpen ? 'abertura' : 'fechamento'} de caixa,
          <strong>
            {' '}
            automaticamente o caixa de sessão passará a ser o escolhido
          </strong>
          . Ir para de tela {isOpen ? 'abertura' : 'fechamento'} de caixa?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button className="UIModal__button-delete" onClick={closeModal}>
          Cancelar
        </Button>
        <Button className="UIModal__button-search" onClick={onSubmit}>
          Aceitar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UIModalCashierOpen;
