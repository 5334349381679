import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

const UIModalOldForm = ({ ...children }) => (
  <Dialog open={children.open} onClose={children.changeModal}>
    <DialogTitle>Formulario incompleto</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {children.msgAlert ||
          'Há dados ainda para serem salvos, deseja continuar o cadastro?'}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={children.setFormValues} color="primary">
        {children.msgNew || 'Novo cadastro'}
      </Button>
      <Button onClick={() => children.changeModal(true)} color="primary">
        {children.msgContinue || 'Continuar cadastro'}
      </Button>
    </DialogActions>
  </Dialog>
);

export default UIModalOldForm;
