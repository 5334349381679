import React from 'react';
import PageContent from '../../../components/Main/Content/mainContent';
import SaleNew from '../../../components/Sale/New/saleNew';

const VendaNova = () => (
  <PageContent>
    <SaleNew />
  </PageContent>
);

export default VendaNova;
