import { Typography } from '@material-ui/core';
import React from 'react';
import './UIFilter.css';

// eslint-disable-next-line
const UIFilter = ({ ...children }) => (
  <Typography className="UIFilter" onClick={children.onClick || false}>
    Filtrar +
  </Typography>
);

export default UIFilter;
