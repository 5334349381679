import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormControlLabel,
  Switch,
  TableHead,
  TableRow,
  TextField,
  Hidden,
  ListItem,
  Collapse,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ExpandLess from '@material-ui/icons/ExpandLess';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { Autocomplete } from '@material-ui/lab';

import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import UIButtonSearch from '../../UI/Button/Search/Search';
import useApi from '../../../utils/useApi';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import UITablePagination from '../../UI/Table/Pagination/UITablePagination';
import FormInputDate from '../../UI/Form/InputDate/FormInputDate';

import validationSchema from '../New/validationSchema';
import {
  emptyObject,
  formattedCurrency,
  isEqualObject,
} from '../../../utils/utils';

import useStyles from '../../../utils/useStyle';

const initialSearch = {
  name: '',
  description: '',
  value: '',
  bySystem: '0',
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const CostsSearch = (children) => {
  const [alert, setAlert] = useState(false);
  const [alertUpdate, setAlertUpdate] = useState(false);
  const { disabledTextField } = useStyles();

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState({});
  const [notificationCustomize, setNotificationCustomize] = useState(false);

  // modal para pagamentos
  const [modalPay, setModalPay] = useState(false);

  // paginação
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // verificar se é mobile
  const { mobile, collapseSearch, changeCollapseSearch, suppliersInfo } =
    children;

  // modais de pesquisa e filtragem
  const [view, setView] = useState(false);
  const [modalValues, setModalValues] = useState(null);
  const [filter, setFilter] = useState(false);

  // ação para click direito
  const [clickRight, setClickRight] = useState(initialClick);

  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/costs',
    params: { bySystem: '0' },
  });

  const [costsDetailLoad] = useApi({
    method: 'get',
    url: '/costs',
  });

  const [suppliersLoad] = useApi({
    method: 'get',
    url: '/suppliers',
  });

  const [costsPutLoad, costsPutInfo, setCostsPutInfo] = useApi({
    method: 'put',
    url: '/costs',
  });

  const [costsLoad, costsInfo, setCostsInfo] = useApi({
    method: 'patch',
    url: '/costs',
  });

  useEffect(() => {
    filterLoad();
  }, [collapseSearch]);

  const humanAttributes = (costId) => {
    const supplierLoad = (id, data, metadata) =>
      suppliersLoad({
        params: { id },
        onCompleted: (response) => {
          if (response) {
            setModalValues({
              ...data,
              metadata,
              supplier: suppliersInfo.data.find(
                (value) => value.id === response.data[0].id,
              ),
              supplierName: response.data[0].name,
              notificationTitle: '',
              dueDate: new Date(),
            });
          }
        },
      });

    costsDetailLoad({
      params: { id: costId },
      onCompleted: (res) => {
        if (res) {
          const data = res.data[0];
          const { metadata } = data;
          supplierLoad(metadata.supplier, data, metadata);
        }
      },
    });
  };

  const handleClickRight = (event, costId) => {
    event.preventDefault();
    if (costId) {
      humanAttributes(costId);
    }
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  const handleClickLeft = (event, costId) => {
    if (costId) {
      humanAttributes(costId);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  function changeValuesPayment(value, attribute) {
    if (Number(value) <= 0) {
      setModalValues({ ...modalValues, errors: 'Pagamento invalido' });
      return;
    }
    setModalValues({ ...modalValues, [attribute]: value, errors: false });
  }

  function handleModalFilter() {
    setFilter(!filter);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleModalPay(costId, isClick) {
    if (costId) {
      humanAttributes(costId);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setModalPay(!modalPay);
    setNotificationCustomize(false);
  }

  function handleModalView(costId, isClick) {
    if (costId) {
      humanAttributes(costId);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function onSearch(values) {
    const searchValues = { ...values, bySystem: values.bySystem };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
    });
    setSearch(emptyObject(searchValues));
  }

  function onPay() {
    const data = {
      costId: modalValues.id,
      supplier: modalValues.supplier.id,
      value: modalValues.value,
      notificationTitle: modalValues.notificationTitle,
      dueDate: modalValues.dueDate
    };
    costsLoad({
      data,
      onCompleted: () => {
        setAlert(!alert);
        onSearch(search);
        handleModalPay();
      },
    });
  }

  function onUpdate(values) {
    const data = {
      id: values.id,
      name: values.name,
      description: values.description,
      value: values.value,
    };
    costsPutLoad({
      data,
      onCompleted: () => {
        setAlertUpdate(!alertUpdate);
        onSearch(search);
        handleModalView();
      },
    });
  }

  return (
    <>
      <UIContainer container direction="row">
        <ListItem
          button
          onClick={changeCollapseSearch}
          style={{ justifyContent: 'space-between' }}
        >
          <UIFormTitle title="Filtrar custos" />
          {collapseSearch ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={collapseSearch}
          timeout="auto"
          unmountOnExit
          style={{ width: '100%' }}
        >
          <>
            <Formik onSubmit={onSearch} initialValues={initialSearch}>
              {({ values, submitForm, setFieldValue }) => (
                <Form style={{ width: '100%' }} onChange={() => submitForm()}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={2} md={1}>
                      <Field
                        name="id"
                        label="ID"
                        placeholder="ID"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <Field
                        name="name"
                        label="Nome"
                        placeholder="Nome do custo"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Hidden smDown>
                      <Grid item xs={12} md={3}>
                        <Field
                          name="description"
                          label="Descrição"
                          placeholder="Descrição do custo"
                          fullWidth
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CurrencyTextField
                          type="tel"
                          value={values.value}
                          label="Valor do custo"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          fullWidth
                          onChange={(ev, value) =>
                            setFieldValue('value', value)
                          }
                        />
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Custos</FormLabel>
                          <FormControlLabel
                            label={
                              values.bySystem === '1'
                                ? 'Automaticos'
                                : 'Manuais'
                            }
                            labelPlacement="start"
                            control={
                              <Switch
                                color="default"
                                checked={values.bySystem === '1'}
                                onChange={(ev) =>
                                  setFieldValue(
                                    'bySystem',
                                    ev.target.checked ? '1' : '0',
                                  )
                                }
                              />
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Hidden>
                    <Hidden mdUp>
                      <UIFilter onClick={handleModalFilter} />
                    </Hidden>
                    <Hidden smDown>
                      <UIButtonSearch xs={1} />
                    </Hidden>
                  </Grid>

                  <UIModalFilter
                    open={filter}
                    changeModal={handleModalFilter}
                    submit={submitForm}
                  >
                    <Grid item xs={12} md={3}>
                      <Field
                        name="description"
                        label="Descrição"
                        placeholder="Descrição do custo"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <CurrencyTextField
                        type="tel"
                        value={values.value}
                        label="Valor do custo"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        fullWidth
                        onChange={(ev, value) => setFieldValue('value', value)}
                      />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Custos</FormLabel>
                        <FormControlLabel
                          label={values.isActive ? 'Alt' : 'Inativas'}
                          labelPlacement="start"
                          control={
                            <Switch
                              color="default"
                              checked={values.isActive}
                              onChange={(ev) =>
                                setFieldValue('isActive', ev.target.checked)
                              }
                            />
                          }
                        />
                      </FormControl>
                    </Grid>
                  </UIModalFilter>
                </Form>
              )}
            </Formik>

            <Grid item xs style={{ marginTop: '15px' }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterInfo.loading && <CircularProgress />}
                    {filterInfo.data &&
                      filterInfo.data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((data) => (
                          <TableRow
                            onDoubleClick={() => handleModalView(data.id)}
                            onContextMenu={
                              !mobile
                                ? (ev) => handleClickRight(ev, data.id)
                                : undefined
                            }
                            style={{ cursor: 'context-menu' }}
                            hover
                            key={data.id}
                          >
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                              component="th"
                              scope="row"
                            >
                              {data.id}
                            </TableCell>
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                            >
                              {data.name}
                            </TableCell>
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                            >
                              {data.description}
                            </TableCell>
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                            >
                              {formattedCurrency(data.value)}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                title="Solicitar pagamento"
                                onClick={() => handleModalPay(data.id)}
                              >
                                <MonetizationOnIcon />
                              </IconButton>
                              <IconButton
                                title="Visualizar/Editar"
                                onClick={() => handleModalView(data.id)}
                              >
                                <SearchIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    <Menu
                      keepMounted
                      open={clickRight.mouseY !== null}
                      onClose={handleCloseClickRight}
                      anchorReference="anchorPosition"
                      anchorPosition={
                        clickRight.mouseY !== null && clickRight.mouseX !== null
                          ? {
                              top: clickRight.mouseY,
                              left: clickRight.mouseX,
                            }
                          : undefined
                      }
                    >
                      <MenuItem onClick={() => handleModalView(null, true)}>
                        Visualizar/Editar
                      </MenuItem>
                      <MenuItem onClick={() => handleModalPay(null, true)}>
                        Solicitar pagamento
                      </MenuItem>
                    </Menu>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => handleCloseClickLeft()}
                    >
                      <MenuItem onClick={() => handleModalView(null, true)}>
                        Visualizar/Editar
                      </MenuItem>
                      <MenuItem onClick={undefined}>
                        Solicitar pagamento
                      </MenuItem>
                    </Menu>
                    {filterInfo.error && (
                      <TableRow>
                        <TableCell>Sem resultados encotrados</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <UITablePagination
                  count={filterInfo.data ? filterInfo.data.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </>
        </Collapse>
      </UIContainer>

      {modalValues && (
        <>
          <Dialog
            open={view}
            onClose={() => handleModalView()}
            onExit={() => setModalValues(null)}
          >
            <Formik
              enableReinitialize
              onSubmit={onUpdate}
              initialValues={modalValues}
              validationSchema={validationSchema}
            >
              {({ values, errors, submitForm, setFieldValue }) => (
                <Form>
                  <UIDialogTitle
                    id="form-dialog-title"
                    onClose={() => handleModalView()}
                  >
                    {modalValues.name}
                  </UIDialogTitle>
                  <DialogContent>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          name="name"
                          error={Boolean(errors.name)}
                          helperText={errors.name}
                          label="Nome do custo"
                          placeholder="Informe o nome do custo"
                          fullWidth
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="description"
                          error={Boolean(errors.description)}
                          helperText={errors.description}
                          label="Descrição do custo"
                          placeholder="Informe a descrição do custo"
                          fullWidth
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="supplier"
                          className={disabledTextField}
                          disabled
                          value={modalValues.supplierName}
                          label="Fornecedor"
                          placeholder="Informe o fornecedor do custo"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CurrencyTextField
                          type="tel"
                          value={modalValues.value}
                          error={Boolean(errors.value)}
                          helperText={errors.value}
                          label="Valor do custo"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          onChange={(ev, value) =>
                            setFieldValue('value', value)
                          }
                          outputFormat="string"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CurrencyTextField
                          type="tel"
                          value={modalValues.totalPaid}
                          label="Total pago"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          className={disabledTextField}
                          disabled
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    {!isEqualObject(values, modalValues) && (
                      <Button onClick={() => handleModalView()} color="primary">
                        Cancelar
                      </Button>
                    )}
                    <Button
                      onClick={
                        isEqualObject(values, modalValues)
                          ? () => handleModalView()
                          : () => submitForm()
                      }
                      color="primary"
                    >
                      {isEqualObject(values, modalValues) ? 'Fechar' : 'Salvar'}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>

          <Dialog
            open={modalPay}
            onClose={() => handleModalPay(false)}
            onExit={() => setModalValues(null)}
          >
            <UIDialogTitle id="alert-dialog-title">
              Solicitar pagamento {modalValues.name}
            </UIDialogTitle>
            <DialogContent>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Autocomplete
                    disableClearable
                    value={modalValues.supplier}
                    noOptionsText="Sem opções"
                    disabled={suppliersInfo.loading}
                    options={
                      suppliersInfo.data !== null ? suppliersInfo.data : []
                    }
                    getOptionLabel={(supplier) => supplier.name}
                    onChange={(ev, supplier) =>
                      changeValuesPayment(supplier, 'supplier')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fornecedor"
                        name="supplier"
                        variant="standard"
                        placeholder="Informe o fornecedor do custo"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CurrencyTextField
                    type="tel"
                    value={modalValues.value}
                    error={Boolean(modalValues.errors)}
                    helperText={modalValues.errors}
                    label="Valor"
                    variant="standard"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    onChange={(ev, value) =>
                      changeValuesPayment(value, 'value')
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputDate
                    value={modalValues.dueDate}
                    label="Data de vencimento"
                    placeholder="Informe a data vencimento do custo"
                    onChange={(value) => changeValuesPayment(value, 'dueDate')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormGroup row>
                      <FormControlLabel
                        style={{ margin: 0 }}
                        label="Personalizar notificação"
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={notificationCustomize}
                            onChange={(ev, checked) =>
                              setNotificationCustomize(checked)
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                {notificationCustomize && (
                  <Grid item xs={12}>
                    <TextField
                      value={modalValues.notificationTitle}
                      label="Nome notificação"
                      name="notificationTitle"
                      placeholder="Informe o nome da notificação"
                      onChange={(ev) =>
                        changeValuesPayment(
                          ev.target.value,
                          'notificationTitle',
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item>
                <Button onClick={() => handleModalPay()} color="primary">
                  Não
                </Button>
                <Button onClick={onPay} color="primary" autoFocus>
                  Sim
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </>
      )}

      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={costsInfo}
        setrequestinfo={setCostsInfo}
        messageSucess={costsInfo.data && costsInfo.data.message}
      />
      <UIDialogConfirm
        open={alertUpdate}
        close={() => setAlertUpdate(false)}
        data={costsPutInfo}
        setrequestinfo={setCostsPutInfo}
        messageSucess={costsPutInfo.data && costsPutInfo.data.message}
      />
    </>
  );
};

export default CostsSearch;
