import React from 'react';
import { useParams } from 'react-router-dom';
import PageContent from '../../../components/Main/Content/mainContent';
import ProductSearch from '../../../components/Product/Search/productSearch';

const ProdutoPesquisar = () => {
  const { id } = useParams();
  return (
    <PageContent>
      <ProductSearch referenceId={id} />
    </PageContent>
  );
};

export default ProdutoPesquisar;
