import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  TableCell,
  TableBody,
  TableRow,
  CircularProgress,
  TableHead,
  Table,
  TableContainer,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Collapse,
  DialogActions,
  useTheme,
  useMediaQuery,
  TextField,
  Hidden,
  Dialog,
  Select,
  DialogContent,
  FormControlLabel,
  ListItem,
  InputLabel,
  FormControl,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';
import SearchIcon from '@material-ui/icons/Search';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { Field, Form, Formik } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  startOfWeek,
  startOfToday,
  startOfMonth,
  isEqual,
  addDays,
} from 'date-fns';

import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import UIContainer from '../../UI/Container/UIContainer';

import StoreContext from '../../Store/context';
import UICardSale from '../../UI/Card/Sale/UICardSale';

import {
  emptyObject,
  formattedCurrency,
  goToReferenceId,
} from '../../../utils/utils';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import useApi from '../../../utils/useApi';
import UIButtonSearch from '../../UI/Button/Search/Search';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import GreenSwitch from '../../UI/Form/Switch/GreenSwitch';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import MyDateRangePicker from '../../UI/Form/DateRangePicker/DateRangePicker';

import './saleSearch.css';
import useStyles from '../../../utils/useStyle';
import UITablePagination from '../../UI/Table/Pagination/UITablePagination';
import { useReactToPrint } from 'react-to-print';
import '../cupom.css';

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const initialSearch = {
  id: '',
  customer: null,
  products: null,
  services: null,
  promotion: null,
  value: '',
  amountPaid: '',
  plots: null,
  interest: null,
  userId: null,
  isClosed: '0',
  observation: '',
};

const initialPayment = {
  amountPaid: 0,
  plots: 1,
  paymentMethod: 6,
};

const SaleSearch = (children) => {
  const { referenceId } = children;
  const { credencials, setModalCashierSelect } = useContext(StoreContext);
  const { disabledTextField } = useStyles();
  const [alert, setAlert] = useState(false);

  // constantes para collapse na view de detalhes
  const [collapseItem, setCollapseItem] = useState(false);
  const [collapsePlots, setCollapsePlots] = useState(false);

  // verificar se é mobile e constantes para versão mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // constantes para modal de pagamentos
  const [modalPay, setModalPay] = useState(false);
  const [payment, setPayment] = useState(initialPayment);

  // modais de pesquisa e filtragem
  const [view, setView] = useState(false);
  const [modalValues, setModalValues] = useState(null);
  const [filter, setFilter] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [mySales, setMySales] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState(emptyObject(initialSearch));

  // manipular datas do range date
  const [dateValues, setDateValues] = useState({
    startDate: new Date(),
    endDate: null,
    key: 'selection',
  });

  const [filterLoad, filterInfo, setFilterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/sales',
    onCompleted: (res) => {
      if (!res) {
        setFilterInfo();
      }
    },
  });

  const [productsLoad, productsInfo] = useApi({
    method: 'get',
    url: '/products',
  });

  const [servicesLoad, servicesInfo] = useApi({
    method: 'get',
    url: '/services',
  });

  const [promotionsLoad, promotionsInfo] = useApi({
    method: 'get',
    url: '/promotions',
    params: { isActive: 1 },
  });

  const [userLoad, userInfo] = useApi({
    method: 'get',
    url: '/users',
  });

  const [clientLoad, clientInfo] = useApi({
    method: 'get',
    url: '/customers',
  });

  const [paymentLoad, paymentInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/payments/methods',
  });

  const [payLoad, payInfo, setPayInfo] = useApi({
    debounceDelay: 500,
    method: 'patch',
    url: '/sales',
  });

  const [searchLoad] = useApi({
    method: 'get',
  });

  const contentRef = useRef();
  const reactToPrintFn = useReactToPrint({ contentRef });

  function formatClient(data) {
    const client = clientInfo?.data?.find((value) => value.id === data);
    const fullName = `${client?.name || 'Cliente'} ${
      client?.lastName || 'Avulso'
    }`;
    return fullName;
  }

  function formatModalValues(data) {
    let values;
    searchLoad({
      url: '/sales',
      params: { id: data.id },
      onCompleted: (response) => {
        if (response) {
          [values] = response.data;
          const itemList = [];
          const products = Object.entries(values.products);
          const services = Object.entries(values.services);
          products.map(([id, amount]) =>
            searchLoad({
              url: '/products',
              params: { id },
              onCompleted: (res) => {
                if (res) itemList.push({ name: [res.data[0].name], amount });
              },
            }),
          );
          services.map(([id, amount]) =>
            searchLoad({
              url: '/services',
              params: { id },
              onCompleted: (res) => {
                if (res) itemList.push({ name: [res.data[0].name], amount });
              },
            }),
          );

          const { interest } = values;
          const userId = userInfo.data.find(
            (value) => value.id === values.userId,
          );
          const promotion = promotionsInfo.data.find(
            (value) => value.id === values.promotion,
          );
          const { plots } = values;
          const valuesTreated = {
            ...values,
            customer: formatClient(values.customer || 'Avulso'),
            userId: `${userId.name} ${userId.lastName || ''}`,
            interest: interest.percentage,
            promotion: promotion && promotion.name,
            products: itemList,
            plots,
          };
          setModalValues(valuesTreated);
        }
      },
    });
  }

  // ação para click direito
  const [clickRight, setClickRight] = useState(initialClick);
  const handleClickRight = (event, values) => {
    event.preventDefault();
    if (values) {
      formatModalValues(values);
    }
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickLeft = (event, values) => {
    if (values) {
      formatModalValues(values);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  function handleModalFilter() {
    setFilter(!filter);
  }

  useEffect(() => {
    if (referenceId) {
      initialSearch.id = referenceId;
    }
    const params = referenceId
      ? { id: referenceId }
      : { userId: credencials.id, isClosed: '0' };

    filterLoad({
      params,
    });
    userLoad();
    productsLoad();
    promotionsLoad();
    servicesLoad();
    clientLoad();
    paymentLoad();
  }, []);

  function handleModalPay(values, isClick) {
    if (values) {
      formatModalValues(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setModalPay(!modalPay);
  }

  function handleModalView(values, isClick) {
    if (values) {
      formatModalValues(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    const timer = setTimeout(
      () => {
        setView(!view);
        setCollapseItem(false);
        setCollapsePlots(false);
      },
      !view ? 250 : 1,
    );
    return () => clearTimeout(timer);
  }

  function onSearch(values) {
    const products = values.products
      ? values.products.map((value) => Number(value.id))
      : null;
    const services = values.services
      ? values.services.map((value) => Number(value.id))
      : null;

    const params = {
      ...values,
      customer: values.customer && values.customer.id,
      promotion: values.promotion && values.promotion.id,
      userId:
        (values.userId && values.userId.id) || (mySales && credencials.id),
      services: services && JSON.stringify(services),
      products: products && JSON.stringify(products),
      isClosed: isClosed ? '1' : '0',
      interest: values.interest === 0 ? '0' : values.interest,
      startRange:
        (dateValues.endDate && dateValues.startDate) || values.startRange,
      endRange: isEqual(dateValues.endDate, dateValues.startDate)
        ? addDays(dateValues.endDate, 1)
        : dateValues.endDate,
    };
    filterLoad({
      debounced: true,
      params: emptyObject(params),
      onCompleted: () => {
        setSearch(emptyObject(params));
      },
    });
  }

  function onChangeClosed(closed) {
    setIsClosed(closed);
    const searchValues = {
      ...search,
      isClosed: closed ? 1 : '0',
      userId: mySales && credencials.id,
    };
    filterLoad({
      params: emptyObject(searchValues),
      onCompleted: (res) => {
        if (res) {
          setSearch(emptyObject(searchValues));
        }
      },
    });
  }

  function onChangeMySale(closed) {
    setMySales(closed);
    const searchValues = {
      ...search,
      userId: closed && credencials.id,
      isClosed: isClosed ? 1 : '0',
    };
    filterLoad({
      params: emptyObject(searchValues),
      onCompleted: (res) => {
        if (res) {
          setSearch(emptyObject(searchValues));
        }
      },
    });
  }

  function changeValuesPayment(value, attribute) {
    if (Number(value) > 0) {
      setPayment({ ...payment, [attribute]: value, errors: false });
    } else {
      setPayment({ ...payment, errors: 'Pagamento invalido' });
    }
  }

  function onPay() {
    if (!credencials.cashSession) {
      setModalCashierSelect(true);
    } else if (Number(payment.amountPaid) > 0) {
      const data = {
        id: modalValues.id,
        plots: payment.plots,
        cashRegister: credencials.cashSession, // valor chumbado
        amountPaid: payment.amountPaid,
        paymentMethod: payment.paymentMethod,
      };
      payLoad({
        data,
        onCompleted: () => {
          setAlert(true);
          filterLoad({
            params: search,
          });
        },
      });
    }
  }

  return (
    <>
      <UIContainer container>
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          xs={12}
        >
          <UIFormTitle title="Filtrar vendas" />
          <UIFilter onClick={handleModalFilter} />
        </Grid>
        <Formik onSubmit={onSearch} initialValues={initialSearch}>
          {({ submitForm, values, setFieldValue }) => (
            <Form style={{ width: '100%' }} onChange={() => submitForm()}>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Hidden smDown>
                  <Grid item xs={1}>
                    <Field
                      name="id"
                      label="ID"
                      placeholder="ID"
                      fullWidth
                      as={TextField}
                    />
                  </Grid>
                </Hidden>
                <Grid item xs={5} md={2}>
                  <Autocomplete
                    clearOnEscape
                    noOptionsText="Sem opções"
                    disabled={clientInfo.loading || clientInfo.error}
                    options={clientInfo.data !== null ? clientInfo.data : []}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(client) => client.name}
                    renderOption={(option) => (
                      <>
                        {option.name} {option.lastName || ''}
                      </>
                    )}
                    onChange={(ev, value) => {
                      setFieldValue('customer', value);
                      submitForm();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="standard"
                        placeholder="Informe um cliente"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={7} md={4}>
                  <Autocomplete
                    multiple
                    noOptionsText="Sem opções"
                    disabled={productsInfo.loading}
                    options={productsInfo.data || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(ev, value) => {
                      setFieldValue('products', value);
                      submitForm();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Produtos"
                        placeholder="Informe os produtos"
                      />
                    )}
                  />
                </Grid>
                <Hidden smDown>
                  <Grid item xs={4}>
                    <MyDateRangePicker
                      label="Data"
                      submitForm={submitForm}
                      state={dateValues}
                      setState={setDateValues}
                    />
                  </Grid>
                  <UIButtonSearch xs={1} />
                </Hidden>
                <Grid container justify="center" alignItems="center">
                  <UICardSale
                    onClick={() => onSearch({ startRange: startOfToday() })}
                    name="Vendas de hoje"
                  />
                  <UICardSale
                    onClick={() =>
                      onSearch({ startRange: startOfWeek(new Date()) })
                    }
                    name="Vendas da semana"
                  />
                  <UICardSale
                    onClick={() =>
                      onSearch({ startRange: startOfMonth(new Date()) })
                    }
                    name="Vendas do mês"
                  />
                  <Hidden mdUp>
                    <Grid item>
                      <FormControlLabel
                        label={mySales ? 'Minhas vendas' : 'De todos'}
                        labelPlacement="start"
                        control={
                          <GreenSwitch
                            checked={mySales}
                            onChange={(ev) => {
                              onChangeMySale(ev.target.checked);
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label={isClosed ? 'Vendas fechadas' : 'Vendas abertas'}
                        labelPlacement="start"
                        control={
                          <GreenSwitch
                            checked={!isClosed}
                            onChange={(ev) => {
                              onChangeClosed(!ev.target.checked);
                            }}
                          />
                        }
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>

              <UIModalFilter
                open={filter}
                changeModal={handleModalFilter}
                submit={submitForm}
              >
                <Hidden mdUp>
                  <Grid item xs={2} md={1}>
                    <Field
                      name="id"
                      label="ID"
                      placeholder="ID"
                      fullWidth
                      as={TextField}
                    />
                  </Grid>
                </Hidden>

                <Grid item xs={10} md={12}>
                  <Autocomplete
                    clearOnEscape
                    noOptionsText="Sem opções"
                    disabled={userInfo.loading}
                    options={userInfo.data || []}
                    onChange={(ev, value) => {
                      setFieldValue('userId', value);
                      submitForm();
                      if (values.userId) setMySales(false);
                    }}
                    getOptionLabel={(user) => user.name}
                    renderOption={(option) => (
                      <>
                        {option.name} {option.lastName}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vendedor"
                        variant="standard"
                        placeholder="Informe um vendedor"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    clearOnEscape
                    noOptionsText="Sem opções"
                    disabled={promotionsInfo.loading}
                    options={promotionsInfo.data || []}
                    onChange={(ev, value) => {
                      setFieldValue('promotion', value);
                      submitForm();
                    }}
                    getOptionLabel={(promotion) => promotion.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Promoção"
                        variant="standard"
                        placeholder="Informe a promoção"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    noOptionsText="Sem opções"
                    disabled={servicesInfo.loading}
                    options={servicesInfo.data || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, service) =>
                      setFieldValue('services', service)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Serviços"
                        placeholder="Informe os serviços"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CurrencyTextField
                    type="tel"
                    value={values.value}
                    label="Valor da venda"
                    variant="standard"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    onChange={(ev, value) => setFieldValue('value', value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <CurrencyTextField
                    type="tel"
                    value={values.amountPaid}
                    label="Valor pago"
                    variant="standard"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    onChange={(ev, value) => setFieldValue('amountPaid', value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name="plots"
                    label="Parcelas"
                    type="number"
                    as={TextField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name="interest"
                    label="Juros"
                    type="number"
                    as={TextField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <Field
                    name="observation"
                    label="Observação"
                    placeholder="Informe uma observação"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
                <Hidden mdUp>
                  <Grid item xs={6}>
                    <Autocomplete
                      clearOnEscape
                      noOptionsText="Sem opções"
                      disabled={promotionsInfo.loading}
                      options={promotionsInfo.data || []}
                      onChange={(ev, value) => {
                        setFieldValue('promotion', value);
                        submitForm();
                      }}
                      getOptionLabel={(promotion) => promotion.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Promoção"
                          variant="standard"
                          placeholder="Informe a promoção"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MyDateRangePicker
                      label="Data"
                      submitForm={submitForm}
                      state={dateValues}
                      setState={setDateValues}
                    />
                  </Grid>
                </Hidden>
              </UIModalFilter>
            </Form>
          )}
        </Formik>
      </UIContainer>

      <UIContainer>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          xs={12}
        >
          <UIFormTitle title="Lista de vendas" />
          <Hidden smDown>
            <Grid item>
              <FormControlLabel
                label={mySales ? 'Minhas vendas' : 'De todos'}
                labelPlacement="start"
                control={
                  <GreenSwitch
                    checked={mySales}
                    onChange={(ev) => {
                      onChangeMySale(ev.target.checked);
                    }}
                  />
                }
              />
              <FormControlLabel
                label={isClosed ? 'Vendas fechadas' : 'Vendas abertas'}
                labelPlacement="start"
                control={
                  <GreenSwitch
                    checked={!isClosed}
                    onChange={(ev) => {
                      onChangeClosed(!ev.target.checked);
                    }}
                  />
                }
              />
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Horário da compra</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterInfo.loading && <CircularProgress />}
                {filterInfo.data &&
                  filterInfo.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data) => (
                      <TableRow
                        onDoubleClick={() => handleModalView(data)}
                        onContextMenu={
                          !mobile
                            ? (ev) => handleClickRight(ev, data)
                            : undefined
                        }
                        style={{ cursor: 'context-menu' }}
                        onClick={
                          mobile ? (ev) => handleClickLeft(ev, data) : undefined
                        }
                        hover
                        key={data.id}
                      >
                        <TableCell component="th" scope="row">
                          {data.id}
                        </TableCell>
                        <TableCell>
                          {formatClient(data?.customer || 'Avulso')}
                        </TableCell>
                        <TableCell>{formattedCurrency(data.value)}</TableCell>
                        <TableCell>
                          {Number(data.isClosed) === 1
                            ? 'Fechada'
                            : 'Em aberto'}
                        </TableCell>
                        <TableCell>{data.moment}</TableCell>
                        <TableCell>
                          <IconButton
                            title="Receber venda"
                            onClick={() => handleModalPay(data)}
                          >
                            <MonetizationOnIcon />
                          </IconButton>
                          <IconButton
                            title="Visualizar"
                            onClick={() => handleModalView(data)}
                          >
                            <SearchIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                <Menu
                  keepMounted
                  open={clickRight.mouseY !== null}
                  onClose={handleCloseClickRight}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    clickRight.mouseY !== null && clickRight.mouseX !== null
                      ? {
                          top: clickRight.mouseY,
                          left: clickRight.mouseX,
                        }
                      : undefined
                  }
                >
                  <MenuItem onClick={() => handleModalView(null, true)}>
                    Visualizar
                  </MenuItem>
                  <MenuItem onClick={() => handleModalPay(null, true)}>
                    Receber venda
                  </MenuItem>
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseClickLeft()}
                >
                  <MenuItem onClick={() => handleModalView(null, true)}>
                    Visualizar
                  </MenuItem>
                  <MenuItem onClick={undefined}>Receber venda</MenuItem>
                </Menu>
                {filterInfo.error && (
                  <TableRow>
                    <TableCell>Sem resultados encotrados</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <UITablePagination
              count={filterInfo.data ? filterInfo.data.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </UIContainer>

      {modalValues && (
        <>
          <Dialog open={view} onClose={() => handleModalView()}>
            <Formik enableReinitialize initialValues={modalValues}>
              {({ values }) => (
                <Form>
                  <UIDialogTitle
                    id="form-dialog-title"
                    onClose={() => handleModalView()}
                  >
                    Venda número: #{modalValues.id}
                  </UIDialogTitle>
                  <DialogContent ref={contentRef}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={6}>
                        <Field
                          name="promotion"
                          label="Promoção"
                          placeholder="Sem promoção"
                          as={TextField}
                          className={disabledTextField}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="customer"
                          label="Cliente"
                          as={TextField}
                          className={disabledTextField}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={values.moment}
                          label="Data da venda"
                          className={disabledTextField}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="userId"
                          label="Vendedor"
                          fullWidth
                          as={TextField}
                          className={disabledTextField}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CurrencyTextField
                          type="tel"
                          value={values.value}
                          label="Valor de venda"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          fullWidth
                          className={disabledTextField}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CurrencyTextField
                          type="tel"
                          value={values.amountPaid}
                          label="Valor pago"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          fullWidth
                          className={disabledTextField}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Link to={() => goToReferenceId(2, values.id)}>
                                  <IconButton title="Ir para pagamentos pendentes">
                                    <CallMadeIcon />
                                  </IconButton>
                                </Link>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CurrencyTextField
                          type="tel"
                          value={values.discount}
                          label="Desconto"
                          variant="standard"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          fullWidth
                          className={disabledTextField}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CurrencyTextField
                          type="tel"
                          value={values.increase}
                          label="Acréscimo"
                          variant="standard"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          fullWidth
                          className={disabledTextField}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          value={
                            values.plots && Object.keys(values.plots).length
                          }
                          label="Parcelas"
                          as={TextField}
                          fullWidth
                          className={disabledTextField}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="interest"
                          label="Juros"
                          placeholder="Sem juros"
                          as={TextField}
                          fullWidth
                          className={disabledTextField}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="observation"
                          label="Observação"
                          placeholder="Sem descrição"
                          fullWidth
                          as={TextField}
                          className={disabledTextField}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <strong>Itens</strong>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Produto/ Serviço</TableCell>
                            <TableCell>Quantidade</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modalValues?.products?.map(({ name, amount }) => (
                            <TableRow hover key={name}>
                              <TableCell>{name}</TableCell>
                              <TableCell>{amount}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <strong>Parcelas</strong>

                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Numero</TableCell>
                            <TableCell>Valor</TableCell>
                          </TableRow>
                          {values.plots &&
                            Object.entries(values.plots).map(([key, value]) => (
                              <TableRow hover key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{value}</TableCell>
                              </TableRow>
                            ))}
                        </TableHead>
                      </Table>
                    </Grid>
                  </DialogContent>
                  <Grid item className="sale-search__link-payment">
                    <Link to={() => goToReferenceId(2, values.id)}>
                      Ver todos os pagamentos
                    </Link>
                  </Grid>
                  <DialogActions>
                    <Button onClick={reactToPrintFn} color="primary" autoFocus>
                      Imprimir Cupom
                    </Button>
                    <Button onClick={() => handleModalView()} color="secondary">
                      Fechar
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
          <Dialog
            fullWidth
            open={modalPay}
            onExit={() => {
              setPayInfo();
              setPayment(initialPayment);
            }}
            onClose={() => setModalPay(false)}
          >
            <UIDialogTitle
              onClose={() => setModalPay(false)}
              id="alert-dialog-title"
            >
              Receber compra de {modalValues.customer || 'Avulso'}
            </UIDialogTitle>
            <DialogContent>
              <Grid container direction="row" spacing={1}>
                {payInfo.data ? (
                  <TableContainer>
                    <Typography align="center" variant="h6" component="div">
                      Resumo do pagamento
                    </Typography>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>Cliente</TableCell>
                          <TableCell>
                            {modalValues.customer || 'Avulso'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Vendedor</TableCell>
                          <TableCell>{credencials.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Valor pago</TableCell>
                          <TableCell>
                            {formattedCurrency(payment.amountPaid)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Valor restante</TableCell>
                          <TableCell>
                            {formattedCurrency(payInfo.data.valueRemaining)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {payInfo.data.remnant !== 0 && (
                            <Typography
                              variant="h6"
                              component="div"
                              color="textSecondary"
                              style={{ marginTop: '10px' }}
                            >
                              TROCO: {formattedCurrency(payInfo.data.remnant)}
                            </Typography>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel shrink id="sale-search__paymentMethod">
                          Metódo de pagamento
                        </InputLabel>
                        <Select
                          value={payment.paymentMethod}
                          onChange={(ev) =>
                            changeValuesPayment(
                              ev.target.value,
                              'paymentMethod',
                            )
                          }
                          labelId="sale-search__paymentMethod"
                          displayEmpty
                        >
                          {paymentInfo.data &&
                            paymentInfo.data.map((pay) => (
                              <MenuItem key={pay.id} value={pay.id}>
                                {pay.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={payment.plots}
                        error={Boolean(payment.errors)}
                        helperText={payment.errors}
                        label="Qntd. parcelas"
                        type="number"
                        onChange={(ev) =>
                          changeValuesPayment(ev.target.value, 'plots')
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CurrencyTextField
                        type="tel"
                        value={payment.amountPaid}
                        error={Boolean(payment.errors)}
                        helperText={payment.errors}
                        label="Valor"
                        variant="standard"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        onChange={(ev, value) =>
                          changeValuesPayment(value, 'amountPaid')
                        }
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              {payInfo.data ? (
                <Button
                  onClick={() => setModalPay(false)}
                  color="primary"
                  autoFocus
                >
                  Sair
                </Button>
              ) : (
                <>
                  <Button onClick={() => setModalPay(false)} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={onPay} color="primary" autoFocus>
                    Receber
                  </Button>
                </>
              )}
            </DialogActions>
            <UIDialogConfirm
              open={alert}
              close={() => setAlert(false)}
              data={payInfo}
              messageSucess={`Venda paga com sucesso pelo ${
                modalValues.customer || 'Cliente'
              }`}
              messageError="Ops, ocorreu um erro ao manipular a categoria"
            />
          </Dialog>
        </>
      )}
    </>
  );
};

export default SaleSearch;
