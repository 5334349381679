import { useContext, useState } from 'react';
import axios from 'axios';
import StoreContext from '../components/Store/context';
import useDebouncedPromise from './useDebouncedPromise';

const initialRequestInfo = {
  error: null,
  data: null,
  loading: false,
};

export default function useApi(config) {
  const [requestInfo, setRequestInfo] = useState(initialRequestInfo);
  const debouncedAxios = useDebouncedPromise(axios, config.debounceDelay);
  const clearValues = () => setRequestInfo(initialRequestInfo);
  const { setOpenModal, credencials } = useContext(StoreContext);

  async function call(localConfig) {
    setRequestInfo({
      ...initialRequestInfo,
      loading: true,
    });

    let response = null;

    const finalConfig = {
      baseURL: 'https://api.duzzsystem.com.br',
      headers: {
        sessionToken: credencials && credencials.sessionToken,
        company: credencials && credencials.company,
      },
      ...config,
      ...localConfig,
    };

    const fn = finalConfig.debounced ? debouncedAxios : axios;

    try {
      response = await fn(finalConfig);
      setRequestInfo({
        ...initialRequestInfo,
        data: response.data,
      });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.includes('O token fornecido é inválido.')
      ) {
        setOpenModal(true);
      }
      setRequestInfo({
        ...initialRequestInfo,
        error,
      });
    }
    if (localConfig && localConfig.onCompleted) {
      localConfig.onCompleted(response);
    }
    if (config.onCompleted) {
      config.onCompleted(response);
    }
    return response;
  }

  return [call, requestInfo, clearValues];
}
