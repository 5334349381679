import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  ListItem,
  Dialog,
  Typography,
  Button,
} from '@material-ui/core';

import Editable from 'react-editable-title';
import UIFormTitle from '../../../UI/Form/Title/UIFormTitle';
import UIContainer from '../../../UI/Container/UIContainer';
import useApi from '../../../../utils/useApi';
import SessionCard from '../Card/sessionCard';
import { emptyObject } from '../../../../utils/utils';
import SaleNew from '../NewSale/saleNew';
import StoreContext from '../../../Store/context';

const initialSaleSession = {
  onlyCalculate: true,
  identifier: 'Comanda sem nome',
  customer: 1,
  promotion: 1,
  products: {},
  services: {},
  interest: {},
  splitRemaining: 0,
};

const NewSaleSession = (children) => {
  const { collapseNew, changeCollapseNew } = children;

  const [modalSale, setModalSale] = useState(false);
  const [modalValues, setModalValues] = useState(false);
  const [cardValues, setCardValues] = useState(null);
  // const [setAdvancedSetting] = useState(false);
  const [textNewSession] = useState('Nova Comanda');
  const { credencials } = useContext(StoreContext);

  // const [saleObject, setSaleObject] = useState(initialSaleItem);

  const [salesSessionsLoad, salesSessionsInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/sales/sessions',
  });

  const [newSessionLoad] = useApi({
    debounceDelay: 500,
    method: 'post',
    url: '/sales/sessions',
  });

  function handleModalSale(values) {
    if (values) {
      const data = {
        saleMetadata: values,
      };
      setModalValues(data);
    }
    if (modalValues) setModalValues(null);
    if (modalSale) refreshCards();
    setModalSale(!modalSale);
  }

  async function refreshCards() {
    setCardValues(null);
    await salesSessionsLoad({
      onCompleted: (res) => {
        if (res) {
          const { data } = res;
          const newCardValues = data.map((value) => {
            const existsCard =
              cardValues && cardValues.find((val) => val.id === value.id);
            if (existsCard) {
              const response = { ...value, oldValue: existsCard.oldValue };
              return response;
            }
            const response = { ...value, oldValue: 0 };

            return response;
          });
          setCardValues(newCardValues);
        } else {
          setCardValues(null);
        }
      },
    });
  }

  function onSubmitNewSession(sessionName) {
    const data = emptyObject({ ...initialSaleSession });
    data.responsible = `${credencials.name} ${credencials.lastName}`;
    data.identifier = sessionName;
    newSessionLoad({
      data,
      onCompleted: () => {
        refreshCards({});
      },
    });
  }

  function setOldValues(id, currentValue) {
    const newOldValues = cardValues.find((value) => value.id === id);
    newOldValues.oldValue = currentValue;
    setCardValues(cardValues);
  }

  useEffect(() => {
    refreshCards();
  }, [collapseNew]);

  return (
    <>
      <UIContainer container direction="row">
        <ListItem
          button
          onClick={changeCollapseNew}
          style={{ justifyContent: 'space-between' }}
        >
          <Button>Recarregar</Button>

          <UIFormTitle title="Comandas" />
        </ListItem>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <div className="sessions-card__card">
              <Typography className="sessions-card__title">
                <Editable
                  text="Nova Comanda"
                  placeholder="Nova Comanda"
                  cb={onSubmitNewSession}
                />
              </Typography>
              <Typography className="sessions-card__money">
                <span onClick={() => onSubmitNewSession(textNewSession)}>Clique aqui ou edite o nome</span>
              </Typography>
            </div>
          </Grid>
          {salesSessionsInfo.loading && <CircularProgress />}
          {cardValues &&
            cardValues.map((values) => (
              <Grid item xs={12} md={6} lg={4} key={values.id}>
                <SessionCard
                  setOldValues={setOldValues}
                  data={values}
                  handleModalSale={handleModalSale}
                  refreshCards={refreshCards}
                />
              </Grid>
            ))}
        </Grid>
      </UIContainer>

      {modalValues && (
        <Dialog open={modalSale} onClose={handleModalSale} fullWidth>
          <SaleNew
            saleMetadata={modalValues.saleMetadata}
            refreshCards={refreshCards}
            handleModalSale={handleModalSale}
          />
        </Dialog>
      )}
    </>
  );
};

export default NewSaleSession;
