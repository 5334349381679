import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import validationSchema from './validationSchema';
import GreenSwitch from '../../UI/Form/Switch/GreenSwitch';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import UIFormContainerSave from '../../UI/Form/ContainerSave/UIFormContainer';
import useApi from '../../../utils/useApi';
import useStorage from '../../../utils/useStorage';
import UIModalOldForm from '../../UI/ModalOldForm/UIModalOldForm';
import { isEqualObject } from '../../../utils/utils';

const initialState = {
  name: '',
  description: '',
  customerProfile: '',
  products: [],
  services: [],
  discount: 0,
  percentage: 0,
  interestFree: 'false',
  isActive: true,
};

const PromotionNew = () => {
  const [open, setOpen] = useState(false);
  const [oldValues, setOldValues] = useStorage('formPromotion');

  const [registerLoad, registerInfo, setRegisterInfo] = useApi({
    method: 'post',
    url: '/promotions',
  });

  const [productsLoad, productsInfo] = useApi({
    method: 'get',
    url: '/products?lacking=0',
  });

  const [servicesLoad, servicesInfo] = useApi({
    method: 'get',
    url: '/services?isActive=1',
  });

  const [clientLoad, clientInfo] = useApi({
    method: 'get',
    url: '/customers/profiles',
  });

  function changeModal() {
    setOpen(!open);
  }

  function setFormValues() {
    setOldValues(null);
    changeModal();
  }

  function updateStorage(values) {
    setOldValues(values);
  }

  useEffect(() => {
    clientLoad();
    productsLoad();
    servicesLoad();
    if (oldValues && !isEqualObject(oldValues, initialState)) {
      changeModal();
    }
  }, []);

  function onSubmit(values) {
    const valuesPromotion = {
      ...values,
      products: values.products.map((product) => product.id),
      services: values.services.map((service) => service.id),
      interestFree: values.interestFree === 'true',
    };
    registerLoad({
      data: valuesPromotion,
    });
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={oldValues || initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form onBlur={() => updateStorage(values)}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Nova promoção" />
                <FormControlLabel
                  label="Promoção habilitada"
                  labelPlacement="start"
                  control={
                    <GreenSwitch
                      checked={values.isActive}
                      onChange={(ev) =>
                        setFieldValue('isActive', ev.target.checked)
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Field
                  name="name"
                  label="Nome"
                  error={Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  placeholder="Informe o nome da promoção"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Field
                  name="description"
                  label="Descrição"
                  error={Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  placeholder="Informe a descrição da promoção"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth>
                  <InputLabel shrink id="promotion-new-customerProfile">
                    Perfil do cliente
                  </InputLabel>
                  <Field
                    name="customerProfile"
                    disabled={clientInfo.loading}
                    error={Boolean(errors.customerProfile)}
                    helperText={
                      touched.customerProfile && errors.customerProfile
                    }
                    labelId="promotion-new-customerProfile"
                    displayEmpty
                    fullWidth
                    as={Select}
                  >
                    <MenuItem disabled value="">
                      <em>Selecione o perfil do cliente</em>
                    </MenuItem>
                    {clientInfo.data !== null &&
                      clientInfo.data.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                          {client.name}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="percentage"
                  label="Desconto em %"
                  error={Boolean(errors.percentage)}
                  helperText={touched.percentage && errors.percentage}
                  placeholder="Informe a porcentagem"
                  type="number"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="discount"
                  label="Desconto em dinheiro"
                  error={Boolean(errors.discount)}
                  helperText={touched.discount && errors.discount}
                  placeholder="Informe o valor do desconto"
                  type="number"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Remover juros no parcelamento?
                  </FormLabel>
                  <RadioGroup
                    row
                    value={values.interestFree}
                    onChange={(ev) =>
                      setFieldValue('interestFree', ev.target.value)
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Não"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </UIContainer>

            <UIContainer container direction="row">
              <Grid item xs={12}>
                <UIFormTitle title="Serviços da promoção" />
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    noOptionsText="Sem opções"
                    disabled={servicesInfo.loading}
                    options={
                      servicesInfo.data !== null ? servicesInfo.data : []
                    }
                    value={values.services}
                    getOptionLabel={(service) => service.name}
                    defaultValue={
                      oldValues !== null && oldValues.services
                        ? oldValues.services.map((value) => value)
                        : []
                    }
                    onChange={(event, services) =>
                      setFieldValue('services', services)
                    }
                    renderInput={(params) => (
                      <Field
                        {...params}
                        name="services"
                        error={Boolean(errors.services)}
                        helperText={touched.services && errors.services}
                        variant="standard"
                        placeholder={
                          !values.products.length ? 'Todos' : 'Adicione mais'
                        }
                        component={TextField}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </UIContainer>
            <UIContainer container direction="row">
              <Grid item xs={12}>
                <UIFormTitle title="Produtos da promoção" />
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    noOptionsText="Sem opções"
                    disabled={productsInfo.loading}
                    options={
                      productsInfo.data !== null ? productsInfo.data : []
                    }
                    value={values.products}
                    getOptionLabel={(product) => product.name}
                    defaultValue={
                      oldValues !== null && oldValues.products
                        ? oldValues.products.map((value) => value)
                        : []
                    }
                    onChange={(event, products) =>
                      setFieldValue('products', products)
                    }
                    renderInput={(params) => (
                      <Field
                        {...params}
                        name="products"
                        error={Boolean(errors.products)}
                        helperText={touched.products && errors.products}
                        variant="standard"
                        placeholder={
                          !values.products.length ? 'Todos' : 'Adicione mais'
                        }
                        component={TextField}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </UIContainer>

            <UIFormContainerSave
              container
              justify="center"
              direction="row"
              messageSucess="Promoção cadastrada com sucesso"
              messageError="Alguma coisa está errada, verifique os campos"
              error={registerInfo.error}
              loading={registerInfo.loading || ''}
              data={registerInfo.data}
              setrequestinfo={setRegisterInfo}
              setFormValues={() => setOldValues(null)}
            />

            <UIModalOldForm
              open={open}
              changeModal={changeModal}
              setFormValues={setFormValues}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PromotionNew;
