import React from 'react';
import PageContent from '../../../components/Main/Content/mainContent';
import PromotionNew from '../../../components/Promotion/New/promotionNew';

const PromocaoNova = () => (
  <PageContent>
    <PromotionNew />
  </PageContent>
);

export default PromocaoNova;
