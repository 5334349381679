import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Tooltip,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { formattedCurrency } from '../../../utils/utils';

const CashierLogisticBar = (children) => {
  const { outsMoves, entriesMoves } = children;
  const [data, setData] = useState(null);

  useEffect(() => {
    const dataValues = [
      {
        name: 'CMV vs Estoque',
        outsMoves: Math.abs(outsMoves),
        entriesMoves,
      },
    ];
    setData(dataValues);
  }, []);

  return (
    <ResponsiveContainer height="100%">
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        barSize={20}
      >
        <Tooltip
          cursor={{ fill: '#FFF' }}
          formatter={(value) => formattedCurrency(value)}
        />
        <Legend
          wrapperStyle={{ position: 'relative', bottom: 0, width: '100%' }}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" orientation="bottom" />
        <YAxis type="category" orientation="left" dataKey="name" />
        <Bar
          name="CMV"
          dataKey="outsMoves"
          fill="#D60429"
          background={{ fill: '#eee' }}
        />
        <Bar
          name="ESTOQUE"
          dataKey="entriesMoves"
          fill="#1B79DF"
          background={{ fill: '#eee' }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CashierLogisticBar;
