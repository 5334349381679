import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import './local.css';

const GoalCard = (children) => {
  const { title, subtitle, href, actionText, iconColor } = children;
  return (
    <Grid
      item
      className="goal-card__card"
      style={{ backgroundColor: iconColor }}
    >
      <Grid item xs={12} md={8}>
        <Typography className="home-page__card-title">{title}</Typography>
        <Typography className="home-page__card-body">{subtitle}</Typography>
      </Grid>
      <Grid item xs={12} md={4} className="goal-card__link">
        <Typography onClick={children.onClick} variant="button" className="home-page__card-goTo">
          <a href={href}>{actionText}</a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GoalCard;
