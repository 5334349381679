import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import CategoryNew from '../New/categoryNew';
import CategorySearch from '../Search/categorySearch';

const CategoryShow = (children) => {
  // verificar se é mobile e constantes para versão mobile
  const type = children;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [collapseNew, setCollapseNew] = useState(false);
  const [collapseSearch, setCollapseSearch] = useState(true);

  function changeCollapseSearch() {
    setCollapseNew(!collapseNew);
    setCollapseSearch(!collapseSearch);
  }

  function changeCollapseNew() {
    setCollapseNew(!collapseNew);
    setCollapseSearch(!collapseSearch);
  }

  return (
    <>
      <CategoryNew
        mobile={mobile}
        collapseNew={collapseNew}
        changeCollapseNew={changeCollapseNew}
        type={type.referenceType}
      />
      <CategorySearch
        mobile={mobile}
        collapseSearch={collapseSearch}
        changeCollapseSearch={changeCollapseSearch}
        type={type.referenceType}
      />
    </>
  );
};

export default CategoryShow;
