import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import StoreContext from '../../Store/context';

// eslint-disable-next-line
const RouterLogin = ({ component: Component, ...rest }) => {
  const { credencials } = useContext(StoreContext);
  if (!credencials) localStorage.clear();
  return (
    <Route
      {...rest}
      render={() =>
        credencials ? <Redirect to="/home" /> : <Component {...rest} />
      }
    />
  );
};

export default RouterLogin;
