import { KeyboardDateTimePicker } from '@material-ui/pickers';
import React from 'react';

const FormInputDateTime = (children) => (
  <KeyboardDateTimePicker
    {...children}
    format="dd/MM/yyyy"
    variant="dialog"
    invalidDateMessage="Formato de data invalida"
    minDateMessage="Informe uma data mínima válida"
    maxDateMessage="Informe uma data máxima válida"
    ampm={false}
    inputVariant="outlined"
    fullWidth
  />
);

export default FormInputDateTime;
