import { Grid } from '@material-ui/core';
import React from 'react';
import './UIContainer.css';

// eslint-disable-next-line
const UIContainer = ({ children, ...rest }) => (
  <Grid
    className="UIContainer"
    // eslint-disable-next-line
    {...rest}
  >
    {children}
  </Grid>
);

export default UIContainer;
