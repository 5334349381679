import React from 'react';
import CashierSearch from '../../../components/Cashier/Search/cashierSearch';
import PageContent from '../../../components/Main/Content/mainContent';

const CaixaPesquisar = () => (
  <PageContent>
    <CashierSearch />
  </PageContent>
);

export default CaixaPesquisar;
