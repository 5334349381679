import React from 'react';
import PageContent from '../../../components/Main/Content/mainContent';
import PromotionSearch from '../../../components/Promotion/Search/promotionSearch';

const PromocaoPesquisar = () => (
  <PageContent>
    <PromotionSearch />
  </PageContent>
);

export default PromocaoPesquisar;