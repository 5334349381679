import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Field, Form, Formik } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ListItem,
  Collapse,
  FormLabel,
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import UIContainer from '../../UI/Container/UIContainer';

import useStorage from '../../../utils/useStorage';
import GreenSwitch from '../../UI/Form/Switch/GreenSwitch';

import useApi from '../../../utils/useApi';
import { capitalize, isEqualObject } from '../../../utils/utils';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIModalOldForm from '../../UI/ModalOldForm/UIModalOldForm';
import validationSchema from './validationSchema';

const initialState = {
  name: '',
  description: '',
  particulars: {},
  isActive: true,
};
const CategoryNew = (children) => {
  const { collapseNew, changeCollapseNew, mobile, type } = children;
  const [open, setOpen] = useState(false);
  const [oldValues, setOldValues] = useStorage('formCategories');
  const [particular, setParticular] = useState('');
  const [alertList, setAlertList] = useState(false);

  const [registerLoad, registerInfo, setRegisterInfo] = useApi({
    method: 'post',
    url: type === 'p'? '/products/categories': '/services/categories',
    onCompleted: () => {
      setAlertList(true);
    },
  });

  function changeModal(isContinue) {
    setOpen(!open);
    if (isContinue) {
      changeCollapseNew();
    }
  }

  function updateStorage(values) {
    setOldValues(values);
  }

  function setFormValues() {
    setOldValues(null);
    setRegisterInfo();
    changeModal();
  }

  useEffect(() => {
    if (oldValues && !isEqualObject(oldValues, initialState)) {
      changeModal();
    }
  }, []);

  function onSubmit(values) {
    registerLoad({
      data: values,
      onCompleted: (res) => {
        if (res) setOldValues(null);
      },
    });
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={oldValues || initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, setFieldValue, submitForm }) => (
          <Form onBlur={() => updateStorage(values)}>
            <UIContainer container direction="row">
              <ListItem
                button
                onClick={changeCollapseNew}
                style={{ justifyContent: 'space-between' }}
              >
                <UIFormTitle title="Nova categoria" />
                {collapseNew ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={collapseNew}
                timeout="auto"
                unmountOnExit
                style={{ width: '100%' }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="name"
                      label="Nome da categoria"
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      placeholder="Informe o nome da categoria"
                      fullWidth
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={8} md={6}>
                    <Field
                      name="description"
                      label="Descrição da categoria"
                      placeholder="Informe a descrição da categoria"
                      fullWidth
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Categoria</FormLabel>
                      <FormControlLabel
                        label={values.isActive ? 'Habilitar' : 'Desabilitar'}
                        labelPlacement="start"
                        style={{ margin: 0 }}
                        control={
                          <GreenSwitch
                            checked={values.isActive}
                            onChange={(ev) =>
                              setFieldValue('isActive', ev.target.checked)
                            }
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                  {Object.entries(values.particulars).map(([key, value]) => (
                    <Grid
                      key={key}
                      container
                      alignItems="flex-end"
                      item
                      xs={12}
                      md={6}
                    >
                      <ChipInput
                        label={capitalize(key)}
                        placeholder="Adicione atributos a particulariedade"
                        value={value}
                        onAdd={(chip) =>
                          setFieldValue(`particulars.${key}`, [
                            ...values.particulars[key],
                            chip,
                          ])
                        }
                        onDelete={(chip) => {
                          const newValuesChip = values.particulars[key].filter(
                            (item) => item !== chip,
                          );
                          setFieldValue(`particulars.${key}`, newValuesChip);
                        }}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  const newObject = values.particulars;
                                  delete newObject[key];
                                  setFieldValue('particulars', newObject);
                                }}
                                size={mobile ? 'small' : 'medium'}
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={particular}
                      label="Adicionar particulariedade"
                      onChange={(ev) => setParticular(ev.target.value)}
                      onKeyUp={(ev) => {
                        if (ev.key === 'Enter') {
                          const keysParticulars = Object.keys(
                            values.particulars,
                          );
                          const partcularsLowCase = particular.toLowerCase();
                          if (
                            particular.trim() &&
                            !keysParticulars.includes(partcularsLowCase)
                          ) {
                            setFieldValue('particulars', {
                              ...values.particulars,
                              [partcularsLowCase]: [],
                            });
                            setParticular('');
                          }
                        }
                      }}
                      placeholder="Informe o nome da particulariedade"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              title="Adicionar"
                              style={{ color: '#23a114' }}
                              onClick={() => {
                                const keysParticulars = Object.keys(
                                  values.particulars,
                                );
                                const partcularsLowCase =
                                  particular.toLowerCase();
                                if (
                                  particular.trim() &&
                                  !keysParticulars.includes(partcularsLowCase)
                                ) {
                                  setFieldValue('particulars', {
                                    ...values.particulars,
                                    [partcularsLowCase]: [],
                                  });
                                  setParticular('');
                                }
                              }}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid container justify="flex-end" item xs={12}>
                    <Button
                      variant="contained"
                      className="UIFormContainerSave__buttons-canceled"
                      type="reset"
                      onClick={() => setOldValues(initialState)}
                      style={{ marginRight: '25px' }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={submitForm}
                      className="UIFormContainerSave__buttons-saved"
                      variant="contained"
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </UIContainer>
          </Form>
        )}
      </Formik>
      <UIDialogConfirm
        open={alertList}
        close={() => setAlertList(!alertList)}
        data={registerInfo}
        timeSucess={3000}
        messageSucess="Categoria registrada com sucesso"
        messageError="Já existe uma categoria com esse nome"
        setrequestinfo={setRegisterInfo}
      />
      <UIModalOldForm
        open={open}
        changeModal={changeModal}
        setFormValues={setFormValues}
      />
    </>
  );
};

export default CategoryNew;
