import { Button, CircularProgress, Grid, Snackbar } from '@material-ui/core';
import React, { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import UIFormTitle from '../Title/UIFormTitle';
import './UIFormContainerSave.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UIFormContainerSave = (children) => {
  const {
    setFormValues,
    loading,
    error,
    messageError,
    messageSucess,
    setRegisterInfo,
    data,
  } = children;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Grid
        className="UIFormContainerSave"
        // eslint-disable-next-line
        {...children}
      >
        <Grid container item direction="row" alignItems="center" md={4} xs={12}>
          <UIFormTitle title="Salvar dados" />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="flex-end"
          alignItems="center"
          xs={12}
          md={8}
          className="UIFormContainerSave__buttons"
        >
          <Button
            variant="contained"
            className="UIFormContainerSave__buttons-canceled"
            type="reset"
            size="large"
            onClick={setFormValues}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            className="UIFormContainerSave__buttons-saved"
            type="submit"
            size="large"
            onClick={handleClick}
            startIcon={
              loading && <CircularProgress size="0.9rem" color="inherit" />
            }
          >
            {loading ? 'Salvando' : 'Salvar'}
          </Button>
        </Grid>
      </Grid>

      {error && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          onExited={setRegisterInfo}
        >
          <Alert onClose={handleClose} severity="error">
            {(error.response &&
              error.response.data &&
              error.response.data.error) ||
              messageError}
          </Alert>
        </Snackbar>
      )}

      {data && (
        <Snackbar
          onEnter={setFormValues}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          onExited={setRegisterInfo}
        >
          <Alert onClose={handleClose} severity="success">
            {messageSucess}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default UIFormContainerSave;
