import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Grid,
  Tab,
  Tabs,
  Typography,
  DialogActions,
  DialogContent,
  Tooltip,
  Fab,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { TabContext, TabPanel } from '@material-ui/lab';
// import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from '@material-ui/core/ListSubheader';

import useApi from '../../../utils/useApi';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import StoreContext from '../../Store/context';
import { formattedCurrency, formattedDateTime } from '../../../utils/utils';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import CashierLogisticBar from '../Graph/cashierLogisticBar';
import CashierLogisticPie from '../Graph/cashierLogisticPie';
import StockTable from '../Table/stockTable';
import EntriesTable from '../Table/entriesTable';
import OutsTable from '../Table/outsTable';

const CashierClosuresView = () => {
  const { credencials } = useContext(StoreContext);

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // define os valores de outros no grafico
  const [othersValues, setOthersValues] = useState(null);

  const [selectNewClosure, setNewClosure] = useState(false);

  const [closeStockModal, setOpenedClosedModal] = useState(false);

  // modais de pesquisa e filtragem
  const [tab, setTab] = useState('1');

  const [index, setIndex] = useState(0);

  const handleTab = (event, newValue) => {
    setTab(newValue);
    if (othersValues) setOthersValues(null);
  };

  const handleModalCloseStock = () => {
    setOpenedClosedModal(!closeStockModal);
  }

  const handleModalSelectVigency = () => {
    setNewClosure(!selectNewClosure);
  };

  const [stockMovesLoad, cashierMoveInfo] = useApi({
    method: 'get',
    params: { withMoves: 1 },
    url: '/stock',
  });
  
  const [alert, setAlert] = useState(false);
  const [setCloseStock, closeStockInfo] = useApi({
    method: 'delete',
    url: '/stock',
  });

  function closeStock() {
    setCloseStock();
    setAlert(true);
    handleModalCloseStock();
  }

  function closeAlert(event, reason) {
    stockMovesLoad({
      method: 'get',
      params: { withMoves: 1 },
      onCompleted: (res) => {
        if (res) {
          setIndex(0);
        }
      },
    });
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  }

  useEffect(() => {
    stockMovesLoad({
      method: 'get',
      params: { withMoves: 1 },
      onCompleted: (res) => {
        if (res) {
          setIndex(0);
        }
      },
    });
  }, [credencials]);

  function openOtherValues(data, tabIndex) {
    setOthersValues(data);
    setTab(tabIndex);
  }

  function setNewIndex(_index) {
    setIndex(_index);
    if (selectNewClosure) handleModalSelectVigency();
    setTab('1');
  }

  function toggleBackForOthers() {
    setOthersValues(!othersValues);
    setTab('4');
  }

  return (
    <>
      <UIContainer container>
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          xs={12}
        >
          <UIFormTitle title="Meu Estoque" />
        </Grid>
        <Typography
          style={{ fontSize: 18 }}
          className="cashier-logistic-open__text"
          onClick={handleModalSelectVigency}
        >
          {cashierMoveInfo.data && cashierMoveInfo.data[index] && (
            <span>
              {!cashierMoveInfo.data[index].dueDate
                ? 'Em aberto desde: '
                : 'Aberto '}
              {formattedDateTime(cashierMoveInfo.data[index].startDate)}
              {cashierMoveInfo.data[index].dueDate ? 'e fechado ' : ' '}
              {formattedDateTime(cashierMoveInfo.data[index].dueDate)}
            </span>
          )}
        </Typography>
      </UIContainer>
      <UIContainer style={{ height: '62.5%', overflow: 'auto' }}>
        <TabContext value={tab}>
          <Grid item xs>
            <Tabs
              value={tab}
              onChange={handleTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab value="1" label="Fechamentos" />
              <Tab value="2" label="Entradas" />
              <Tab value="3" label="Saídas" />
              <Tab value="4" label="Resumo" />
            </Tabs>
          </Grid>

          {cashierMoveInfo.data && cashierMoveInfo.data[index] && (
            <>
              <TabPanel value="1" index="1" style={{ padding: 0 }}>
                <StockTable
                  actualId={cashierMoveInfo.data[index].id}
                  doubleClickAction={setNewIndex}
                  mobile={mobile}
                  data={cashierMoveInfo.data}
                  loading={cashierMoveInfo.loading}
                  error={cashierMoveInfo.error}
                />
              </TabPanel>
              <TabPanel
                value="2"
                index="2"
                style={{ padding: 0, height: '81%' }}
              >
                <Grid container style={{ width: '100%', height: '100%' }}>
                  <EntriesTable
                    mobile={mobile}
                    data={cashierMoveInfo.data[index].entries.moves}
                    totalValue={cashierMoveInfo.data[index].entries.total}
                    loading={cashierMoveInfo.loading}
                    error={cashierMoveInfo.error}
                  />
                </Grid>
              </TabPanel>
              <TabPanel
                value="3"
                index="3"
                style={{ padding: 0, height: '81%' }}
              >
                <Grid container style={{ width: '100%', height: '100%' }}>
                  <OutsTable
                    mobile={mobile}
                    data={cashierMoveInfo.data[index].outs.moves}
                    totalValue={cashierMoveInfo.data[index].outs.total}
                    loading={cashierMoveInfo.loading}
                    error={cashierMoveInfo.error}
                  />
                </Grid>
              </TabPanel>
              <TabPanel
                value="4"
                index="4"
                style={{ padding: 0, height: '81%' }}
              >
                <Grid container style={{ width: '100%', height: '100%' }}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ height: '98%', marginBottom: '10px' }}
                  >
                    <CashierLogisticBar
                      entriesMoves={cashierMoveInfo.data[index].value}
                      outsMoves={cashierMoveInfo.data[index].cmv}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={4}
                    justify="center"
                    style={{ height: '100%', marginBottom: '10px' }}
                  >
                    <CashierLogisticPie
                      moves={cashierMoveInfo.data[index].entries.moves}
                      color="#1B79DF"
                      caption="Entradas de estoque"
                      total={cashierMoveInfo.data[index].entries.total}
                      openOtherValues={openOtherValues}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={4}
                    justify="center"
                    style={{ height: '100%', marginBottom: '10px' }}
                  >
                    <CashierLogisticPie
                      moves={cashierMoveInfo.data[index].outs.moves}
                      color="#D60429"
                      caption="Saídas de estoque"
                      total={cashierMoveInfo.data[index].outs.total}
                      openOtherValues={openOtherValues}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </>
          )}
        </TabContext>
      </UIContainer>
      <UIContainer
        container
        direction="row"
        alignItems="center"
        style={{ minHeight: '15%' }}
      >
        <>
          <Grid
            item
            xs={12}
            md={5}
            className="form-container-sale__table-info-payment"
          >
            <Typography
              paragraph
              style={{
                margin: 0,
                color:
                  cashierMoveInfo.data &&
                  cashierMoveInfo.data[index] &&
                  cashierMoveInfo.data[index].total < 0
                    ? 'red'
                    : 'green',
              }}
            >
              Valor do estoque:{' '}
              {cashierMoveInfo.data && cashierMoveInfo.data[index] && (
                <span>
                  {formattedCurrency(cashierMoveInfo.data[index].value)}
                </span>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            className="form-container-sale__table-info-payment"
          >
            <Typography paragraph style={{ margin: 0 }}>
              CMV:{' '}
              {cashierMoveInfo.data && cashierMoveInfo.data[index] && (
                <span>
                  {formattedCurrency(cashierMoveInfo.data[index].cmv)}
                </span>
              )}
            </Typography>
          </Grid>
        </>
        <Grid xs={12} md={2} item container>
          <Grid item xs />
          <Button
            variant="outlined"
            type="submit"
            size="large"
            color="secondary"
            onClick={handleModalCloseStock}
          >
            NOVA VIGENCIA
          </Button>
        </Grid>
      </UIContainer>
      <Dialog
        open={closeStockModal}
        fullWidth
        onClose={handleModalCloseStock}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <UIDialogTitle id="alert-dialog-title">DESEJA FECHAR O ESTOQUE ATUAL?</UIDialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              Entenda que esta ação marcará o periodo atual como data de fechamento de estoque e iniciará uma nova vigência com CMV zerado
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalCloseStock} color="primary">
            Cancelar
          </Button>
          <Button onClick={closeStock} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={closeStockInfo}
        setrequestinfo={closeAlert}
        messageSucess={
          closeStockInfo.data
            ? closeStockInfo.data.message
            : 'Estoque encerrado com sucesso!'
        }
        type="success"
      />
      <Dialog
        open={selectNewClosure}
        fullWidth
        onClose={handleModalSelectVigency}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <UIDialogTitle id="alert-dialog-title">Escolha o período</UIDialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <List subheader={<li />}>
                {cashierMoveInfo.data &&
                  cashierMoveInfo.data.map((closureId, _index) => (
                    <li key={`section-${closureId.opened}`}>
                      <ul>
                        <Button
                          onClick={() => setNewIndex(_index)}
                          style={
                            _index === index
                              ? { backgroundColor: '#d3d3d3' }
                              : { backgroundColor: '#fff' }
                          }
                          disabled={_index === index}
                        >
                          {!closureId.dueDate
                            ? 'Em aberto desde: '
                            : 'Aberto: '}
                          {formattedDateTime(closureId.startDate)}
                          {closureId.dueDate ? 'Fechado:' : ' '}
                          {formattedDateTime(closureId.dueDate)}
                        </Button>
                      </ul>
                    </li>
                  ))}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalSelectVigency} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {othersValues && (tab === '1' || tab === '2') && (
        <Tooltip title="Fechar 'Outros'">
          <Fab
            style={{
              backgroundColor: '#94031f',
              color: '#fff',
              right: '50%',
              left: '50%',
              bottom: '35px',
              position: 'relative',
            }}
            size="small"
            variant="extended"
            onClick={toggleBackForOthers}
          >
            Voltar
          </Fab>
        </Tooltip>
      )}
    </>
  );
};

export default CashierClosuresView;
