import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { format } from 'date-fns';
import StoreContext from '../../Store/context';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import useApi from '../../../utils/useApi';
import { emptyObject, formattedCurrency } from '../../../utils/utils';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import { useReactToPrint } from 'react-to-print';
import '../cupom.css';

const SaleConfirm = ({ ...children }) => {
  const { credencials, setModalCashierSelect } = useContext(StoreContext);

  const { options, setOptions, calculate, handleModal } = children;

  const [open, setOpen] = useState(false);

  const [salesLoad, saleInfo, setSaleInfo] = useApi({
    method: 'post',
    url: '/sales',
  });

  const [paymentLoad, paymentInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/payments/methods',
  });

  const contentRef = useRef();
  const reactToPrintFn = useReactToPrint({ contentRef });

  useEffect(() => {
    console.log(options);
    paymentLoad();
  }, []);

  function onQuit() {
    setSaleInfo();
    handleModal();
    if (saleInfo.data) {
      children.resetform();
    }
  }

  function onSale() {
    if (!credencials.cashSession) {
      setModalCashierSelect(true);
    } else {
      const saleFinal = {
        ...calculate,
        cashRegister: credencials.cashSession,
        amountPaid: Number(options.amountPaid || 0),
        observation: options.observation,
        paymentMethod: options.paymentMethod,
      };
      salesLoad({
        data: { ...emptyObject(saleFinal), onlyCalculate: false },
      });
      setOpen(true);
    }
  }

  return (
    <>
      <Dialog fullWidth open={children.open} onClose={onQuit}>
        <UIDialogTitle onClose={onQuit}>
          {saleInfo.data ? 'Venda concluida' : 'Concluir venda'}
        </UIDialogTitle>
        <DialogContent>
          {!saleInfo.data && (
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel shrink id="sale-confirm__paymentMethod">
                    Metódo de pagamento
                  </InputLabel>
                  <Select
                    value={options.paymentMethod}
                    onChange={(ev) =>
                      setOptions({ ...options, paymentMethod: ev.target.value })
                    }
                    labelId="sale-confirm__paymentMethod"
                    displayEmpty
                  >
                    {paymentInfo.data &&
                      paymentInfo.data.map((pay) => (
                        <MenuItem key={pay.id} value={pay.id}>
                          {pay.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrencyTextField
                  type="tel"
                  value={options.amountPaid}
                  label="Valor pago"
                  variant="standard"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  onChange={(ev, value) =>
                    setOptions({ ...options, amountPaid: value })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  value={options.observation}
                  onChange={(ev) =>
                    setOptions({ ...options, observation: ev.target.value })
                  }
                  label="Observações"
                  placeholder="Anote alguma observação da venda"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          {saleInfo.data && (
            <Grid ref={contentRef} container direction="row" spacing={1}>
              <TableContainer>
                <Typography align="center" variant="h6" component="div">
                  Resumo do pedido #{saleInfo.data.saleId}
                </Typography>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Cliente</TableCell>
                      <TableCell>
                        {saleInfo.data.customer === 1
                          ? 'Avulso'
                          : `${options.customer.name} ${
                              options.customer.lastName || ''
                            }`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Vendedor</TableCell>
                      <TableCell>{credencials.name}</TableCell>
                    </TableRow>
                    {options.promotion && (
                      <TableRow>
                        <TableCell>Promoção aplicada</TableCell>
                        <TableCell>{options.promotion.name}</TableCell>
                      </TableRow>
                    )}
                    {saleInfo.data.details.discount > 0 && (
                      <TableRow>
                        <TableCell>Desconto</TableCell>
                        <TableCell>
                          {formattedCurrency(saleInfo.data.details.discount)}
                        </TableCell>
                      </TableRow>
                    )}
                    {saleInfo.data.details.increase > 0 && (
                      <TableRow>
                        <TableCell>Acréscimo juros</TableCell>
                        <TableCell>
                          {formattedCurrency(saleInfo.data.details.increase)}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>Valor da compra</TableCell>
                      <TableCell>
                        {formattedCurrency(saleInfo.data.value)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Valor pago</TableCell>
                      <TableCell>
                        {formattedCurrency(options.amountPaid)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Parcelas</TableCell>
                      <TableCell>
                        {saleInfo.data.payment
                          ? Object.entries(
                              saleInfo.data.payment.plots.resume,
                            ).map(([key, value]) => (
                              <p style={{ padding: 0, margin: 0 }}>
                                {key}ª
                                {typeof value === 'string'
                                  ? ` de ${formattedCurrency(
                                      Number(value.split(' -')[0]),
                                    )} - Paga`
                                  : ` de ${formattedCurrency(value)}`}
                              </p>
                            ))
                          : Object.entries(saleInfo.data.plots).map(
                              ([key, value]) => (
                                <p style={{ padding: 0, margin: 0 }}>
                                  {key}ª
                                  {typeof value === 'string'
                                    ? ` de ${formattedCurrency(
                                        Number(value.split(' -')[0]),
                                      )} - Paga`
                                    : ` de ${formattedCurrency(value)}`}
                                </p>
                              ),
                            )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Data da compra</TableCell>
                      <TableCell>{new Date().toLocaleString()}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Itens</TableCell>
                      {saleInfo.data.details.products.map((p) => (
                        <TableRow>
                          <TableCell>
                            {p.amount}x {formattedCurrency(p.price)}
                          </TableCell>
                          <p style={{ padding: 0, margin: 0 }}>
                            <TableCell> {p.item}</TableCell>
                          </p>
                        </TableRow>
                      ))}
                      {saleInfo.data.details.services.map((p) => (
                        <TableRow>
                          <TableCell>
                            {p.amount}x {formattedCurrency(p.price)}
                          </TableCell>
                          <p style={{ padding: 0, margin: 0 }}>
                            <TableCell> {p.item}</TableCell>
                          </p>
                        </TableRow>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {saleInfo.data.payment !== null && (
                <Typography
                  variant="h6"
                  component="div"
                  color="textSecondary"
                  style={{ marginTop: '10px', paddingLeft: '10px' }}
                >
                  TROCO: {formattedCurrency(saleInfo.data.payment.remnant)}
                </Typography>
              )}
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          {saleInfo.data && (
            <Button
              onClick={reactToPrintFn}
              color="primary"
              autoFocus
              disabled={saleInfo.loading}
            >
              Imprimir Cupom
            </Button>
          )}
          <Button
            onClick={saleInfo.data ? onQuit : onSale}
            color={saleInfo.data ? 'secondary' : 'primary'}
            autoFocus
            disabled={saleInfo.loading}
          >
            {saleInfo.data ? 'Sair' : 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
      <UIDialogConfirm
        open={open}
        data={saleInfo}
        messageSucess="Venda realizada com sucesso!"
        close={() => setOpen(!open)}
        setrequestinfo={undefined}
        type="success"
      />
    </>
  );
};

export default SaleConfirm;
