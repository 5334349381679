import React from 'react';
import PageContent from '../../../components/Main/Content/mainContent';
import UserNew from '../../../components/User/New/userNew';

const UsuarioNovo = () => (
  <PageContent>
    <UserNew />
  </PageContent>
);

export default UsuarioNovo;
