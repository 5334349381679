import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { format } from 'date-fns';
import useApi from '../../../utils/useApi';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import validationSchema from './validationSchema';
import UIFormContainerSave from '../../UI/Form/ContainerSave/UIFormContainer';
import FormInputDateTime from '../../UI/Form/InputDateTime/FormInputDateTime';
import UIModalOldForm from '../../UI/ModalOldForm/UIModalOldForm';
import useStorage from '../../../utils/useStorage';
import { emptyObject, isEqualObject } from '../../../utils/utils';

const initialState = {
  name: '',
  usersId: [],
  startDate: new Date(),
  dueDate: null,
  initialValue: 0.0,
};
const CashierNew = () => {
  const [open, setOpen] = useState(false);
  const [oldValues, setOldValues] = useStorage('formCashier');
  const [usersLoad, usersInfo] = useApi({
    method: 'get',
    params: { isActive: '1' },
    url: '/users',
  });

  const [registerLoad, registerInfo, setRegisterInfo] = useApi({
    method: 'post',
    url: '/cash-registers',
  });

  function changeModal() {
    setOpen(!open);
  }

  function setFormValues() {
    setOldValues(null);
    changeModal();
  }

  function updateStorage(values) {
    setOldValues(values);
  }

  useEffect(() => {
    usersLoad();
    if (oldValues && !isEqualObject(oldValues, initialState)) {
      changeModal();
    }
  }, []);

  const messageError = (error) => {
    const ops = 'Ops, alguma coisa está errada, verifique os campos';
    const apiError = registerInfo.error
      ? registerInfo.error.response.data.error
      : '';
    const messageCode = {
      1062: 'Nome de caixa existente, por favor escolha outro',
    };
    return (apiError.includes(error) && messageCode[error]) || ops;
  };

  function onSubmit(values) {
    const valuesCashier = {
      ...values,
      dueDate:
        values.dueDate &&
        format(new Date(values.dueDate), 'yyyy-MM-dd HH:mm:ss'),
      startDate: format(new Date(values.startDate), 'yyyy-MM-dd HH:mm:ss'),
      usersId: values.usersId.map((user) => user.id),
    };
    registerLoad({
      data: emptyObject(valuesCashier),
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={oldValues || initialState}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <Form onBlur={() => updateStorage(values)}>
          <UIContainer container direction="row">
            <Grid item xs={12}>
              <UIFormTitle title="Novo caixa" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                limitTags={2}
                disabled={usersInfo.loading}
                options={usersInfo.data !== null ? usersInfo.data : []}
                value={values.usersId}
                getOptionLabel={(user) => `${user.username} - ${user.name}`}
                onChange={(event, users) => setFieldValue('usersId', users)}
                noOptionsText="Sem opções"
                defaultValue={
                  oldValues !== null && oldValues.usersId
                    ? oldValues.usersId.map((value) => value)
                    : []
                }
                renderInput={(params) => (
                  <Field
                    {...params}
                    name="usersId"
                    error={Boolean(errors.usersId)}
                    helperText={touched.usersId && errors.usersId}
                    variant="standard"
                    label="Operador do caixa"
                    placeholder="Adicionar mais"
                    component={TextField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="name"
                label="Nome do caixa"
                error={Boolean(errors.name)}
                helperText={errors.name}
                placeholder="Informe o nome do caixa"
                fullWidth
                as={TextField}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CurrencyTextField
                type="tel"
                value={values.initialValue}
                label="Valor inicial do caixa"
                variant="standard"
                decimalCharacter=","
                error={Boolean(errors.initialValue)}
                helperText={errors.initialValue}
                digitGroupSeparator="."
                currencySymbol="R$"
                outputFormat="string"
                fullWidth
                onChange={(ev, value) => setFieldValue('initialValue', value)}
              />
            </Grid>
          </UIContainer>
          <UIContainer container direction="row">
            <Grid item xs={12}>
              <UIFormTitle title="Horário de vigência" />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputDateTime
                name="startDate"
                value={values.startDate}
                helperText={errors.startDate}
                error={Boolean(errors.startDate)}
                label="Horário inicio vigência"
                onChange={(value) => setFieldValue('startDate', value, true)}
                disablePast
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputDateTime
                name="dueDate"
                value={values.dueDate}
                minDate={values.startDate}
                label="Horário termino vigência"
                onChange={(value) => setFieldValue('dueDate', value, false)}
                disablePast
              />
            </Grid>
          </UIContainer>
          <UIFormContainerSave
            container
            justify="center"
            direction="row"
            messageSucess="Caixa cadastrado com sucesso"
            messageError={messageError(1062)}
            error={registerInfo.error}
            loading={registerInfo.loading || ''}
            data={registerInfo.data}
            setrequestinfo={setRegisterInfo}
            setFormValues={() => setOldValues(null)}
          />
          <UIModalOldForm
            open={open}
            changeModal={changeModal}
            setFormValues={setFormValues}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CashierNew;
