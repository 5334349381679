import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useApi from '../../../../utils/useApi';
import TransferNew from '../New/transferNew';
import TransferSearch from '../Search/transferSearch';

const TransferShow = () => {
  // verificar se é mobile e constantes para versão mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [collapseNew, setCollapseNew] = useState(true);
  const [collapseSearch, setCollapseSearch] = useState(false);

  function changeCollapseSearch() {
    setCollapseNew(!collapseNew);
    setCollapseSearch(!collapseSearch);
  }

  function changeCollapseNew() {
    setCollapseNew(!collapseNew);
    setCollapseSearch(!collapseSearch);
  }

  const [usersLoad, usersInfo] = useApi({
    method: 'get',
    url: '/users',
  });

  const [paymentLoad, paymentInfo] = useApi({
    method: 'get',
    url: '/payments/methods',
  });

  const [cashierLoad, cashierInfo] = useApi({
    method: 'get',
    url: '/cash-registers',
    params: { isActive: 1 },
  });

  useEffect(() => {
    usersLoad();
    cashierLoad();
    paymentLoad();
  }, []);

  return (
    <>
      <TransferSearch
        usersInfo={usersInfo}
        paymentInfo={paymentInfo}
        mobile={mobile}
        collapseSearch={collapseSearch}
        changeCollapseSearch={changeCollapseSearch}
      />
      <TransferNew
        usersInfo={usersInfo}
        cashierInfo={cashierInfo}
        collapseNew={collapseNew}
        changeCollapseNew={changeCollapseNew}
      />
    </>
  );
};

export default TransferShow;
