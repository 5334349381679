import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

const UIClock = () => {
  const currentTime = new Date().toLocaleTimeString('pt-BR');
  const [time, setTime] = useState(currentTime);

  const updateTime = () => {
    const newTime = new Date().toLocaleTimeString('pt-BR');
    setTime(newTime);
  };

  setInterval(updateTime, 1000);

  return <Typography variant="h6">{time}</Typography>;
};

export default UIClock;
