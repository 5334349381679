import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UIDialogConfirm = ({ ...children }) => (
  <>
    {children.data.data && (
      <Snackbar
        open={children.open}
        autoHideDuration={children.timeSucess ? children.timeSucess : 5000}
        onClose={children.close}
        onExited={children.setrequestinfo}
      >
        <Alert onClose={children.close} severity="success">
          {children.messageSucess}
        </Alert>
      </Snackbar>
    )}
    {children.data.error && (
      <Snackbar
        open={children.open}
        autoHideDuration={5000}
        onClose={children.close}
        onExited={children.setrequestinfo}
      >
        <Alert onClose={children.close} severity="error">
          {children.data.error &&
          children.data.error.response &&
          children.data.error.response.status !== 500
            ? children.data.error.response.data.error
            : children.messageError}
          {/* {console.log(children.data.error.response)} */}
        </Alert>
      </Snackbar>
    )}
  </>
);

export default UIDialogConfirm;
