import React, { useState } from 'react';

import {
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { formattedCurrency } from '../../../utils/utils';
import UITablePagination from '../../UI/Table/Pagination/UITablePagination';

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const EntriesTable = (children) => {
  const { data, loading, error, totalValue } = children;

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  // ação para click direito e esquerdo
  const [clickRight, setClickRight] = useState(initialClick);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      Total: {formattedCurrency(totalValue)}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Movimentação</TableCell>
            <TableCell>Produto</TableCell>
            <TableCell>Fornecedor</TableCell>
            <TableCell>Quantidade</TableCell>
            <TableCell>Responsável</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell />
            <TableCell>Momento</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <CircularProgress />}
          {data &&
            data
              .map((value) => (
                <TableRow
                  tabIndex={-1}
                  onDoubleClick={undefined}
                  style={{ cursor: 'context-menu' }}
                  hover
                  key={value.id}
                >
                  <TableCell component="th" scope="row">
                    {value.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {value.productId}
                  </TableCell>
                  <TableCell>{value.supplier}</TableCell>
                  <TableCell component="th" scope="row">
                    {value.amount}
                  </TableCell>
                  <TableCell>{value.userId}</TableCell>
                  <TableCell>{formattedCurrency(value.value)}</TableCell>
                  <TableCell />
                  <TableCell component="th" scope="row">
                    {value.moment}
                  </TableCell>
                </TableRow>
              ))}
          <Menu
            keepMounted
            open={clickRight.mouseY !== null}
            onClose={handleCloseClickRight}
            anchorReference="anchorPosition"
            anchorPosition={
              clickRight.mouseY !== null && clickRight.mouseX !== null
                ? {
                    top: clickRight.mouseY,
                    left: clickRight.mouseX,
                  }
                : undefined
            }
          >
            <MenuItem onClick={undefined}>Ver detalhes</MenuItem>
          </Menu>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleCloseClickLeft()}
          >
            <MenuItem onClick={undefined}>Ver detalhes</MenuItem>
          </Menu>
          {error && (
            <TableRow>
              <TableCell>Sem resultados encotrados</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <UITablePagination
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default EntriesTable;
