import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import useApi from '../../../utils/useApi';
import UIButtonSearch from '../../UI/Button/Search/Search';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import { emptyObject } from '../../../utils/utils';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import GreenSwitch from '../../UI/Form/Switch/GreenSwitch';
import useStyles from '../../../utils/useStyle';

const initialState = {
  id: '',
  name: '',
  lastName: '',
  cpf: '',
  username: '',
  children: 0,
  profile: '',
  cashRegisters: [],
};

const profiles = {
  1: 'Operador',
  2: 'Gerente',
  3: 'Administrador',
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const UserSearch = (children) => {
  const { referenceId } = children;

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { disabledTextField } = useStyles();

  // mensagem de confirmação para operações
  const [alert, setAlert] = useState(false);

  // modais de pesquisa e filtragem
  const [view, setView] = useState(!!referenceId);
  const [filter, setFilter] = useState(false);
  const [modalValues, setModalValues] = useState(null);
  // adicionar quantidade ao produto
  const [userActive, setUserActive] = useState(false);
  const [isActive, setIsActive] = useState('1');

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState(null);

  const [cashierLoad, cashierInfo] = useApi({
    method: 'get',
    url: '/cash-registers',
  });

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/users',
    params: { isActive: '1' },
  });

  const [updateLoad, updateInfo, setUpdateInfo] = useApi({
    method: 'put',
    url: '/users',
    onCompleted: (res) => {
      if (res) {
        filterLoad({
          params: search,
        });
      }
    },
  });

  const [activeLoad, activeInfo, setActiveInfo] = useApi({
    method: 'patch',
    url: '/users',
    onCompleted: () => {
      filterLoad({
        params: search,
      });
    },
  });

  function humanAttributes(values) {
    const { cashRegisters } = values;
    const cashierData = cashierInfo.data || [];
    const cashRegistersTraeted = cashRegisters.map((value) =>
      cashierData.find((val) => Number(val.id) === Number(value)),
    );
    const data = {
      ...values,
      isActive: values.isActive === '1' ? 1 : 0,
      cashRegisters: cashRegistersTraeted,
    };
    setModalValues(data);
  }

  // provider do click do lado direito
  const [clickRight, setClickRight] = useState(initialClick);
  const handleClickRight = (event, values) => {
    event.preventDefault();
    humanAttributes(values);
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };
  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickLeft = (event, values) => {
    humanAttributes(values);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    cashierLoad();
    filterLoad();
  }, []);

  function handleModalFilter() {
    setFilter(!filter);
  }

  function handleModalActive(values, isClick) {
    if (values) {
      humanAttributes(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setUserActive(!userActive);
  }

  function handleModalUpdate(values, isClick) {
    if (values) {
      humanAttributes(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function fullName(data) {
    return `${data.name} ${data.lastName || ''}`;
  }

  function closeAlert(event, reason) {
    setActiveInfo();
    setUpdateInfo();
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  }

  function onSubmit(values) {
    const active = values.isActive || isActive;
    const searchValues = {
      ...values,
      isActive: active,
    };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
    });
    setSearch(emptyObject(searchValues));
  }

  function onUpdate(values) {
    const updateValues = {
      id: values.id,
      cashRegisters: values.cashRegisters.map(({ id }) => id),
      name: values.name,
      lastName: values.lastName,
    };
    updateLoad({
      data: updateValues,
    });
    setView(!view);
    setAlert(true);
  }

  function onActive() {
    const data = { id: modalValues.id };
    activeLoad({ data, onCompleted: () => setAlert(true) });
    setModalValues(null);
  }

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialState}>
        {({ values, submitForm, setFieldValue }) => (
          <Form onChange={submitForm}>
            <UIContainer container>
              <Grid
                item
                container
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Filtrar usuários" />
                <UIFilter onClick={handleModalFilter} />
              </Grid>
              <Grid item xs={2} md={1}>
                <Field
                  name="id"
                  label="ID"
                  placeholder="ID"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <Field
                  name="name"
                  label="Nome"
                  placeholder="Informe o nome do usuário"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden smDown>
                <Grid item xs={6} md={3}>
                  <Field
                    name="lastName"
                    label="Sobrenome"
                    placeholder="Informe o sobrenome do usuário"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={5} md={2}>
                <Field
                  name="username"
                  label="Login"
                  placeholder="Informe o login do usuário"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden smDown>
                <Grid item xs={6} md={2}>
                  <InputMask
                    value={values.cpf}
                    mask="999.999.999-99"
                    onChange={(ev) =>
                      setFieldValue(
                        'cpf',
                        ev.target.value.replace(/[^0-9]/g, ''),
                      )
                    }
                  >
                    {() => (
                      <TextField
                        label="CPF"
                        placeholder="Informe o numero do CPF"
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Grid>
                <UIButtonSearch xs={1} />
              </Hidden>
            </UIContainer>

            <UIModalFilter
              open={filter}
              changeModal={handleModalFilter}
              submit={submitForm}
            >
              <Hidden mdUp>
                <Grid item xs={6}>
                  <InputMask
                    value={values.cpf}
                    mask="999.999.999-99"
                    onChange={(ev) =>
                      setFieldValue(
                        'cpf',
                        ev.target.value.replace(/[^0-9]/g, ''),
                      )
                    }
                  >
                    {() => (
                      <TextField
                        label="CPF"
                        placeholder="Informe o numero do CPF"
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="lastName"
                    label="Sobrenome"
                    placeholder="Informe o sobrenome do usuário"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={6} md={12}>
                <Autocomplete
                  multiple
                  disabled={cashierInfo.loading}
                  options={cashierInfo.data || []}
                  value={values.cashRegisters}
                  getOptionLabel={(cash) => cash.name}
                  onChange={(event, cash) =>
                    setFieldValue('cashRegisters', cash)
                  }
                  getOptionSelected={(op, cash) => op.name === cash.name}
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Operador do caixa"
                      placeholder="Adicionar mais"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={12}>
                <FormControl fullWidth>
                  <InputLabel shrink id="users-new-gender">
                    Perfil
                  </InputLabel>
                  <Field
                    name="profile"
                    labelId="users-new-gender"
                    disabled
                    displayEmpty
                    fullWidth
                    as={Select}
                  >
                    <MenuItem disabled value="">
                      <em>Informe o perfil do usuário</em>
                    </MenuItem>
                    <MenuItem value={1}>Indefinido</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
            </UIModalFilter>
          </Form>
        )}
      </Formik>
      <UIContainer>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          xs={12}
        >
          <UIFormTitle title="Lista de usuários" />
          <FormControlLabel
            label={isActive ? 'Usuários ativos' : 'Usuários desativados'}
            labelPlacement="start"
            control={
              <GreenSwitch
                checked={isActive === '1'}
                onChange={(ev) => {
                  setIsActive(ev.target.checked ? '1' : '0');
                  onSubmit({
                    ...search,
                    isActive: ev.target.checked ? '1' : '0',
                  });
                }}
              />
            }
          />
        </Grid>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome Completo</TableCell>
                  <TableCell>Login</TableCell>
                  <TableCell>Tipo perfil</TableCell>
                  <TableCell>CPF</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterInfo.loading && <CircularProgress />}
                {filterInfo.data &&
                  filterInfo.data.map((data) => (
                    <TableRow
                      onDoubleClick={() => handleModalUpdate(data)}
                      onContextMenu={
                        !mobile ? (ev) => handleClickRight(ev, data) : undefined
                      }
                      style={{ cursor: 'context-menu' }}
                      onClick={
                        mobile ? (ev) => handleClickLeft(ev, data) : undefined
                      }
                      hover
                      key={data.id}
                    >
                      <TableCell component="th" scope="row">
                        {data.id}
                      </TableCell>
                      <TableCell>{fullName(data)}</TableCell>
                      <TableCell>{data.username}</TableCell>
                      <TableCell>{data.profile}</TableCell>
                      <TableCell>{data.cpf}</TableCell>
                      <TableCell>
                        <Switch
                          color="primary"
                          title="Ativar/ desativar usuário"
                          checked={data.isActive === '1'}
                          name="checkedA"
                          onClick={() => handleModalActive(data)}
                        />
                        <IconButton
                          title="Mais detalhes"
                          onClick={() => handleModalUpdate(data)}
                        >
                          <SearchIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                <Menu
                  keepMounted
                  open={clickRight.mouseY !== null}
                  onClose={handleCloseClickRight}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    clickRight.mouseY !== null && clickRight.mouseX !== null
                      ? {
                          top: clickRight.mouseY,
                          left: clickRight.mouseX,
                        }
                      : undefined
                  }
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  <MenuItem onClick={() => handleModalActive(null, true)}>
                    Ativar/ desativar usuário
                  </MenuItem>
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseClickLeft()}
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  <MenuItem onClick={() => handleModalActive(null, true)}>
                    Ativar/ desativar usuário
                  </MenuItem>
                </Menu>
                {filterInfo.error && (
                  <TableRow>
                    <TableCell>Sem resultados encontrados</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </UIContainer>
      {modalValues && (
        <>
          <Dialog
            open={userActive}
            onClose={() => handleModalActive()}
            fullWidth
          >
            <UIDialogTitle
              id="form-dialog-title"
              onClose={() => handleModalActive()}
            >
              Deseja {modalValues.isActive ? 'desativar' : 'ativar'} o usuario{' '}
              {fullName(modalValues)}
            </UIDialogTitle>
            <DialogActions>
              <Button onClick={() => handleModalActive()}>Cancelar</Button>
              <Button onClick={onActive}>Confirmar</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={view}
            onClose={() => {
              handleModalUpdate();
              setModalValues(null);
            }}
            aria-labelledby="form-dialog-title"
          >
            <Formik
              enableReinitialize
              onSubmit={onUpdate}
              initialValues={modalValues}
            >
              {({ values, errors, setFieldValue, submitForm }) => (
                <Form>
                  <UIDialogTitle
                    id="form-dialog-title"
                    onClose={() => handleModalUpdate()}
                  >
                    {modalValues.name}
                  </UIDialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Field
                          name="name"
                          label="Nome"
                          className={disabledTextField}
                          placeholder="Nome do produto"
                          fullWidth
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="lastName"
                          label="Sobrenome"
                          placeholder="Informe o sobrenome do usuário"
                          className={disabledTextField}
                          fullWidth
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputMask
                          disabled
                          value={values.cpf}
                          mask="999.999.999-99"
                          onChange={(ev) =>
                            setFieldValue(
                              'cpf',
                              ev.target.value.replace(/[^0-9]/g, ''),
                            )
                          }
                        >
                          {() => (
                            <TextField
                              label="CPF"
                              placeholder="Informe o numero do CPF"
                              className={disabledTextField}
                              fullWidth
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="username"
                          label="Login"
                          placeholder="Informe o login do usuário"
                          className={disabledTextField}
                          disabled
                          fullWidth
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel shrink id="users-new-gender">
                            Perfil
                          </InputLabel>
                          <Field
                            name="profile"
                            labelId="users-new-gender"
                            className={disabledTextField}
                            disabled
                            displayEmpty
                            fullWidth
                            as={Select}
                          >
                            <MenuItem disabled value="">
                              <em>Informe o perfil do usuário</em>
                            </MenuItem>
                            <MenuItem value={modalValues.profile}>
                              {profiles[modalValues.profile]}
                            </MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          disabled={cashierInfo.loading}
                          options={cashierInfo.data || []}
                          value={values.cashRegisters}
                          getOptionLabel={(cash) => cash.name}
                          onChange={(event, cash) =>
                            setFieldValue('cashRegisters', cash)
                          }
                          getOptionSelected={(op, cash) =>
                            op.name === cash.name
                          }
                          noOptionsText="Sem opções"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.cashRegisters)}
                              label="Operador do caixa"
                              placeholder="Adicionar mais"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    {JSON.stringify(values) !== JSON.stringify(modalValues) && (
                      <Button
                        onClick={() => handleModalUpdate()}
                        color="primary"
                      >
                        Cancelar
                      </Button>
                    )}
                    <Button
                      onClick={
                        JSON.stringify(values) === JSON.stringify(modalValues)
                          ? () => handleModalUpdate()
                          : () => submitForm()
                      }
                      color="primary"
                    >
                      {JSON.stringify(values) === JSON.stringify(modalValues)
                        ? 'Fechar'
                        : 'Salvar'}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
        </>
      )}
      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={activeInfo}
        setrequestinfo={closeAlert}
        messageSucess="Operação feita com sucesso"
        type="success"
      />
      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={updateInfo}
        setrequestinfo={closeAlert}
        messageSucess={
          updateInfo.data
            ? updateInfo.data.message
            : 'Produto atualizado com sucesso'
        }
        type="success"
      />
    </>
  );
};

export default UserSearch;
