import React, { useEffect, useState } from 'react';

import {
  Badge,
  Collapse,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  Typography,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import useApi from '../../../../utils/useApi';
import './slideperfil.css';
import UIModalExit from '../../../UI/ModalExit/UIModalExit';
import SlideNotifications from '../Notifications/slideNotifications';

const MainSlidePerfil = ({ ...children }) => {
  const [expanded, setExpanded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [collapseNotication, setCollapseNotification] = useState(false);
  const [notificationLoad, notificationInfo] = useApi({
    method: 'get',
    params: { viewed: '0' },
    url: '/users/notifications',
  });

  const [notificationView] = useApi({
    method: 'get',
    url: '/users/notifications',
  });

  useEffect(() => {
    notificationLoad();
    const interval = setInterval(() => notificationLoad(), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function toggleCollapseNotification(id) {
    setCollapseNotification(id !== collapseNotication && id);
  }

  function toggleExpandedCollapse() {
    setExpanded(!expanded);
  }

  function toggleDialog() {
    setOpenDialog(!openDialog);
  }

  function closeNotification(id, viewed) {
    const params = { id, viewed };
    notificationView({
      params,
      onCompleted: () => {
        notificationLoad();
      },
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid className="main-base-slide-item">
        <Grid
          container
          direction="row"
          alignItems="center"
          justify={children.open ? 'flex-start' : 'center'}
          className="main-base-slide-perfil__item"
        >
          <Badge
            color="secondary"
            variant={notificationInfo.data !== null ? 'dot' : 'standard'}
            onClick={toggleExpandedCollapse}
          >
            {children.icon}
          </Badge>

          {/* sub-menu ao houver */}
          <Hidden xsUp={children.open}>
            <List
              component="div"
              disablePadding
              className="main-base-slide-perfil__submenu"
            >
              <span>{children.credencials.name}</span>
              {children.options.map((option) => (
                <ListItem
                  onClick={option.exit ? toggleDialog : option.action}
                  key={children.options.indexOf(option) + 1}
                  button
                  style={{ padding: '5px 0px' }}
                >
                  <ListItemText primary={option.name} />
                </ListItem>
              ))}
            </List>
          </Hidden>

          <Hidden xsUp={!children.open}>
            <Grid
              container
              direction="column"
              className="main-base-slide-perfil__operador"
              onClick={toggleExpandedCollapse}
            >
              <span>
                {children.credencials.name} {children.credencials.lastName}
              </span>
              <Typography paragraph>{children.function}</Typography>
            </Grid>
          </Hidden>

          {children.open && <Grid item xs />}

          {/* noticações */}
          {children.open && (
            <IconButton
              onClick={handleClick}
              className="main-base-slide-perfil__notification"
            >
              <Badge
                badgeContent={
                  notificationInfo.data === null
                    ? 0
                    : notificationInfo.data.length
                }
                color="secondary"
              >
                <NotificationsIcon fontSize="small" />
              </Badge>
            </IconButton>
          )}
        </Grid>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '350px',
              height: '500px',
              overflowY: 'auto',
              '::WebkitScrollbar': {
                width: '3px',
                backgroundColor: '#a6a6a6',
              },
              '::WebkitScrollbarThumb': {
                borderRadius: '10px',
                backgroundColor: '#17497F',
              },
            },
          }}
        >
          <Typography
            noWrap
            className="main-base-slide-perfil__text-notification"
          >
            Notificações do sistema
          </Typography>
          <Divider />
          {notificationInfo.data === null && !notificationInfo.loading && (
            <Typography noWrap style={{ padding: '10px' }}>
              Sem novas notificações
            </Typography>
          )}
          {notificationInfo.loading && (
            <Typography noWrap style={{ padding: '10px' }}>
              Procurando novas
            </Typography>
          )}

          {notificationInfo.data &&
            notificationInfo.data.map((notification, index) => (
              <SlideNotifications
                closeNotification={closeNotification}
                handleClose={handleClose}
                isPair={index % 2 === 0}
                toggleExpandedCollapse={toggleCollapseNotification}
                expanded={collapseNotication}
                key={notification.id}
                notification={notification}
              />
            ))}
        </Menu>
        {children.open && (
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            style={{ width: '100%' }}
          >
            <List component="div" disablePadding>
              {children.options.map((option) => (
                <ListItem
                  onClick={option.exit ? toggleDialog : option.action}
                  key={children.options.indexOf(option) + 1}
                  button
                >
                  <ListItemText primary={option.name} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </Grid>
      <UIModalExit
        open={openDialog}
        toggleDialog={toggleDialog}
        exit={children.exit}
      />
    </>
  );
};

export default MainSlidePerfil;
