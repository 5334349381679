import { createContext } from 'react';

const StoreContext = createContext({
  credencials: null,
  setCredencials: () => {},
  openModal: false,
  setOpenModal: () => {},
  modalCashierSelect: false,
  setModalCashierSelect: () => {},
  modalCashierOpen: { open: false, cashierId: null },
  setModalCashierOpen: () => {},
});

export default StoreContext;
