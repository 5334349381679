import * as Yup from 'yup';
import { format } from 'date-fns';

export default Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  lastName: Yup.string(),
  cpf: Yup.number(),
  rg: Yup.string(),
  bornIn: Yup.date()
    .max(format(new Date(), 'yyyy-MM-dd'), 'Data invalida')
    .nullable('Campo obrigatório'),
  email: Yup.string().email('Digite um email valido'),
  gender: Yup.number(),
  marialStatus: Yup.number(),
  children: Yup.number()
    .required('Campo obrigatório')
    .min(0, 'Inválido')
    .integer('Inválido'),
  whatsapp: Yup.number(),
  city: Yup.string(),
  neighborhood: Yup.string(),
  street: Yup.string(),
  houseNumber: Yup.string(),
});
