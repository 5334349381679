import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import './Search.css';

const UIButtonSearch = ({ ...children }) => (
  <Grid
    className="button-search__container"
    container
    direction="row"
    justify="flex-end"
    alignItems="flex-end"
    item
    {...children}
  >
    <IconButton type="submit" className="button-search">
      <SearchIcon />
    </IconButton>
  </Grid>
);

export default UIButtonSearch;
