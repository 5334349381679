import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Collapse,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  // useMediaQuery,
  // useTheme,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import UIContainer from '../../../UI/Container/UIContainer';
import useApi from '../../../../utils/useApi';
// import UIModalOldForm from '../../../UI/ModalOldForm/UIModalOldForm';
import UIFormContainerSale from '../../../UI/Form/ContainerSale/UIFormContainerSale';
import SaleSearchItem from '../../Modal/searchItem';
import {
  emptyObject,
  formattedCurrency,
  isEqualObject,
} from '../../../../utils/utils';
import SaleSelect from '../../Select/saleSelect';
import UIDialogConfirm from '../../../UI/Dialog/Confirm/UIDialogConfirm';
import SaleConfirm from '../Confirm/saleConfirm';
import './saleNew.css';
import useStyles from '../../../../utils/useStyle';

const initialItem = {
  id: '',
  name: '',
  amount: 0,
  valueUn: 0,
  valueTotal: 0,
};

const initialOptions = {
  customer: null,
  plot: 1,
  promotion: null,
  totalGain: 0,
  discount: 0,
  total: 0,
  interest: 0,
  totalSum: 0,
  observation: '',
  amountPaid: 0,
  paymentMethod: 3,
};

const initialCalculate = {
  onlyCalculate: true,
  cashRegister: 1,
  customer: 1,
  products: {},
  services: {},
  plots: {},
  interest: 0,
  promotion: 1,
  splitRemaining: 0,
  session_id: '',
  identifier: '',
  responsible: '',
};

const SaleNew = (children) => {
  const { saleMetadata, refreshCards, handleModalSale } = children;
  const [open, setOpen] = useState(false);
  const { disabledTextField } = useStyles();

  // botôes para foco
  const btnInsert = document.querySelector('.sale-new__insert-button');
  const inputBarCode = document.getElementById('sale-new-search-item');

  const [loading, setLoading] = useState(true);

  // verificar se é mobile e constantes para versão mobile
  // const theme = useTheme();
  // const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mobile = true;
  const [collapse, setCollapse] = useState(false);
  const [collapseAdd, setCollapseAdd] = useState(true);
  const [scroll, setScroll] = useState(1);

  // valores para o container de salvar e opcoes
  const [cartValues, setCartValues] = useState(initialOptions);
  // array dos produtos na lista
  const [listItens, setListItems] = useState([]);
  // constante para guardar valores para calcular
  const [calculate, setCalculate] = useState(initialCalculate);

  // constante para armazenar input de codigo de barra
  const [barCode, setBarCode] = useState('');

  // consante para controlar o modal de confirmar venda
  const [modalConfirm, setModalConfirm] = useState(false);

  // modal search item
  const [modalSearch, setModalSearch] = useState(false);

  // select item no modal
  const [item, setItem] = useState(initialItem);

  // controle do alert para sucesso ou falha quando adiciona um item
  // e quando confirma a lista
  const [alertList, setAlertList] = useState(false);

  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemChose, setItemChose] = useState(null);

  const handleClickLeft = (event, data) => {
    setItemChose(data);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setItemChose(null);
    setAnchorEl(null);
  };

  const [salesLoad, salesInfo, setSaleInfo] = useApi({
    method: 'put',
    url: '/sales/sessions',
  });

  const [salesSessionsLoad, salesSessionsInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/sales/sessions',
  });

  const [searchProductLoad] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/products',
  });

  const [searchServicesLoad] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/services',
  });

  function changeModal() {
    if (open) {
      setListItems(false);
      setCartValues(false);
      setCalculate(false);
    }
    setOpen(!open);
  }

  function changeViewItens() {
    setCollapse(!collapse);
    setCollapseAdd(false);
  }

  function changeAddItens() {
    setCollapseAdd(!collapseAdd);
    setCollapse(false);
  }

  function changeSearchItens() {
    setModalSearch(!modalSearch);
  }

  function scrollTo(hash) {
    document.location.hash = `#${hash}`;
  }

  useEffect(() => {
    const fetchAndOrganizeData = async () => {
      salesSessionsLoad({
        params: { session_id: saleMetadata.session_id },
        onCompleted: async (res) => {
          if (res) {
            setLoading(true);
            const { data } = res;
            const value = data[0];
            const { products, services } = value;
            const formCalculate = {
              onlyCalculate: true,
              cashRegister: value.cashRegister,
              customer: value.customer,
              products: value.products,
              services: value.services,
              plots: value.plots,
              interest: value.interest,
              promotion: value.promotion,
              splitRemaining: value.splitRemaining ? 1 : 0,
              session_id: value.session_id,
              identifier: value.identifier,
              responsible: value.responsible,
              observation: `${value.identifier} - ${value.responsible}`,
            };
            const formSaleOptions = {
              customer: value.customer,
              plots: value.plots,
              promotion: value.promotion,
              totalGain: value.sale_metadata.totalSum,
              discount: value.sale_metadata.discount,
              total: value.sale_metadata.total,
              interest: value.interest,
              totalSum: value.sale_metadata.totalSum,
              observation: `${value.identifier} - ${value.responsible}`,
              amountPaid: value.amountPaid,
              paymentMethod: value.paymentMethod || 3,
            };

            const productPromises = Object.keys(products).map(
              (product) =>
                new Promise((resolve) => {
                  searchProductLoad({
                    params: { id: product, barCode: product },
                    onCompleted: (tmpRes) => {
                      if (tmpRes && tmpRes.data.length > 0) {
                        const tmpData = tmpRes.data[0];
                        resolve({
                          id: parseInt(tmpData.id),
                          amount: products[product],
                          name: tmpData.name,
                          valueUn: tmpData.value,
                          valueTotal: tmpData.value * products[product],
                          typeItem: 'product',
                          barCode: tmpData.barCode,
                        });
                      } else {
                        resolve(undefined);
                      }
                    },
                  });
                }),
            );

            // Fetch service details asynchronously
            const servicePromises = Object.keys(services).map(
              (service) =>
                new Promise((resolve) => {
                  searchServicesLoad({
                    params: { id: service, barCode: service },
                    onCompleted: (tmpRes) => {
                      if (tmpRes && tmpRes.data.length > 0) {
                        const tmpData = tmpRes.data[0];
                        resolve({
                          id: parseInt(tmpData.id),
                          amount: services[service],
                          name: tmpData.name,
                          valueUn: tmpData.value,
                          valueTotal: tmpData.value * services[service],
                          typeItem: 'service',
                          barCode: tmpData.barCode,
                        });
                      } else {
                        resolve(undefined);
                      }
                    },
                  });
                }),
            );

            // Wait for all product and service details to be fetched
            const fetchedProducts = await Promise.all(productPromises);
            const fetchedServices = await Promise.all(servicePromises);

            // Combine fetched products and services
            const combinedList = [
              ...fetchedProducts.filter((item) => item !== undefined),
              ...fetchedServices.filter((item) => item !== undefined),
            ];

            // Update state with fetched and transformed data
            setLoading(false);
            setListItems((prevList) => [...prevList, ...combinedList]);
            setCartValues(formSaleOptions);
            setCalculate(formCalculate);
          }
          changeModal();
        },
      });
    };

    fetchAndOrganizeData();
  }, [saleMetadata]);

  function searchBarCode(value) {
    setBarCode(value);
    searchProductLoad({
      params: { barCode: value },
      onCompleted: (res) => {
        if (res) {
          const data = res.data[0];
          setItem({
            id: data.id,
            amount: 1,
            name: data.name,
            valueUn: data.value,
            valueTotal: data.value,
            typeItem: 'product',
          });
          document.querySelector('.sale-new__insert-button').focus();
        }
      },
    });
    searchServicesLoad({
      params: { barCode: value },
      onCompleted: (res) => {
        if (res) {
          const data = res.data[0];
          setItem({
            id: data.id,
            amount: 1,
            name: data.name,
            valueUn: data.value,
            valueTotal: data.value,
            typeItem: 'service',
          });
          btnInsert.focus();
        }
      },
    });
  }

  function checkCart(list = listItens) {
    const allProducts = list.filter((value) => value.typeItem === 'product');
    const allServices = list.filter((value) => value.typeItem === 'service');
    const products = allProducts.reduce((acc, { id, amount }) => {
      acc[id] = amount;
      return acc;
    }, {});
    const services = allServices.reduce((acc, { id, amount }) => {
      acc[id] = amount;
      return acc;
    }, {});

    const data = {
      ...calculate,
      products,
      services,
    };
    return emptyObject(data);
  }

  function selectItem(values) {
    setModalSearch(!modalSearch);
    setItem({
      id: values.id,
      amount: 1,
      name: values.name,
      valueUn: values.value,
      valueTotal: values.value,
      typeItem: values.typeItem,
      barCode: values.barCode,
    });
    setBarCode(values.barCode);
    btnInsert.focus();
  }

  function addItem(values) {
    if (item.name) {
      const testItens = [...listItens, values];
      let data = checkCart(testItens);

      const checkDuplicateItem = listItens.find((currentValue, index) => {
        if (currentValue.barCode === barCode) {
          listItens[index].amount =
            Number(listItens[index].amount) + Number(values.amount);
        }
        return currentValue.barCode === barCode;
      });

      if (checkDuplicateItem) {
        data = checkCart(listItens);
        salesLoad({
          data,
          onCompleted: (res) => {
            if (res) {
              setListItems(listItens);
              setCartValues({ ...cartValues, ...res.data.sale_metadata });
              setCalculate({ ...calculate, ...data });
              setItem(initialItem);
              inputBarCode.focus();
              if (mobile) {
                changeViewItens();
              }
              refreshCards({});
            }
          },
        });
      } else {
        data = checkCart(testItens);
        salesLoad({
          data,
          onCompleted: (res) => {
            if (res) {
              setListItems(testItens);
              setCartValues({ ...cartValues, ...res.data.sale_metadata });
              setCalculate({ ...calculate, ...data });
              setItem(initialItem);
              inputBarCode.focus();
              if (mobile) {
                changeViewItens();
              }
              refreshCards({});
            }
          },
        });
      }
    }
    setBarCode('');
  }

  function editItem(index, value) {
    if (value >= 0) {
      const oldAmount = listItens[index].amount;
      const oldValueTotal = listItens[index].valueTotal;

      listItens[index].amount = value;
      listItens[index].valueTotal =
        Number(value) * Number(listItens[index].valueUn);

      const data = checkCart(listItens);

      salesLoad({
        data: emptyObject(data),
        onCompleted: (res) => {
          if (res) {
            setAlertList(false);
            setListItems(listItens);
            setCalculate({ ...calculate, ...data });
            setCartValues({ ...cartValues, ...res.data.sale_metadata });
            refreshCards({});
          } else {
            listItens[index].amount = oldAmount;
            listItens[index].valueTotal = oldValueTotal;
            setListItems(listItens);
            if (value) setAlertList(true);
          }
        },
      });
    }
  }

  function removeItem(index) {
    const itens = listItens.filter((data, indexData) => indexData !== index);

    const data = checkCart(itens);

    if (itens.length <= 0) {
      setListItems([]);
      setCartValues(initialOptions);
      setCalculate(initialCalculate);
      setAlertList(false);
    } else {
      salesLoad({
        data,
        onCompleted: (res) => {
          if (res) {
            setListItems(itens);
            setCalculate({ ...calculate, ...data });
            setCartValues({ ...cartValues, ...res.data.sale_metadata });
            refreshCards({});
          }
        },
      });
    }

    if (mobile) {
      handleCloseClickLeft();
    }
  }

  function selectClient(value) {
    setCartValues({ ...cartValues, customer: value || null });
    setCalculate({ ...calculate, customer: value.id || null });
    salesLoad({
      data: emptyObject({ ...calculate, customer: value.id || null }),
    });
  }

  function selectPlots(value) {
    if (value >= 0 && value <= 72) {
      cartValues.plot = value;
      calculate.plots = value;
      salesLoad({
        data: emptyObject(calculate),
        onCompleted: (res) => {
          if (res) {
            setCartValues({ ...cartValues, ...res.data.sale_metadata });
            setCalculate({ ...calculate, plots: Number(value) || null });
          }
        },
      });
    }
  }

  function selectPromotion(value) {
    cartValues.promotion = value;
    calculate.promotion = value && value.id;
    salesLoad({
      data: emptyObject(calculate),
      onCompleted: (res) => {
        if (res) {
          setCartValues({ ...cartValues, ...res.data.sale_metadata });
          setCalculate({ ...calculate, promotion: value && value.id });
          refreshCards({});
        } else {
          calculate.promotion = null;
          setAlertList(true);
          setCartValues({ ...cartValues, promotion: null });
        }
      },
    });
  }

  function selectInterest(value) {
    if (value >= 0 && value <= 100) {
      cartValues.interest = value;

      const objInterest = {
        percentage: value || 0,
        type: 'simples',
      };

      calculate.interest = objInterest;

      salesLoad({
        data: emptyObject(calculate),
        onCompleted: (res) => {
          if (res) {
            setAlertList(false);
            setCartValues({ ...cartValues, ...res.data.sale_metadata });
            setCalculate({ ...calculate, interest: objInterest });
            refreshCards({});
          }
        },
      });
    }
  }

  function confirmSale() {
    salesLoad({
      data: emptyObject(calculate),
      onCompleted: (res) => {
        if (res) {
          setCartValues({ ...cartValues, ...res.data.sale_metadata });
          setModalConfirm(!modalConfirm);
          refreshCards({});
        } else {
          setAlertList(true);
        }
      },
    });
  }

  // reseta o form após concluir venda
  function resetform() {
    setCartValues(initialOptions);
    setCalculate(initialCalculate);
    setListItems([]);
    clearFormStorage();
    setModalConfirm(!modalConfirm);
  }

  // limpa o form antigo do local storage
  // function clearForm() {
  //   setCartValues(initialOptions);
  //   setListItens([]);
  //   setCalculate(initialCalculate);
  //   clearFormStorage();
  //   setOpen(!open);
  // }

  function nextStateMobile() {
    const select = document.getElementById('sale-new-select');
    const confirm = document.getElementById('sale-new-confirm');
    const back = document.querySelector('.sale-new-return');
    if (scroll === 1) {
      select.style.visibility = 'visible';
      select.style.opacity = 1;
      scrollTo('sale-new-select');
    }
    if (scroll === 2) {
      confirm.style.visibility = 'visible';
      confirm.style.opacity = 1;
      back.style.bottom = '10px';
      scrollTo('sale-new-confirm');
    }
    setScroll(scroll + 1);
  }

  function returnStateMobile() {
    const select = document.getElementById('sale-new-select');
    const confirm = document.getElementById('sale-new-confirm');
    const back = document.querySelector('.sale-new-return');
    if (scroll === 2) {
      select.style.visibility = 'hidden';
      select.style.opacity = 0;
      scrollTo('sale-new-search');
    }
    if (scroll === 3) {
      confirm.style.visibility = 'hidden';
      confirm.style.opacity = 0;
      back.style.bottom = '60px';
      scrollTo('sale-new-select');
    }
    setScroll(scroll - 1);
  }

  return (
    <Grid
      container
      id="sale-new-search"
      style={{ height: mobile ? 'auto' : '100%', scrollBehavior: 'smooth' }}
    >
      {loading && <CircularProgress />}
      {!loading && (
        <Grid
          container
          id="sale-new-search"
          style={{ height: mobile ? 'auto' : '100%', scrollBehavior: 'smooth' }}
        >
          <Formik enableReinitialize initialValues={item} onSubmit={addItem}>
            {({ errors, touched, values, setFieldValue }) => (
              <Form style={{ width: '100%' }}>
                <UIContainer container style={{ minHeight: 'auto' }}>
                  {mobile && (
                    <ListItem button onClick={changeAddItens}>
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Adicionar itens" />
                      {collapseAdd ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                  )}
                  <Collapse
                    in={mobile ? collapseAdd : true}
                    timeout="auto"
                    unmountOnExit
                    style={{ width: '100%' }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="sale-new-search-item"
                          value={barCode}
                          autoComplete="off"
                          label="BARCODE"
                          onChange={(ev) => searchBarCode(ev.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Atalho: Shift + Espaço"
                                  onClick={changeSearchItens}
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Field
                          name="name"
                          label="Item"
                          placeholder="Nome do item"
                          fullWidth
                          className={disabledTextField}
                          disabled
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <TextField
                          value={values.amount}
                          onChange={(ev) => {
                            if (ev.target.value >= 0) {
                              setFieldValue('amount', ev.target.value);
                              setFieldValue(
                                'valueTotal',
                                values.valueUn * Number(ev.target.value),
                              );
                            }
                          }}
                          label="Qntd."
                          type="number"
                          error={Boolean(errors.amount)}
                          helperText={touched.amount && errors.amount}
                          placeholder="Insira a quantidade"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <CurrencyTextField
                          type="tel"
                          value={values.valueUn}
                          label="Valor unitario"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          fullWidth
                          className={disabledTextField}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <CurrencyTextField
                          type="tel"
                          value={values.valueTotal}
                          label="Valor total"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          fullWidth
                          className={disabledTextField}
                          disabled
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        justify={mobile ? 'flex-end' : 'center'}
                        alignItems={mobile ? 'flex-end' : 'center'}
                        xs={12}
                        md={11}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          className="sale-new__insert-button"
                          startIcon={
                            (searchProductLoad.loading && (
                              <CircularProgress size="0.9rem" color="inherit" />
                            )) ||
                            (searchServicesLoad.loading && (
                              <CircularProgress size="0.9rem" color="inherit" />
                            ))
                          }
                        >
                          {`>`}
                        </Button>
                      </Grid>
                    </Grid>
                  </Collapse>
                </UIContainer>
              </Form>
            )}
          </Formik>

          <SaleSearchItem
            open={modalSearch}
            mobile={mobile}
            handleModal={changeSearchItens}
            selectItem={selectItem}
            btnInsert={btnInsert}
          />

          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{
              height: mobile ? 'auto' : '65%',
              paddingBottom: '15px',
            }}
          >
            <UIContainer
              container
              item
              style={{
                width: mobile ? '100%' : '70%',
                height: mobile ? 'auto' : '100%',
                overflow: 'auto',
              }}
            >
              {!mobile ? (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Qntd.</TableCell>
                        <TableCell>Produto</TableCell>
                        <TableCell>Valor un</TableCell>
                        <TableCell>Valor total</TableCell>
                        <TableCell>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listItens !== [] &&
                        listItens &&
                        listItens.map((data, index) => (
                          <TableRow key={data.id} hover>
                            <TableCell
                              style={{ maxWidth: 100 }}
                              component="th"
                              scope="row"
                            >
                              <TextField
                                value={data.amount}
                                onChange={(ev) =>
                                  editItem(index, ev.target.value)
                                }
                                onBlur={(ev) => {
                                  if (
                                    !ev.target.value ||
                                    ev.target.value === '0'
                                  ) {
                                    editItem(index, 1);
                                  }
                                }}
                                type="number"
                              />
                            </TableCell>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>
                              {formattedCurrency(data.valueUn)}
                            </TableCell>
                            <TableCell>
                              {formattedCurrency(data.valueTotal)}
                            </TableCell>
                            <TableCell>
                              <span title="Excluir item">
                                <IconButton onClick={() => removeItem(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Qntd.</TableCell>
                          <TableCell>Produto</TableCell>
                          <TableCell>Valor un</TableCell>
                          <TableCell>Valor total</TableCell>
                          <TableCell>Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listItens !== [] &&
                          listItens &&
                          listItens.map((data, index) => (
                            <TableRow key={data.id} hover>
                              <TableCell component="th" scope="row">
                                <TextField
                                  value={data.amount}
                                  onChange={(ev) =>
                                    editItem(index, ev.target.value)
                                  }
                                  type="number"
                                />
                              </TableCell>
                              <TableCell
                                onClick={(ev) => handleClickLeft(ev, index)}
                              >
                                {data.name}
                              </TableCell>
                              <TableCell
                                onClick={(ev) => handleClickLeft(ev, index)}
                              >
                                {formattedCurrency(data.valueUn)}
                              </TableCell>
                              <TableCell
                                onClick={(ev) => handleClickLeft(ev, index)}
                              >
                                {formattedCurrency(data.valueTotal)}
                              </TableCell>
                              <TableCell>
                                <span title="Excluir item">
                                  <IconButton onClick={() => removeItem(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </UIContainer>
            <UIContainer
              id="sale-new-select"
              container
              item
              style={{
                width: mobile ? '100%' : '29%',
                height: mobile ? 'auto' : '100%',
                overflow: 'auto',
              }}
            >
              <ListItem button onClick={changeViewItens}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Finalizar venda" />
                {collapse ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={collapse}
                timeout="auto"
                unmountOnExit
                style={{ overflow: 'auto', width: '100%' }}
              >
                <SaleSelect
                  options={cartValues}
                  setOptions={setCartValues}
                  selectPlots={selectPlots}
                  selectClient={selectClient}
                  selectPromotion={selectPromotion}
                  selectInterest={selectInterest}
                  changeSearchItens={changeSearchItens}
                  confirmSale={confirmSale}
                  inputBarCode={inputBarCode}
                />
              </Collapse>
            </UIContainer>
          </Grid>

          <UIFormContainerSale
            id="sale-new-confirm"
            mobile={mobile}
            style={{ minHeight: mobile ? 'auto' : '20%' }}
            onConfirmed={confirmSale}
            sale={salesInfo}
            data={cartValues}
          />

          <SaleConfirm
            open={modalConfirm}
            options={cartValues}
            calculate={calculate}
            setOptions={setCartValues}
            handleModal={() => setModalConfirm(!modalConfirm)}
            handleModalSale={handleModalSale}
            refreshCards={refreshCards}
            resetform={resetform}
          />

          <UIDialogConfirm
            open={alertList}
            data={salesInfo}
            timeSucess={3000}
            messageSucess="Ação feita com sucesso!"
            close={() => setAlertList(!alertList)}
            setrequestinfo={setSaleInfo}
          />

          {/* <UIModalOldForm
        open={open}
        changeModal={changeModal}
        setFormValues={clearForm}
        msgAlert="Há dados no carrinho, deseja continuar a venda?"
        msgContinue="Continuar venda"
        msgNew="Nova venda"
      /> */}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseClickLeft}
          >
            <MenuItem onClick={() => removeItem(itemChose)}>
              Exluir produto
            </MenuItem>
          </Menu>

          {mobile && scroll !== 3 && (
            <Tooltip className="sale-new-next" title="Avançar">
              <Fab
                style={{ backgroundColor: '#178208', color: '#fff' }}
                size="small"
                variant="extended"
                onClick={nextStateMobile}
              >
                <ArrowDownwardIcon />
                Avançar
              </Fab>
            </Tooltip>
          )}
          {mobile && scroll > 1 && scroll < 4 && (
            <Tooltip className="sale-new-return" title="Voltar">
              <Fab
                style={{ backgroundColor: '#C40409', color: '#fff' }}
                size="small"
                variant="extended"
                onClick={returnStateMobile}
              >
                <ArrowUpwardIcon />
                Voltar
              </Fab>
            </Tooltip>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SaleNew;
