import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  category: Yup.object()
    .required('Campo obrigatório')
    .nullable('Campo obrigatório'),
  supplier: Yup.object()
    .required('Campo obrigatório')
    .nullable('Campo obrigatório'),
  value: Yup.number().min(0.01, 'Inválido').required('Campo obrigatório'),
  particulars: Yup.object().required('Campo obrigatório'),
  generateCost: Yup.boolean()
});
