import React from 'react';
import PageContent from '../../../components/Main/Content/mainContent';
import ProductNew from '../../../components/Product/New/productNew';

const ProdutoNovo = () => (
  <PageContent>
    <ProductNew />
  </PageContent>
);

export default ProdutoNovo;
