import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import validationSchema from './validationSchema';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import UIFormContainerSave from '../../UI/Form/ContainerSave/UIFormContainer';
import useApi from '../../../utils/useApi';
import useStorage from '../../../utils/useStorage';
import UIModalOldForm from '../../UI/ModalOldForm/UIModalOldForm';
import GreenSwitch from '../../UI/Form/Switch/GreenSwitch';

const initialState = {
  name: '',
  lastName: '',
  cpf: '',
  username: '',
  password: '',
  cashRegisters: [],
  isActive: true,
};

const UserNew = () => {
  const [open, setOpen] = useState(false);
  const [oldValues, setOldValues] = useStorage('formUsers');

  const [registerLoad, registerInfo, setRegisterInfo] = useApi({
    method: 'post',
    url: '/users',
  });

  const [cashierLoad, cashierInfo] = useApi({
    method: 'get',
    url: '/cash-registers',
    params: { isActive: '1' },
  });

  function changeModal() {
    setOpen(!open);
  }

  function setFormValues() {
    setOldValues(null);
    changeModal();
  }

  useEffect(() => {
    cashierLoad();
    if (
      oldValues &&
      JSON.stringify(oldValues) !== JSON.stringify(initialState)
    ) {
      changeModal();
    }
  }, []);

  function onSubmit(values) {
    const data = {
      ...values,
      isActive: values.isActive ? 1 : '0',
      cashRegisters: values.cashRegisters.map((value) => value.id),
    };
    registerLoad({
      data,
    });
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={oldValues || initialState}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form onBlur={() => setOldValues(values)}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Novo usuário" />
                <FormControlLabel
                  label={values.isActive ? 'Usuário ativo' : 'Usuário inativo'}
                  labelPlacement="start"
                  control={
                    <GreenSwitch
                      checked={values.isActive}
                      onChange={(ev) =>
                        setFieldValue('isActive', ev.target.checked)
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name="name"
                  label="Nome"
                  error={Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  placeholder="Informe o nome do usuário"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Field
                  name="lastName"
                  label="Sobrenome"
                  error={Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  placeholder="Informe o sobrenome do usuário"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputMask
                  value={values.cpf}
                  mask="999.999.999-99"
                  onChange={(ev) =>
                    setFieldValue('cpf', ev.target.value.replace(/[^0-9]/g, ''))
                  }
                >
                  {() => (
                    <TextField
                      label="CPF"
                      error={Boolean(errors.cpf)}
                      helperText={touched.cpf && errors.cpf}
                      placeholder="Informe o numero do CPF"
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="username"
                  error={Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                  label="Login"
                  placeholder="Informe o login do usuário"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="password"
                  error={Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  type="password"
                  label="Senha"
                  placeholder="Informe a senha do usuário"
                  fullWidth
                  as={TextField}
                />
              </Grid>
            </UIContainer>
            <UIContainer container direction="row">
              <Grid item xs={12}>
                <UIFormTitle title="Opções do usuário" />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  disabled={cashierInfo.loading}
                  options={cashierInfo.data || []}
                  value={values.cashRegisters}
                  getOptionLabel={(cash) => cash.name}
                  onChange={(event, cash) =>
                    setFieldValue('cashRegisters', cash)
                  }
                  getOptionSelected={(op, cash) => op.name === cash.name}
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.cashRegisters)}
                      helperText={touched.cashRegisters && errors.cashRegisters}
                      label="Operador do caixa"
                      placeholder="Adicionar mais"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel shrink id="users-new-gender">
                    Perfil
                  </InputLabel>
                  <Field
                    name="profile"
                    error={Boolean(errors.profile)}
                    helperText={touched.profile && errors.profile}
                    labelId="users-new-gender"
                    displayEmpty
                    fullWidth
                    as={Select}
                  >
                    <MenuItem disabled value="1">
                      <em>Informe o perfil do usuário</em>
                    </MenuItem>
                    <MenuItem value={1}>Operador</MenuItem>
                    <MenuItem value={2}>Gerente</MenuItem>
                    <MenuItem value={3}>Administrador</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
            </UIContainer>
            <UIFormContainerSave
              container
              justify="center"
              direction="row"
              messageSucess="usuário cadastrado com sucesso"
              messageError="Alguma coisa está errada, verifique os campos"
              error={registerInfo.error}
              loading={registerInfo.loading || ''}
              data={registerInfo.data}
              setRegisterInfo={setRegisterInfo}
              setFormValues={() => setOldValues(null)}
            />
            <UIModalOldForm
              open={open}
              changeModal={changeModal}
              setFormValues={setFormValues}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserNew;
