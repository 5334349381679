import React from 'react';
import CostsShow from '../../../components/Costs/Show/costsShow';
import PageContent from '../../../components/Main/Content/mainContent';

const CustoNovo = () => (
  <PageContent>
    <CostsShow />
  </PageContent>
);

export default CustoNovo;
