import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Tooltip,
  Bar,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  LabelList,
} from 'recharts';
import { formattedCurrency } from '../../../../utils/utils';

const CustomBarLabel = (payload) => {
  const { x, y, width, value } = payload;
  return (
    <text
      x={x + width / 2}
      y={y + width / 3}
      fill="#FFF"
      textAnchor="middle"
      dy={25}
      fontSize={14}
    >{`${value.toFixed(2)}%`}</text>
  );
};
const CustomBarLabelEntry = (payload) => {
  const { x, y, width, value } = payload;
  return (
    <text
      x={x + width / 2}
      y={y + width / 1}
      fill="#FFF"
      textAnchor="middle"
      dy={25}
      fontSize={14}
    >{`${value.toFixed(2)}%`}</text>
  );
};

const CustomBarLabelDiscount = (payload) => {
  const { x, y, width, value } = payload;
  return (
    <text
      x={x + width / 2}
      y={y + width / 2}
      fill="#dfba69"
      textAnchor="middle"
      dy={25}
      fontSize={14}
    >{`${value.toFixed(2)}%`}</text>
  );
};

const CashierLogisticBarUsers = (children) => {
  const { dataApi, dataLabel, caption } = children;
  const [data, setData] = useState(null);

  useEffect(() => {
    const dataEntries = dataApi.entries.moves;
    const dataOuts = dataApi.outs.moves;
    const dataDiscounts = dataApi.discounts.moves
    let dataValues = [];

    if (dataEntries) {
      dataValues = dataEntries.reduce((acc, values) => {
        const userExist = acc.find((value) => value.name === values.userId);
        if (userExist) {
          userExist.Entradas += Math.abs(values.value);
        } else {
          acc.push({
            name: `${values.userId}`,
            Entradas: Math.abs(values.value),
            Descontos: 0,
            Saidas: 0,
          });
        }
        return acc;
      }, []);
    }

    if (dataDiscounts) {
      console.log(dataValues)
      dataDiscounts.forEach((discount) => {
        const userExist = dataValues.find((val) => val.name === discount.userId);
        if (userExist) {
          userExist.Descontos += Math.abs(discount.value);
        } else {
          dataValues.push({
            name: `${discount.userId}`,
            Saidas: 0,
            Descontos: Math.abs(discount.value),
            Entradas: 0,
          });
        }
      });
    }

    if (dataOuts) {
      dataOuts.forEach((out) => {
        console.log(out)
        const userExist = dataValues.find((val) => val.name === out.userId);
        if (userExist) {
          userExist.Saidas += Math.abs(out.value);
        } else {
          dataValues.push({
            name: `${out.userId}`,
            Saidas: Math.abs(out.value),
            Descontos: 0,
            Entradas: 0,
          });
        }
      });
    }


    if (dataValues) {
      dataValues.forEach((value, index) => {
        // definindo porcentagem de cada usuario
        const total = value.Entradas + value.Saidas + value.Descontos;
        dataValues[index].percentageEntry = (value.Entradas / total) * 100;
        dataValues[index].percentageOut = (value.Saidas / total) * 100;
        dataValues[index].percentageDiscounts = (value.Descontos / total) * 100;
        // definindo nome do usuario
        dataValues[index].name = dataLabel.find(
          (val) => val.id === value.name,
        ).name;
      });
      setData(dataValues);
    }
  }, []);
  return (
    <>
      <Typography variant="body2">{caption}</Typography>
      <ResponsiveContainer height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis dataKey="name" />
          <Tooltip
            formatter={(value) => formattedCurrency(value)}
            cursor={{ fill: '#FFF' }}
          />
          <Legend />
          <Bar barSize={50} dataKey="Entradas" stackId="moves" fill="#1B79DF">
            <LabelList dataKey="percentageEntry" content={CustomBarLabelEntry} />
          </Bar>

          <Bar barSize={50} dataKey="Descontos" stackId="moves" fill="#f39708">
            <LabelList dataKey="percentageDiscounts" content={CustomBarLabelDiscount} />
          </Bar>

          <Bar barSize={50} dataKey="Saidas" stackId="moves" fill="#D60429">
            <LabelList dataKey="percentageOut" content={CustomBarLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default CashierLogisticBarUsers;
