import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';

const UIButtonRefresh = ({ ...children }) => (
  <Grid
    className="button-search__container"
    container
    direction="row"
    justify="flex-end"
    alignItems="flex-end"
    item
    {...children}
  >
    <IconButton type="submit" className="button-search">
      <SyncIcon />
    </IconButton>
  </Grid>
);

export default UIButtonRefresh;
