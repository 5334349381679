import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Grid,
  Tab,
  Tabs,
  Typography,
  DialogActions,
  DialogContent,
  Tooltip,
  Fab,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { TabContext, TabPanel } from '@material-ui/lab';
// import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from '@material-ui/core/ListSubheader';

import useApi from '../../../utils/useApi';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import StoreContext from '../../Store/context';
import CashierClosures from './Open/selectClosure';
import ClosuresTable from './Table/closuresTable';
import { formattedCurrency, formattedDateTime } from '../../../utils/utils';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
// import CashierLogisticTable from './Table/casherLogisticTable';
import CashierLogisticTable from '../Logistic/Table/casherLogisticTable';
import CashierLogisticBar from '../Logistic/Graph/cashierLogisticBar';
import CashierLogisticPie from '../Logistic/Graph/cashierLogisticPie';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import CashierLogisticBarUsers from '../Logistic/Graph/cashierLogisticBarUsers';
import CashierLogisticRadar from '../Logistic/Graph/cashierLogisticRadar';


const CashierClosuresView = () => {
  const { credencials } = useContext(StoreContext);
  const { cashSession } = credencials;

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // define os valores de outros no grafico
  const [othersValues, setOthersValues] = useState(null);

  // valores do caixa
  const [cashier, setCashier] = useState(null);

  const [closeCashier, setCloseCashier] = useState(false);
  const [alert, setAlert] = useState(false);

  // modais de pesquisa e filtragem
  const [tab, setTab] = useState('1');

  const [index, setIndex] = useState(0);

  const handleTab = (event, newValue) => {
    setTab(newValue);
    if (othersValues) setOthersValues(null);
  };

  const handleModalCashier = () => {
    setCloseCashier(!closeCashier);
  };

  // const changeValues = (name, value) => {
  //   if (Number(value) >= 0) {
  //     setModalValues({ ...modalValues, [name]: value, errors: null });
  //   } else {
  //     setModalValues({ ...modalValues, errors: 'Pagamento invalido' });
  //   }
  // };

  const [cashierMoveLoad, cashierMoveInfo] = useApi({
    method: 'get',
    params: { cashRegister: cashSession },
    url: '/cash-registers/closures',
  });

  const [cashierLoad, cashierInfo, setCashierInfo] = useApi({
    url: '/cash-registers',
  });

  const [paymentLoad, paymentInfo] = useApi({
    method: 'get',
    url: '/payments/methods',
  });

  const [userLoad, userInfo] = useApi({
    method: 'get',
    url: '/users',
  });

  useEffect(() => {
    userLoad();
    paymentLoad();

    if (credencials.cashSession) {
      cashierLoad({
        method: 'get',
        params: { id: cashSession },
        onCompleted: (res) => {
          if (res) {
            const data = res.data[0];
            setCashier(data);
          }
        },
      });
      cashierMoveLoad({
        method: 'get',
        params: { cashRegister: cashSession },
        onCompleted: (res) => {
          if (res) {
            setIndex(0);
          }
        },
      });
    } else {
      setCashier(null);
    }
  }, [credencials]);

  function openOtherValues(data, tabIndex) {
    setOthersValues(data);
    setTab(tabIndex);
  }

  function setNewIndex(_index) {
    setIndex(_index);
    if (closeCashier) handleModalCashier();
    setTab('1');
  }

  function toggleBackForOthers() {
    setOthersValues(!othersValues);
    setTab('4');
  }

  if (index === -1) return <CashierClosures />;
  if (cashSession && cashier && cashierMoveInfo.data) {
    return (
      <>
        <UIContainer container>
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            xs={12}
          >
            <UIFormTitle title="Fechamentos" />
          </Grid>
          <Typography
            style={{ fontSize: 18 }}
            className="cashier-logistic-open__text"
            onClick={handleModalCashier}
          >
            Caixa {cashier.name},{' '}
            {cashierMoveInfo.data &&
            cashierMoveInfo.data[index] &&
            index !== -1 ? (
              <span>
                Aberto: {formattedDateTime(cashierMoveInfo.data[index].opened)}
                Fechado: {formattedDateTime(cashierMoveInfo.data[index].closed)}
              </span>
            ) : (
              <span>O caixa {cashier.name} nunca foi fechado </span>
            )}
          </Typography>
        </UIContainer>
        <UIContainer style={{ height: '62.5%', overflow: 'auto' }}>
          <TabContext value={tab}>
            <Grid item xs>
              <Tabs
                value={tab}
                onChange={handleTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab value="1" label="Fechamentos" />
                <Tab value="2" label="Entradas" />
                <Tab value="3" label="Saídas" />
                <Tab value="4" label="Descontos" />
                <Tab value="5" label="Resumo" />
                <Tab value="6" label="Balanço" />
              </Tabs>
            </Grid>

            {cashierMoveInfo.data && cashierMoveInfo.data[index] && (
              <>
                <TabPanel value="1" index="1" style={{ padding: 0 }}>
                  <ClosuresTable
                    actualIndex={index}
                    doubleClickAction={setNewIndex}
                    mobile={mobile}
                    data={cashierMoveInfo.data}
                    loading={cashierMoveInfo.loading}
                    error={cashierMoveInfo.error}
                  />
                </TabPanel>
                <TabPanel value="2" index="2" style={{ padding: 0 }}>
                  <CashierLogisticTable
                    mobile={mobile}
                    data={
                      othersValues || cashierMoveInfo.data[index].entries.moves
                    }
                    loading={cashierMoveInfo.loading}
                    error={cashierMoveInfo.error}
                  />
                </TabPanel>
                <TabPanel value="3" index="3" style={{ padding: 0 }}>
                  <CashierLogisticTable
                    mobile={mobile}
                    data={
                      othersValues || cashierMoveInfo.data[index].outs.moves
                    }
                    loading={cashierMoveInfo.loading}
                    error={cashierMoveInfo.error}
                  />
                </TabPanel>
                <TabPanel value="4" index="4" style={{ padding: 0 }}>
                  <CashierLogisticTable
                    mobile={mobile}
                    data={
                      othersValues ||
                      cashierMoveInfo.data[index].discounts.moves
                    }
                    loading={cashierMoveInfo.loading}
                    error={cashierMoveInfo.error}
                  />
                </TabPanel>
                <TabPanel
                  value="5"
                  index="5"
                  style={{ padding: 0, height: '81%' }}
                >
                  <Grid container style={{ width: '100%', height: '100%' }}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justify="center"
                      style={{ height: '100%', marginBottom: '30px' }}
                    >
                      <CashierLogisticBarUsers
                        dataApi={cashierMoveInfo.data[index]}
                        color="#1B79DF"
                        caption="Movimentações por operador"
                        total={cashierMoveInfo.data[index].entries.total}
                        dataLabel={userInfo.data}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justify="center"
                      style={{ height: '100%', marginBottom: '10px' }}
                    >
                      <CashierLogisticRadar
                        moves={cashierMoveInfo.data[index].entries.moves}
                        caption="Entradas por método de pagamento"
                        dataLabel={paymentInfo.data}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel
                  value="6"
                  index="6"
                  style={{ padding: 0, height: '81%' }}
                >
                  <Grid container style={{ width: '100%', height: '100%' }}>
                    {!mobile ? (
                      <Grid
                        container
                        style={{
                          width: '50%',
                          height: '60%',
                          marginLeft: '50px',
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          md={4}
                          justify="center"
                          style={{ height: '100%' }}
                        >
                          <CashierLogisticPie
                            moves={cashierMoveInfo.data[index].entries.moves}
                            color="#1B79DF"
                            caption="Transações de entrada"
                            total={cashierMoveInfo.data[index].entries.total}
                            openOtherValues={openOtherValues}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          md={4}
                          justify="center"
                          style={{ height: '100%', marginBottom: '10px' }}
                        >
                          <CashierLogisticPie
                            moves={cashierMoveInfo.data[index].outs.moves}
                            color="#D60429"
                            caption="Transações de saida"
                            total={cashierMoveInfo.data[index].outs.total}
                            openOtherValues={openOtherValues}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          md={4}
                          justify="center"
                          style={{ height: '100%', marginBottom: '10px' }}
                        >
                          <CashierLogisticPie
                            moves={cashierMoveInfo.data[index].discounts.moves}
                            color="#f39708"
                            caption="Descontos Aplicados"
                            total={cashierMoveInfo.data[index].discounts.total}
                            openOtherValues={(data) => {
                              openOtherValues(data, '3');
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        height: !mobile ? '50%' : '90%',
                        marginBottom: '10px',
                        marginLeft: '50px',
                      }}
                    >
                      <CashierLogisticBar
                        entriesMoves={cashierMoveInfo.data[index].entries.total}
                        outsMoves={cashierMoveInfo.data[index].outs.total}
                        discounts={cashierMoveInfo.data[index].discounts.total}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </>
            )}
          </TabContext>
        </UIContainer>
        <UIContainer
          container
          direction="row"
          alignItems="center"
          style={{ minHeight: '15%' }}
        >
          <>
            <Grid
              item
              xs={12}
              md={5}
              className="form-container-sale__table-info-payment"
            >
              <Typography
                paragraph
                style={{
                  margin: 0,
                  color:
                    cashierMoveInfo.data &&
                    cashierMoveInfo.data[index] &&
                    cashierMoveInfo.data[index].total < 0
                      ? 'red'
                      : 'green',
                }}
              >
                BALANÇO:{' '}
                {cashierMoveInfo.data && cashierMoveInfo.data[index] && (
                  <span>
                    {formattedCurrency(cashierMoveInfo.data[index].total)}
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              className="form-container-sale__table-info-payment"
            >
              <Typography paragraph style={{ margin: 0 }}>
                TOTAL MOVIMENTADO:{' '}
                {cashierMoveInfo.data && cashierMoveInfo.data[index] && (
                  <span>
                    {formattedCurrency(
                      cashierMoveInfo.data[index].totalBustling,
                    )}
                  </span>
                )}
              </Typography>
            </Grid>
          </>
          <Grid xs={12} md={2} item container>
            <Grid item xs />
            <Button
              className="UIModal__button-search"
              variant="contained"
              type="submit"
              size="large"
              onClick={handleModalCashier}
            >
              Ver outro fechamento
            </Button>
          </Grid>
        </UIContainer>
        <Dialog
          open={closeCashier}
          fullWidth
          onClose={handleModalCashier}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <UIDialogTitle id="alert-dialog-title">
            Escolha o fechamento
          </UIDialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <List subheader={<li />}>
                  {cashierMoveInfo.data &&
                    cashierMoveInfo.data.map((closureId, _index) => (
                      <li key={`section-${closureId.opened}`}>
                        <ul>
                          <Button
                            onClick={() => setNewIndex(_index)}
                            style={
                              _index === index
                                ? { backgroundColor: '#d3d3d3' }
                                : { backgroundColor: '#fff' }
                            }
                            disabled={_index === index}
                          >
                            Aberto: {formattedDateTime(closureId.opened)}
                            Fechado: {formattedDateTime(closureId.closed)}
                          </Button>
                        </ul>
                      </li>
                    ))}
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalCashier} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <UIDialogConfirm
          open={alert}
          setrequestinfo={setCashierInfo}
          close={() => setAlert(false)}
          data={cashierInfo}
          messageSucess={cashierInfo.data && cashierInfo.data.Message}
        />
        {othersValues && (tab === '1' || tab === '2') && (
          <Tooltip title="Fechar 'Outros'">
            <Fab
              style={{
                backgroundColor: '#94031f',
                color: '#fff',
                right: '50%',
                left: '50%',
                bottom: '35px',
                position: 'relative',
              }}
              size="small"
              variant="extended"
              onClick={toggleBackForOthers}
            >
              Voltar
            </Fab>
          </Tooltip>
        )}
      </>
    );
  }
  return <CashierClosures />;
};

export default CashierClosuresView;
