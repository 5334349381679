import React, { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  Legend,
  Tooltip,
} from 'recharts';
import { formattedCurrency } from '../../../../utils/utils';

const CashierLogisticRadar = (children) => {
  const { moves, caption, dataLabel } = children;
  const [data, setData] = useState(null);

  useEffect(() => {
    const initialGraph = [
      { name: '1', value: 0 },
      { name: '2', value: 0 },
      { name: '3', value: 0 },
      { name: '4', value: 0 },
      { name: '5', value: 0 },
      { name: '6', value: 0 },
      { name: '7', value: 0 },
      { name: '8', value: 0 },
    ];
    const dataValues = moves.reduce((acc, values) => {
      const userExist = acc.find(
        (value) => value.name === values.reference.paymentMethod,
      );
      if (userExist) {
        userExist.value += Math.abs(values.value);
      } else {
        acc.push({
          name: `${values.reference.paymentMethod}`,
          value: Math.abs(values.value),
        });
      }
      return acc;
    }, initialGraph);

    if (dataValues && dataLabel) {
      dataValues.forEach((value, index) => {
        dataValues[index].name = dataLabel.find(
          (val) => val.id === value.name,
        ).name;
      });
      setData(dataValues);
    }
  }, []);
  return (
    <>
      <ResponsiveContainer height="100%">
        <RadarChart
          margin={{ top: 0, left: 0, right: 0, bottom: 30 }}
          cx="50%"
          cy="50%"
          outerRadius="80%"
          data={data}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <Legend verticalAlign="top" />
          <Radar
            dot
            name={caption}
            dataKey="value"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
          <Tooltip
            formatter={(value, name, payload) => [
              formattedCurrency(payload.value),
              'Valor: ',
            ]}
            cursor={{ stroke: 'blue', strokeWidth: 1 }}
          />
        </RadarChart>
      </ResponsiveContainer>
    </>
  );
};

export default CashierLogisticRadar;
