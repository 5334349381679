import React from 'react';
import CashierClosuresView from '../../../components/Cashier/Closures/cashierClosures';
import PageContent from '../../../components/Main/Content/mainContent';

const CaixaFechamentos = () => (
  <PageContent>
    <CashierClosuresView />
  </PageContent>
);

export default CaixaFechamentos;
