import React, { Children } from 'react';
import { Grid, Typography } from '@material-ui/core';

import './local.css';

const StickyNotes = (children) => {
  const { title, content, href, actionText } = children;
  return (
    <Grid item xs={12} className="sticky-notes__card">
      <Grid item xs={12}>
        <Typography className="home-page__card-title" style={{ float: 'left' }}>
          {title}
        </Typography>
      </Grid>
      <Typography className="home-page__card-body">{content}</Typography>
      <Typography variant="button" className="home-page__card-goTo">
        <a href={href} target="_blank">
          {actionText}
        </a>
      </Typography>
    </Grid>
  );
};

export default StickyNotes;
