import React from 'react';
import PageContent from '../../../components/Main/Content/mainContent';
import OverviewShow from '../../../components/Overview/Show/overviewShow';

const VisaoGeral = () => (
  <PageContent>
    <OverviewShow />
  </PageContent>
);

export default VisaoGeral;
