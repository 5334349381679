import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/pt-BR';
import Root from './pages/root';

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
      <CssBaseline />
      <Root />
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
