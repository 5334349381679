import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { format, isValid } from 'date-fns';

import { emptyObject, formattedDateTimeIso } from '../../../utils/utils';
import useApi from '../../../utils/useApi';
import StoreContext from '../../Store/context';

import UIButtonSearch from '../../UI/Button/Search/Search';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import GreenSwitch from '../../UI/Form/Switch/GreenSwitch';

const initialState = {
  id: '',
  name: '',
  description: '',
  customerProfile: null,
  discount: 0,
  interestFree: '',
  isActive: false,
  percentage: 0,
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const PromotionSearch = () => {
  const { credencials } = useContext(StoreContext);

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // modal ativar/desativar promoção
  const [modalActive, setModalActive] = useState(false);

  // mensagem de confirmação para operações
  const [alert, setAlert] = useState(false);
  const [alertActive, setAlertActive] = useState(false);

  // modais de pesquisa e filtragem
  const [view, setView] = useState(false);
  const [filter, setFilter] = useState(false);

  // salvar valores para modalde visualizar/alterar
  const [modalValues, setModalValues] = useState({});

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState({ isActive: 1 });
  // verificar se é ativo ou nao na "chavinha"
  const [isActive, setIsActive] = useState(false);

  // provider do click do lado direito
  const [clickRight, setClickRight] = useState(initialClick);
  const handleClickRight = (event, values) => {
    event.preventDefault();
    setModalValues({
      ...values,
      isOpen: Number(values.isOpen) === 1,
      value: values.currentValue,
      isActive: Number(values.isActive) === 1,
      endDate: isValid(formattedDateTimeIso(values.endDate))
        ? formattedDateTimeIso(values.endDate)
        : null,
      startDate: formattedDateTimeIso(values.startDate),
    });
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickLeft = (event, values) => {
    setModalValues({
      ...values,
      isOpen: Number(values.isOpen) === 1,
      value: values.currentValue,
      isActive: Number(values.isActive) === 1,
      endDate: isValid(formattedDateTimeIso(values.endDate))
        ? formattedDateTimeIso(values.endDate)
        : null,
      startDate: formattedDateTimeIso(values.startDate),
    });
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/promotions',
    headers: {
      sessionToken: credencials.sessionToken,
      company: credencials.company,
    },
  });

  const [activeLoad, activeInfo, setActiveInfo] = useApi({
    method: 'patch',
    url: '/promotions',
    headers: {
      sessionToken: credencials.sessionToken,
      company: credencials.company,
    },
    onCompleted: (response) => {
      if (response) {
        filterLoad({
          params: search,
        });
      }
    },
  });

  const [updateLoad, updateInfo, setUpdateInfo] = useApi({
    method: 'put',
    url: '/promotions',
    headers: {
      sessionToken: credencials.sessionToken,
      company: credencials.company,
    },
    onCompleted: (response) => {
      if (response) {
        filterLoad({
          params: search,
        });
      }
    },
  });

  const [clientLoad, clientInfo] = useApi({
    method: 'get',
    url: '/customers/profiles',
    headers: {
      sessionToken: credencials.sessionToken,
      company: credencials.company,
    },
  });

  useEffect(() => {
    clientLoad();
    filterLoad({
      params: { isActive: 1 },
    });
  }, []);

  function handleModalFilter() {
    setFilter(!filter);
  }

  function handleModalUpdate(values, isClick) {
    if (values) {
      setModalValues(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function handleModalAcive(values, isClick) {
    if (values) {
      setModalValues({
        id: values.id,
        isActive: values.isOpen,
        name: values.name,
      });
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setModalActive(true);
  }

  function closeAlert(event, reason) {
    setUpdateInfo();
    setActiveInfo();
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  }

  function onSubmit(values) {
    const searchValues = {
      ...values,
      isActive: (values.showCloseAll && '0') || (!isActive && 1),
      showCloseAll: '',
    };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
    });
    setSearch(emptyObject(searchValues));
  }

  function onInactive(actived) {
    const searchValues = {
      ...search,
      isActive: !actived && 1,
    };
    filterLoad({
      params: emptyObject(searchValues),
    });
    setIsActive(actived);
    setSearch(emptyObject(searchValues));
  }

  function onUpdate(values) {
    updateLoad({
      data: values,
    });
    setView(false);
    setAlert(true);
  }

  function onActive() {
    const data = {
      id: modalValues.id,
    };
    activeLoad({
      data,
    });
    setModalActive(false);
    setAlertActive(true);
  }

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialState}>
        {({ values, submitForm, setFieldValue }) => (
          <Form onChange={submitForm}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Filtrar promoções" />
                <UIFilter onClick={handleModalFilter} />
              </Grid>
              <Hidden smDown>
                <Grid item md={1}>
                  <Field
                    name="id"
                    label="ID"
                    placeholder="ID"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>

              <Grid item xs={6} md={3}>
                <Field
                  name="name"
                  label="Nome"
                  placeholder="Nome da promoção"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden smDown>
                <Grid item xs={6} md={4}>
                  <Field
                    name="description"
                    label="Descrição"
                    placeholder="Descrição da promoção"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>

              <Grid item xs={6} md={3}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Ver promoções</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      label="Sem juros"
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={values.isOpen}
                          onChange={(ev) =>
                            setFieldValue('isOpen', ev.target.checked)
                          }
                        />
                      }
                    />
                    <Hidden smDown>
                      <FormControlLabel
                        label="Inativas"
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={values.userId}
                            onChange={(ev) =>
                              setFieldValue('userId', ev.target.checked)
                            }
                          />
                        }
                      />
                    </Hidden>
                  </FormGroup>
                </FormControl>
              </Grid>

              <Hidden smDown>
                <UIButtonSearch xs={1} />
              </Hidden>
            </UIContainer>

            <UIModalFilter
              open={filter}
              changeModal={handleModalFilter}
              submit={submitForm}
              fullWidth
            >
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Field
                    name="id"
                    label="ID"
                    placeholder="ID"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12}>
                <Field
                  name="percentage"
                  label="Porcentagem"
                  placeholder="Informe a porcentagem do desconto"
                  type="number"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Field
                  name="percentage"
                  label="Exemplo tipo de cliente"
                  fullWidth
                  as={TextField}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Field
                  name="products"
                  label="Produtos"
                  placeholder="Informe o nome do produto"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="services"
                  label="Serviços"
                  placeholder="Informe o nome do serviço"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <FormControl component="fieldset">
                <FormLabel component="legend">Ver promoções</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    label="Somente inativas"
                    labelPlacement="start"
                    control={
                      <Checkbox
                        checked={values.showCloseAll}
                        onChange={(ev) =>
                          setFieldValue('showCloseAll', ev.target.checked)
                        }
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </UIModalFilter>
          </Form>
        )}
      </Formik>
      <UIContainer>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          xs={12}
        >
          <UIFormTitle title="Lista de promoções" />
          <FormControlLabel
            label={
              isActive
                ? 'Esconder promoções inativas'
                : 'Mostrar promoções inativas'
            }
            labelPlacement="start"
            control={
              <GreenSwitch
                checked={isActive}
                onChange={(ev) => onInactive(ev.target.checked)}
              />
            }
          />
        </Grid>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Desconto %</TableCell>
                  <TableCell>Desconto R$</TableCell>
                  <TableCell>Remove Juros</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterInfo.loading && <CircularProgress />}
                {filterInfo.data &&
                  filterInfo.data.map((data) => (
                    <TableRow
                      hover
                      onDoubleClick={() => handleModalUpdate(data)}
                      onContextMenu={
                        !mobile ? (ev) => handleClickRight(ev, data) : undefined
                      }
                      style={{
                        cursor: 'context-menu',
                      }}
                      onClick={
                        mobile ? (ev) => handleClickLeft(ev, data) : undefined
                      }
                      key={data.id}
                    >
                      <TableCell component="th" scope="row">
                        {data.id}
                      </TableCell>
                      <TableCell>{data.name}</TableCell>
                      <TableCell>{data.description}</TableCell>
                      <TableCell>
                        {data.percentage !== '0'
                          ? `${data.percentage}%`
                          : 'Nenhum'}
                      </TableCell>
                      <TableCell>
                        {data.discount !== '0.0'
                          ? `R$ ${data.discount}`
                          : 'Nenhum'}
                      </TableCell>
                      <TableCell>
                        {data.interestFree === '1' ? 'Sim' : 'Não'}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          title="Mais detalhes"
                          onClick={() => handleModalUpdate(data)}
                        >
                          <SearchIcon />
                        </IconButton>
                        <Switch
                          title="Ativar/desativar promoção"
                          onClick={() => handleModalAcive(data)}
                          color="primary"
                          checked={Number(data.isActive) === 1}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                <Menu
                  keepMounted
                  open={clickRight.mouseY !== null}
                  onClose={handleCloseClickRight}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    clickRight.mouseY !== null && clickRight.mouseX !== null
                      ? {
                          top: clickRight.mouseY,
                          left: clickRight.mouseX,
                        }
                      : undefined
                  }
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  {Number(modalValues.isActive) === 1 && (
                    <MenuItem onClick={() => handleModalAcive(null, true)}>
                      Ativar/desativar promoção
                    </MenuItem>
                  )}
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseClickLeft()}
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  {Number(modalValues.isActive) === 1 && (
                    <MenuItem onClick={() => handleModalAcive(null, true)}>
                      Ativar/desativar promoção
                    </MenuItem>
                  )}
                </Menu>
                {filterInfo.error && (
                  <TableRow>
                    <TableCell>Sem resultados encotrados</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </UIContainer>
      {modalValues && (
        <Dialog
          open={view}
          onClose={() => handleModalUpdate()}
          aria-labelledby="form-dialog-title"
        >
          <Formik
            enableReinitialize
            onSubmit={onUpdate}
            initialValues={modalValues}
          >
            {({ values, touched, errors, submitForm, setFieldValue }) => (
              <Form>
                <UIDialogTitle
                  id="form-dialog-title"
                  onClose={() => handleModalUpdate()}
                >
                  {modalValues.name}
                </UIDialogTitle>
                <DialogContent>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={8}>
                      <Field
                        name="name"
                        label="Nome"
                        placeholder="Nome da promoção"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        label={
                          values.isActive
                            ? 'Promoção ativa'
                            : 'Promoção inativa'
                        }
                        labelPlacement="top"
                        control={<GreenSwitch checked={values.isActive} />}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        name="description"
                        label="Descrição"
                        placeholder="Descrição da promoção"
                        fullWidth
                        disabled={!values.isActive}
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        name="percentage"
                        label="Desconto em %"
                        error={Boolean(errors.percentage)}
                        helperText={touched.percentage && errors.percentage}
                        placeholder="Informe a porcentagem"
                        type="number"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        name="discount"
                        label="Desconto em R$"
                        error={Boolean(errors.percentage)}
                        helperText={touched.percentage && errors.percentage}
                        placeholder="Informe o valor fixo do desconto"
                        type="number"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel
                          shrink
                          id="promotion-search-customerProfile"
                        >
                          Perfil do cliente
                        </InputLabel>
                        <Field
                          name="customerProfile"
                          disabled={clientInfo.loading}
                          error={Boolean(errors.customerProfile)}
                          helperText={
                            touched.customerProfile && errors.customerProfile
                          }
                          labelId="promotion-search-customerProfile"
                          displayEmpty
                          fullWidth
                          as={Select}
                        >
                          <MenuItem disabled value="">
                            <em>Selecione o perfil do cliente</em>
                          </MenuItem>
                          {clientInfo.data !== null &&
                            clientInfo.data.map((client) => (
                              <MenuItem key={client.id} value={client.id}>
                                {client.name}
                              </MenuItem>
                            ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {JSON.stringify(values) !== JSON.stringify(modalValues) && (
                    <Button onClick={() => handleModalUpdate()} color="primary">
                      Cancelar
                    </Button>
                  )}
                  <Button
                    onClick={
                      JSON.stringify(values) === JSON.stringify(modalValues)
                        ? () => handleModalUpdate()
                        : () => submitForm()
                    }
                    color="primary"
                  >
                    {JSON.stringify(values) === JSON.stringify(modalValues)
                      ? 'Fechar'
                      : 'Salvar'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}

      <Dialog
        open={modalActive}
        onClose={() => setModalActive(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <UIDialogTitle id="alert-dialog-title">
          Deseja {modalValues.isActive === 1 ? 'ativar' : 'desativar'} a
          promoção {modalValues.name}?
        </UIDialogTitle>
        <DialogActions>
          <Button onClick={() => setModalActive(false)} color="primary">
            Não
          </Button>
          <Button onClick={onActive} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <UIDialogConfirm
        open={alertActive}
        close={() => setAlertActive(false)}
        data={activeInfo}
        setrequestinfo={closeAlert}
        messageSucess={`Promoção ${
          modalValues.isActive === 1 ? 'desativada' : 'ativada'
        } com sucesso`}
        messageError="Ops, aconteceu algum problema, tente mais tarde"
        type="success"
      />
      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={updateInfo}
        setrequestinfo={closeAlert}
        messageSucess="Promo atualizada com sucesso"
        messageError="Ops, aconteceu algum problema, tente mais tarde"
        type="success"
      />
    </>
  );
};

export default PromotionSearch;
