import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import SearchIcon from '@material-ui/icons/Search';
import CallMadeIcon from '@material-ui/icons/CallMade';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useTheme } from '@material-ui/core/styles';

import { format, isValid } from 'date-fns';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import {
  emptyObject,
  formattedDateTime,
  formattedCurrency,
  formattedDateTimeIso,
  isEqualObject,
} from '../../../utils/utils';
import useApi from '../../../utils/useApi';
import StoreContext from '../../Store/context';
import UIButtonSearch from '../../UI/Button/Search/Search';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import FormInputDate from '../../UI/Form/InputDate/FormInputDate';
import GreenSwitch from '../../UI/Form/Switch/GreenSwitch';
import FormInputDateTime from '../../UI/Form/InputDateTime/FormInputDateTime';
import useStyles from '../../../utils/useStyle';
import UITablePagination from '../../UI/Table/Pagination/UITablePagination';

import './cashierSearch.css';

const initialState = {
  id: '',
  name: '',
  startDate: null,
  dueDate: null,
  initialValue: 0.0,
  currentValue: 0,
  isOpen: false,
  isActive: false,
  userId: '',
  showCloseAll: false,
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const CashierSearch = () => {
  const { credencials, setCredencials, setModalCashierOpen } =
    useContext(StoreContext);
  const history = useHistory();
  const { disabledTextField } = useStyles();

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // modal para encerrar vigencia e abrir/desativar caixa
  const [closeCashier, setCloseCashier] = useState(false);

  // mensagem de confirmação para operações
  const [alert, setAlert] = useState(false);

  // modais de pesquisa e filtragem
  const [view, setView] = useState(false);
  const [filter, setFilter] = useState(false);

  // salvar valores para modalde visualizar/alterar
  const [modalValues, setModalValues] = useState({});

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState({ isActive: 1 });
  // verificar se é ativo ou nao na "chavinha"
  const [isActive, setIsActive] = useState(false);

  // paginação
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const humanAttributes = (values) => {
    const valuesTreated = {
      ...values,
      isOpen: Number(values.isOpen) === 1,
      isActive: Number(values.isActive) === 1,
      value: values.currentValue,
      dueDate: isValid(formattedDateTimeIso(values.dueDate))
        ? formattedDateTimeIso(values.dueDate)
        : null,
      startDate: formattedDateTimeIso(values.startDate),
    };
    setModalValues(valuesTreated);
  };

  // provider do click do lado direito
  const [clickRight, setClickRight] = useState(initialClick);
  const handleClickRight = (event, values) => {
    event.preventDefault();
    humanAttributes(values);
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickLeft = (event, values) => {
    humanAttributes(values);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/cash-registers',
  });

  const [terminatesLoad, terminatesInfo, setTerminatesInfo] = useApi({
    method: 'delete',
    url: '/cash-registers',
    onCompleted: (response) => {
      if (response) {
        filterLoad({
          params: search,
        });
      }
    },
  });

  const [updateLoad, updateInfo, setUpdateInfo] = useApi({
    method: 'put',
    url: '/cash-registers',
    onCompleted: (response) => {
      if (response) {
        filterLoad({
          params: search,
        });
      }
    },
  });

  const [usersLoad, usersInfo] = useApi({
    method: 'get',
    params: { isActive: '1' },
    url: '/users',
  });

  useEffect(() => {
    usersLoad();
    filterLoad({
      params: { isActive: 1 },
    });
  }, []);

  function handleModalFilter() {
    setFilter(!filter);
  }

  function handleModalUpdate(values, isClick) {
    if (values) {
      humanAttributes(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function handleModalTerminates(values, isClick) {
    if (values) {
      setModalValues(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setCloseCashier(!closeCashier);
  }

  function closeAlert(event, reason) {
    setUpdateInfo();
    setTerminatesInfo();
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  }

  function onSubmit(values) {
    const searchValues = {
      ...values,
      isOpen: values.isOpen && 1,
      isActive: (values.showCloseAll && '0') || (!isActive && 1),
      dueDate: isValid(values.dueDate)
        ? format(new Date(values.dueDate), 'yyyy-MM-dd')
        : '',
      startDate: isValid(values.startDate)
        ? format(new Date(values.startDate), 'yyyy-MM-dd')
        : '',
      userId: values.userId && Number(credencials.id),
      showCloseAll: '',
    };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
    });
    setSearch(emptyObject(searchValues));
  }

  function onInactive(actived) {
    const searchValues = {
      ...search,
      isActive: !actived && 1,
    };
    filterLoad({
      params: emptyObject(searchValues),
    });
    setIsActive(actived);
    setSearch(emptyObject(searchValues));
  }

  function onUpdate(values) {
    const updateValues = {
      id: Number(values.id),
      usersId: values.usersId && values.usersId.map((user) => user.id),
      name: values.name,
      dueDate: isValid(values.dueDate)
        ? format(new Date(values.dueDate), 'yyyy-MM-dd HH:mm:ss')
        : '',
    };
    updateLoad({
      data: emptyObject(updateValues),
    });
    setView(false);
    setAlert(true);
  }

  function onRedirect(cashierId) {
    credencials.cashSession = cashierId;
    setCredencials(credencials);
    history.push('/home/caixas/detalhes');
  }

  function onTerminates() {
    const data = {
      id: Number(modalValues.id),
    };
    terminatesLoad({
      data,
      onCompleted: (res) => {
        setAlert(true);
        if (res) {
          setCloseCashier(false);
        }
      },
    });
  }

  function hadleModalCashierOpen(cashierId, isOpen, open) {
    if (credencials.cashSession === cashierId) {
      onRedirect(cashierId);
    } else {
      setModalCashierOpen({ open, cashierId, isOpen: isOpen === 1 });
    }
  }

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialState}>
        {({ values, submitForm, setFieldValue }) => (
          <Form onChange={() => submitForm()}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Filtrar caixas" />
                <UIFilter onClick={handleModalFilter} />
              </Grid>
              <Hidden smDown>
                <Grid item md={1}>
                  <Field
                    name="id"
                    label="ID"
                    placeholder="ID"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>

              <Grid item xs={6} md={3}>
                <Field
                  name="name"
                  label="Nome"
                  placeholder="Nome do caixa"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden smDown>
                <Grid item md={2}>
                  <FormInputDate
                    name="startDate"
                    clearable
                    value={values.startDate}
                    label="Inicio vigência"
                    onChange={(value) => setFieldValue('startDate', value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormInputDate
                    name="dueDate"
                    dateFormat="MM-DD-YYYY"
                    value={values.dueDate}
                    onChange={(value) => setFieldValue('dueDate', value, false)}
                    minDate={values.startDate}
                    clearable
                    label="Termino vigência"
                  />
                </Grid>
              </Hidden>

              <Grid item xs={6} md={3}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Ver caixas</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      label="Abertos"
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={values.isOpen}
                          onChange={(ev) =>
                            setFieldValue('isOpen', ev.target.checked)
                          }
                        />
                      }
                    />
                    <Hidden smDown>
                      <FormControlLabel
                        label="Meus caixas"
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={values.userId}
                            onChange={(ev) =>
                              setFieldValue('userId', ev.target.checked)
                            }
                          />
                        }
                      />
                    </Hidden>
                  </FormGroup>
                </FormControl>
              </Grid>

              <Hidden smDown>
                <UIButtonSearch xs={1} />
              </Hidden>
            </UIContainer>

            <UIModalFilter
              open={filter}
              changeModal={handleModalFilter}
              submit={submitForm}
            >
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Field
                    name="id"
                    label="ID"
                    placeholder="ID"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} md={12}>
                <CurrencyTextField
                  type="tel"
                  value={values.initialValue}
                  label="Valor inicial do caixa"
                  variant="standard"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  fullWidth
                  onChange={(ev, value) => setFieldValue('initialValue', value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CurrencyTextField
                  type="tel"
                  value={values.currentValue}
                  label="Valor atual do caixa"
                  variant="standard"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  fullWidth
                  onChange={(ev, value) => setFieldValue('currentValue', value)}
                />
              </Grid>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <FormInputDate
                    name="startDate"
                    value={values.startDate}
                    label="Inicio vigência"
                    onChange={(value) =>
                      setFieldValue('startDate', value, true)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputDate
                    name="dueDate"
                    value={values.dueDate}
                    onChange={(value) => setFieldValue('dueDate', value, false)}
                    minDate={values.startDate}
                    clearable
                    fullWidth
                    label="Termino vigência"
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Ver caixas</FormLabel>
                  <FormGroup row>
                    <Hidden mdUp>
                      <FormControlLabel
                        label="Meus caixas"
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={values.userId}
                            onChange={(ev) =>
                              setFieldValue('userId', ev.target.checked)
                            }
                          />
                        }
                      />
                    </Hidden>
                    <FormControlLabel
                      label="Somente inativos"
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={values.showCloseAll}
                          onChange={(ev) =>
                            setFieldValue('showCloseAll', ev.target.checked)
                          }
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </UIModalFilter>
          </Form>
        )}
      </Formik>
      <UIContainer>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          xs={12}
        >
          <UIFormTitle title="Lista de caixas" />
          <FormControlLabel
            label={
              isActive ? 'Esconder caixas inativos' : 'Mostrar caixas inativos'
            }
            labelPlacement="start"
            control={
              <GreenSwitch
                checked={isActive}
                onChange={(ev) => onInactive(ev.target.checked)}
              />
            }
          />
        </Grid>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Início de vigência</TableCell>
                  <TableCell>Término de vigência</TableCell>
                  <TableCell>Valor do caixa</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterInfo.loading && <CircularProgress />}
                {filterInfo.data &&
                  filterInfo.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data) => (
                      <TableRow
                        className="cashier-search__table-row"
                        onDoubleClick={() => handleModalUpdate(data)}
                        onContextMenu={
                          !mobile
                            ? (ev) => handleClickRight(ev, data)
                            : undefined
                        }
                        style={{
                          cursor: 'context-menu',
                          backgroundColor:
                            (!Number(data.isActive) && '#EAEDED') ||
                            (!!Number(data.isActive) &&
                              Number(data.isOpen) === 1 &&
                              '#C6FFD1'),
                        }}
                        onClick={
                          mobile ? (ev) => handleClickLeft(ev, data) : undefined
                        }
                        key={data.id}
                      >
                        <TableCell component="th" scope="row">
                          {data.id}
                        </TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>
                          {formattedDateTime(data.startDate)}
                        </TableCell>
                        <TableCell>
                          {formattedDateTime(data.dueDate) || 'Indefinido'}
                        </TableCell>
                        <TableCell>
                          {formattedCurrency(data.currentValue)}
                        </TableCell>
                        <TableCell>
                          {(Number(data.isOpen) === 0 &&
                            Number(data.isActive) === 1 &&
                            'Fechado') ||
                            (Number(data.isOpen) === 1 && 'Aberto') ||
                            (Number(data.isOpen) === 0 && 'Encerrado')}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            title="Mais detalhes"
                            onClick={() => handleModalUpdate(data)}
                          >
                            <SearchIcon />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              hadleModalCashierOpen(data.id, data.isOpen, true)
                            }
                            title={
                              data.isOpen === '1'
                                ? 'Ir para fechamento de caixa'
                                : 'Ir para abertura de caixa'
                            }
                            disabled={!Number(data.isActive)}
                          >
                            <CallMadeIcon />
                          </IconButton>
                          <IconButton
                            title="Encerrar vigência"
                            disabled={!Number(data.isActive)}
                            onClick={() => handleModalTerminates(data)}
                          >
                            <PowerSettingsNewIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                <Menu
                  keepMounted
                  open={clickRight.mouseY !== null}
                  onClose={handleCloseClickRight}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    clickRight.mouseY !== null && clickRight.mouseX !== null
                      ? {
                          top: clickRight.mouseY,
                          left: clickRight.mouseX,
                        }
                      : undefined
                  }
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  {Number(modalValues.isActive) === 1 && (
                    <MenuItem onClick={() => handleModalTerminates(null, true)}>
                      Encerrar vigência
                    </MenuItem>
                  )}
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseClickLeft()}
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  {Number(modalValues.isActive) === 1 && (
                    <MenuItem onClick={() => handleModalTerminates(null, true)}>
                      Encerrar vigência
                    </MenuItem>
                  )}
                </Menu>
                {filterInfo.error && (
                  <TableRow>
                    <TableCell>Sem resultados encotrados</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <UITablePagination
              count={filterInfo.data ? filterInfo.data.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </UIContainer>
      {modalValues && (
        <Dialog
          open={view}
          onClose={() => handleModalUpdate()}
          aria-labelledby="form-dialog-title"
        >
          <Formik
            enableReinitialize
            onSubmit={onUpdate}
            initialValues={modalValues}
          >
            {({
              values,
              touched,
              setFieldTouched,
              errors,
              setFieldValue,
              submitForm,
            }) => (
              <Form>
                <UIDialogTitle
                  id="form-dialog-title"
                  onClose={() => handleModalUpdate()}
                >
                  {modalValues.name}
                </UIDialogTitle>
                <DialogContent>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={8}>
                      <Field
                        name="name"
                        label="Nome do caixa"
                        placeholder="Informe o nome do caixa"
                        fullWidth
                        disabled={!values.isActive}
                        className={!values.isActive && disabledTextField}
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        label={values.isOpen ? 'Caixa aberto' : 'Caixa fechado'}
                        labelPlacement="top"
                        control={<GreenSwitch checked={values.isOpen} />}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CurrencyTextField
                        label="Valor inicial do caixa"
                        value={values.initialValue}
                        error={Boolean(errors.initialValue)}
                        helperText={errors.initialValue}
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        className={disabledTextField}
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CurrencyTextField
                        type="tel"
                        value={values.currentValue}
                        label="Valor atual do caixa"
                        decimalCharacter=","
                        error={Boolean(errors.currentValue)}
                        helperText={errors.currentValue}
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        fullWidth
                        className={disabledTextField}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        value="top"
                        control={
                          <IconButton
                            disabled={!values.isActive}
                            onClick={() => handleModalTerminates(values)}
                          >
                            <PowerSettingsNewIcon size="small" />
                          </IconButton>
                        }
                        label="Encerrar vigência"
                        labelPlacement="top"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormInputDateTime
                        name="startDate"
                        value={values.startDate}
                        helperText={errors.startDate}
                        error={Boolean(errors.startDate)}
                        disabled
                        className={disabledTextField}
                        label="Inicio vigência"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormInputDateTime
                        name="dueDate"
                        disabled={!values.isActive}
                        className={!values.isActive && disabledTextField}
                        value={values.dueDate}
                        minDate={values.startDate}
                        label="Termino vigência"
                        onChange={(value) => setFieldValue('dueDate', value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        limitTags={4}
                        noOptionsText="Sem opções"
                        options={usersInfo.data !== null ? usersInfo.data : []}
                        getOptionLabel={(user) =>
                          `${user.username} - ${user.name}`
                        }
                        onBlur={() => setFieldTouched('usersId', true)}
                        onChange={(event, users) => {
                          setFieldValue('usersId', users);
                        }}
                        renderInput={(params) => (
                          <Field
                            {...params}
                            className="cashier-search__helperText"
                            name="usersId"
                            helperText={
                              touched.usersId &&
                              'Atenção: para retirar acesso de algum usuário ao caixa, vá para a aba Usuários e altere os caixas disponíveis para uso, diretamente no cadastro do mesmo.'
                            }
                            error={!touched}
                            variant="standard"
                            label="Adicionar operadores ao caixa"
                            placeholder="Adicionar mais"
                            component={TextField}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {!isEqualObject(values, modalValues) && (
                    <Button onClick={() => handleModalUpdate()} color="primary">
                      Cancelar
                    </Button>
                  )}
                  <Button
                    onClick={
                      isEqualObject(values, modalValues)
                        ? () => handleModalUpdate()
                        : () => submitForm()
                    }
                    color="primary"
                  >
                    {isEqualObject(values, modalValues) ? 'Fechar' : 'Salvar'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}

      <Dialog
        open={closeCashier}
        onClose={() => setCloseCashier(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <UIDialogTitle id="alert-dialog-title">
          Deseja encerrar vigencia do caixa {modalValues.name}
        </UIDialogTitle>
        <DialogActions>
          <Button onClick={() => setCloseCashier(false)} color="primary">
            Não
          </Button>
          <Button onClick={() => onTerminates()} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={terminatesInfo}
        setrequestinfo={closeAlert}
        messageSucess="Vigencia de caixa encerrada com sucesso"
        messageError="Não é possivel encerrar um caixa já encerrado"
        type="success"
      />
      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={updateInfo}
        setrequestinfo={closeAlert}
        messageSucess="Caixa atualizado com sucesso"
        messageError="Falha em atualizar o caixa"
        type="success"
      />
    </>
  );
};

export default CashierSearch;
