import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import UIContainer from '../../../UI/Container/UIContainer';
import './cashierLogisticOpen.css';

import StoreContext from '../../../Store/context';

const CashierLogisticOpen = () => {
  const { setModalCashierSelect, modalCashierSelect } =
    useContext(StoreContext);
  return (
    <UIContainer container>
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        className="cashier-logistic-open"
      >
        <Typography
          className="cashier-logistic-open__text"
          onClick={() => setModalCashierSelect(!modalCashierSelect)}
          style={{ fontSize: 20 }}
        >
          Nenhum caixa encontrado. <span>Selecionar caixa</span>
        </Typography>
      </Grid>
    </UIContainer>
  );
};

export default CashierLogisticOpen;
