import React, { useEffect, useState } from 'react';
import useApi from '../../../../utils/useApi';
import NewSaleSession from '../New/saleSessionNew';

const SaleSessionShow = () => {
  // verificar se é mobile e constantes para versão mobile
  const [collapseNew, setCollapseNew] = useState(true);
  const [collapseSearch, setCollapseSearch] = useState(false);

  function changeCollapseNew() {
    setCollapseNew(!collapseNew);
    setCollapseSearch(!collapseSearch);
  }

  return (
    <>
      <NewSaleSession
        collapseNew={collapseNew}
        changeCollapseNew={changeCollapseNew}
      />
    </>
  );
};

export default SaleSessionShow;
