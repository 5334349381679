import React from 'react';
import HomePage from '../../components/Home/homePage';
import PageContent from '../../components/Main/Content/mainContent';

const PaginaInicial = () => (
  <PageContent>
    <HomePage />
  </PageContent>
);

export default PaginaInicial;
