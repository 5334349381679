import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import './UIModalFilter.css';
import UIDialogTitle from '../Dialog/Title/UIDialogTitle';

const UIModalFilter = ({ ...children }) => {
  function submitForm() {
    children.submit();
    children.changeModal();
  }
  return (
    <Dialog open={children.open} onClose={children.changeModal}>
      <UIDialogTitle
        id="form-dialog-title"
        onClose={() => children.changeModal()}
      >
        Outros filtros
      </UIDialogTitle>
      <DialogContent>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          className="UIModalFilter__content"
          alignItems="center"
          xs={12}
        >
          {children.children}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className="UIModal__button-search" onClick={submitForm}>
          Procurar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UIModalFilter;
