import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { tint } from 'polished';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';

import './cashierLogisticPie.css';
import { formattedCurrency } from '../../../utils/utils';

const CashierLogisticPie = (children) => {
  const { color, moves, caption, total, openOtherValues } = children;
  const baseColor = color;
  const [colors, setColors] = useState([]);
  const [data, setData] = useState(null);
  const [others, setOthers] = useState(null);

  useEffect(() => {
    let dataValues = [];
    const otherValues = [];

    const maxValue = moves.reduce(
      (acc, item) => Math.max(acc, Math.abs(item.value)),
      0,
    );

    const minValue = maxValue * 0.1; // pega 10% do valor maximo para deixar como outros
    let sumMinValues = 0;

    dataValues = moves.reduce((acc, values) => {
      if (Math.abs(values.value) < minValue) {
        otherValues.push(values);
        sumMinValues += Math.abs(values.value);
      } else {
        acc.push({
          ...values,
          name: `Movimentação ${values.id}`,
          value: Math.abs(values.value),
        });
      }
      return acc;
    }, []);

    if (otherValues.length > 1) {
      dataValues.push({
        name: 'Outros',
        value: Number(sumMinValues.toFixed(2)),
      });
    }
    if (otherValues.length === 1) {
      const firstData = {
        name: `Movimentação ${otherValues[0].id}`,
        value: Math.abs(otherValues[0].value),
      };
      dataValues.push(firstData);
    }

    dataValues.forEach((element, index) => {
      colors.push(tint(index * 0.03, baseColor));
    });

    setColors(colors);
    dataValues.sort((a, b) => b.value - a.value);
    setOthers(otherValues);
    setData(dataValues);
  }, []);

  return (
    <>
      <ResponsiveContainer height="100%">
        <PieChart>
          <Pie
            height={400}
            dataKey="value"
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill={color}
            label
          >
            {data &&
              data.map((entry, index) => (
                <Cell
                  key={entry.name}
                  onClick={() => {
                    const tabIndex = caption.includes('entrada') ? '1' : '2';
                    if (entry.name === 'Outros') {
                      openOtherValues(others, tabIndex);
                    } else openOtherValues([entry], tabIndex);
                  }}
                  style={{ cursor: 'pointer' }}
                  fill={colors[index]}
                />
              ))}
          </Pie>
          <Tooltip formatter={(value) => formattedCurrency(value)} />
        </PieChart>
      </ResponsiveContainer>
      <Typography variant="body2">{`${caption}: R$ ${total}`}</Typography>
    </>
  );
};

export default CashierLogisticPie;
