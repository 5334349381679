// exclui os atributos do objeto que são nulos/vazios/undefined
function emptyObject(obj) {
  return Object.keys(obj)
    .filter((key) => obj[key])
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

// atribui string vazia para todos os atributos do objeto
const clearObject = (object, isObject = false) => {
  const obj = isObject ? object : JSON.parse(object);
  const objLimpos = Object.keys(obj).reduce((accumulator, currentValue) => {
    accumulator[currentValue] = '';
    return accumulator;
  }, {});
  return objLimpos;
};

// formata a string para que fique com a primeira letra maiuscula
const capitalize = (str) => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.substr(1);
};

const formattedDateTime = (dateTime) => {
  if (!dateTime) return '';
  const dataRetornada = dateTime;
  const dataCortada = dataRetornada.split('-');
  const horaCortada = dataCortada[2].split(' ');
  return `${dataCortada[0]}/${dataCortada[1]}/${horaCortada[0]} às ${horaCortada[1]} `;
};

const formattedDateTimeIso = (dateTime) => {
  if (!dateTime) return null;
  const dataRetornada = dateTime;
  const dataCortada = dataRetornada.split('-');
  const horaCortada = dataCortada[2].split(' ');
  return new Date(
    `${horaCortada[0]}-${dataCortada[1]}-${dataCortada[0]}T${horaCortada[1]}`,
  );
};

const goToReferenceId = (referenceTable, referenceId) => {
  const allReferenceTable = {
    1: '',
    2: 'vendas/pagamentos',
    3: 'vendas/pesquisar',
    4: 'produto/pesquisar',
    5: 'gestao/custos',
    6: 'gestao/contas',
  };
  const local = allReferenceTable[referenceTable];
  const link = `/home/${local}/${referenceId}`;
  return link || '#';
};

const goToNotificationsTable = (referenceTable, referenceId) => {
  const allReferenceTable = {
    1: 'caixas/transferencia',
    2: 'vendas/pagamentos',
    3: 'vendas/pesquisar',
    5: 'gestao/custos',
    6: 'gestao/contas',
  };
  const local = allReferenceTable[referenceTable];
  const link = `/home/${local}/${referenceId}`;
  return link || '#';
};

const allReferenceTable = [
  { id: 1, name: 'Transferência' },
  { id: 2, name: 'Pagamento' },
  { id: 3, name: 'Venda' },
  { id: 4, name: 'Saida' },
  { id: 5, name: 'Gasto' },
  { id: 6, name: 'Conta/ custo' },
  { id: 7, name: 'Serviço' },
];

allReferenceTable.sort((a, b) => a.name.localeCompare(b.name));

const listReferenceTable = (referenceId) => {
  const reference = allReferenceTable.find(
    (value) => value.id === Number(referenceId),
  );
  return reference.name;
};

const formattedCurrency = (money) =>
  Number(money).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

const isEqualObject = (objA = [], objB = []) => {
  const parseObjA = JSON.stringify(objA);
  const parseObjB = JSON.stringify(objB);
  return parseObjA === parseObjB;
};

const calculateMarkupByValue = (cost, value) =>
  cost && value ? ((Number(value) / Number(cost)) * 100 - 100).toFixed(2) : 0;

const calculateMarginByValue = (cost, value) =>
  cost && value
    ? (((Number(value) - Number(cost)) / Number(value)) * 100).toFixed(2)
    : 0;

const calculateValueByMargin = (cost, margin) =>
  cost && margin ? (Number(cost) / (1 - Number(margin) / 100)).toFixed(2) : 0;

const calculateValueByMarkup = (cost, markup) =>
  cost && markup ? (Number(cost) * (1 + Number(markup) / 100)).toFixed(2) : 0;


export {
  calculateMarkupByValue,
  calculateMarginByValue,
  calculateValueByMargin,
  calculateValueByMarkup,
  emptyObject,
  capitalize,
  clearObject,
  formattedDateTime,
  formattedCurrency,
  formattedDateTimeIso,
  goToReferenceId,
  goToNotificationsTable,
  listReferenceTable,
  allReferenceTable,
  isEqualObject,
};
