import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  description: Yup.string(),
  customerProfile: Yup.number(),
  products: Yup.array()
    .of(Yup.object()),
  services: Yup.array()
    .of(Yup.object()),
  percentage: Yup.number()
    .min(0, 'Inválido')
    .max(100, 'Inválido'),
  discount: Yup.number()
    .min(0, 'Inválido')
    .max(100, 'Inválido'),
});
