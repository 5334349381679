import React from 'react';
import PageContent from '../../../components/Main/Content/mainContent';
import CategoryShow from '../../../components/Category/Show/categoryShow';

const CategoriaNova = () => {
  const type = window.location.pathname.split('/');

  return (
    <PageContent>
      <CategoryShow referenceType={type ? type[type.length - 1] : null} />
    </PageContent>
  );
};

export default CategoriaNova;
