import React from 'react';
import { useParams } from 'react-router-dom';
import PageContent from '../../../components/Main/Content/mainContent';
import PaymentsView from '../../../components/Payments/View/paymentsView';

const PagamentosVisualizar = () => {
  const { id } = useParams();

  return (
    <PageContent>
      <PaymentsView referenceId={id ? Number.parseInt(id, 10) : null} />
    </PageContent>
  );
};

export default PagamentosVisualizar;
