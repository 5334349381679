import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import MainNavBar from '../components/Main/NavBar/navBar';
import RoutesPrivate from '../components/Routes/Private/private';
import StoreProvider from '../components/Store/provider';
import MainSlide from '../components/Main/Slide/slide';
import Login from './Login/login';
import PageContent from '../components/Main/Content/mainContent';
import PrivateComponents from '../components/Routes/Private/components';
import CaixasNovo from './Caixas/Novo/NovoCaixa';
import RouterLogin from '../components/Routes/Login/routerLogin';
import ClienteNovo from './Cliente/Novo/NovoCliente';
import PromocaoNova from './Promocao/Nova/NovaPromocao';
import PromocaoPesquisar from './Promocao/Pesquisar/PesquisarPromocao';
import ProdutoNovo from './Produto/Novo/NovoProduto';
import ProdutoPesquisar from './Produto/Pesquisar/NovoProduto';
import ServicoNovo from './Servicos/Novo/NovoServico';
import ServicoPesquisar from './Servicos/Pesquisar/ProcurarServico';
import CaixaPesquisar from './Caixas/Pesquisar/PesquisarCaixa';
import VendaNova from './Venda/Nova/NovaVenda';
import VendaComanda from './Venda/Comanda/PesquisarVenda';
import CategoriaNova from './Categorias/Nova/NovaCategoria';
import ContasPagar from './Contas/Pagar/ContasPagar';
import VendaPesquisa from './Venda/Pesquisar/PesquisarVenda';
import PagamentosVisualizar from './Pagamentos/Visualizar/VisualizarPagamentos';
import CaixaLogistica from './Caixas/Logística/LogisticaCaixa';
import CaixaFechamentos from './Caixas/Fechamentos/FechamentosCaixa';
import EstoqueVisualizar from './Estoque/Visualizar/VisualizarEstoque';
import CustoNovo from './Custo/Novo/NovoCusto';
import CaixasTransferencia from './Caixas/Transferencia/TransferenciaCaixa';
import PaginaInicial from './Home/PaginaInicial';
import UsuarioNovo from './Usuarios/Novo/NovoUsuario';
import UsuarioPesquisar from './Usuarios/Pesquisar/PesquisarUsuario';
import ClientePesquisar from './Cliente/Pesquisar/PesquisarCliente';
import VisaoGeral from './Gestao/VisaoGeral/VisaoGeral';

const Root = () => (
  <Router>
    <StoreProvider>
      <RouterLogin exact path="/" component={Login} />
      <PrivateComponents>
        <MainNavBar />
        <MainSlide />
      </PrivateComponents>
      <Switch>
        <RoutesPrivate exact path="/home" component={PaginaInicial} />
        <RoutesPrivate path="/home/categoria/nova" component={CategoriaNova} />
        <RoutesPrivate path="/home/produto/novo" component={ProdutoNovo} />
        <RoutesPrivate
          path="/home/produto/pesquisar/:id"
          component={ProdutoPesquisar}
        />
        <RoutesPrivate
          path="/home/produto/pesquisar"
          component={ProdutoPesquisar}
        />
        <RoutesPrivate path="/home/servico/novo" component={ServicoNovo} />
        <RoutesPrivate
          path="/home/servico/pesquisar/:id"
          component={ServicoPesquisar}
        />
        <RoutesPrivate
          path="/home/servico/pesquisar"
          component={ServicoPesquisar}
        />
        <RoutesPrivate path="/home/promocao/nova" component={PromocaoNova} />
        <RoutesPrivate
          path="/home/promocao/pesquisar"
          component={PromocaoPesquisar}
        />
        <RoutesPrivate path="/home/vendas/nova" component={VendaNova} />
        <RoutesPrivate
          path="/home/vendas/pesquisar/:id"
          component={VendaPesquisa}
        />
        <RoutesPrivate
          path="/home/vendas/pesquisar"
          component={VendaPesquisa}
        />
        <RoutesPrivate
          path="/home/vendas/comanda/:id"
          component={VendaComanda}
        />
        <RoutesPrivate path="/home/vendas/comanda" component={VendaComanda} />
        <RoutesPrivate
          path="/home/vendas/pagamentos/:id"
          component={PagamentosVisualizar}
        />
        <RoutesPrivate
          path="/home/vendas/pagamentos"
          component={PagamentosVisualizar}
        />
        <RoutesPrivate path="/home/clientes/novo" component={ClienteNovo} />
        <RoutesPrivate path="/home/usuarios/novo" component={UsuarioNovo} />
        <RoutesPrivate
          path="/home/usuarios/pesquisar"
          component={UsuarioPesquisar}
        />
        <RoutesPrivate
          path="/home/clientes/pesquisar"
          component={ClientePesquisar}
        />
        <RoutesPrivate path="/home/caixas/novo" component={CaixasNovo} />
        <RoutesPrivate
          path="/home/caixas/pesquisar"
          component={CaixaPesquisar}
        />
        <RoutesPrivate
          path="/home/caixas/detalhes"
          component={CaixaLogistica}
        />
        <RoutesPrivate
          path="/home/caixas/fechamentos"
          component={CaixaFechamentos}
        />
        <RoutesPrivate
          path="/home/caixas/sangria"
          component={CaixasTransferencia}
        />
        <RoutesPrivate
          path="/home/gestao/estoque"
          component={EstoqueVisualizar}
        />
        <RoutesPrivate path="/home/gestao/contas/:id" component={ContasPagar} />
        <RoutesPrivate path="/home/gestao/contas" component={ContasPagar} />
        <RoutesPrivate path="/home/gestao/custos" component={CustoNovo} />
        <RoutesPrivate path="/home/gestao/visaogeral" component={VisaoGeral} />
        <RoutesPrivate path="*">
          <PrivateComponents>
            <PageContent>Pagina não encontrada</PageContent>
          </PrivateComponents>
        </RoutesPrivate>
      </Switch>
    </StoreProvider>
  </Router>
);

export default Root;
