import React from 'react';
import ClientSearch from '../../../components/Client/Search/clientSearch';
import PageContent from '../../../components/Main/Content/mainContent';

const ClientePesquisar = () => (
  <PageContent>
    <ClientSearch />
  </PageContent>
);

export default ClientePesquisar;
