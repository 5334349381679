import React from 'react';
import { TablePagination } from '@material-ui/core';

const UITablePagination = (children) => (
  <TablePagination
    rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
    labelRowsPerPage="Linhas por página"
    colSpan={3}
    component="div"
    SelectProps={{
      inputProps: { 'aria-label': 'rows per page' },
      native: true,
    }}
    {...children}
  />
);

export default UITablePagination;
