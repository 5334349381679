import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';
import { format, isValid } from 'date-fns';
import useApi from '../../../utils/useApi';
// import validationSchema from './validationSchema';
import UIButtonSearch from '../../UI/Button/Search/Search';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import { emptyObject } from '../../../utils/utils';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import FormInputDate from '../../UI/Form/InputDate/FormInputDate';

const initialValues = {
  id: '',
  name: '',
  lastName: '',
  gender: '',
  marialStatus: '',
  children: 0,
  city: '',
  neighborhood: '',
  street: '',
  houseNumber: '',
  bornIn: null,
  email: '',
  whatsapp: '',
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const ClientSearch = () => {
  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // mensagem de confirmação para operações
  const [alert, setAlert] = useState(false);

  // modais de pesquisa e filtragem
  const [view, setView] = useState();
  const [filter, setFilter] = useState(false);
  const [modalValues, setModalValues] = useState(null);

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState(null);

  // provider do click do lado direito
  const [clickRight, setClickRight] = useState(initialClick);

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/customers',
  });

  const [updateLoad, updateInfo, setUpdateInfo] = useApi({
    method: 'put',
    url: '/customers',
    onCompleted: (response) => {
      if (response) {
        filterLoad({
          params: search,
        });
      }
    },
  });

  useEffect(() => {
    filterLoad();
  }, []);

  function humanAttributes(values) {
    setModalValues({
      ...values,
      bornIn:
        isValid(values.bornIn) && format(new Date(values.bornIn), 'yyyy-MM-dd'),
    });
  }

  const handleClickRight = (event, values) => {
    event.preventDefault();
    humanAttributes(values);
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };
  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickLeft = (event, values) => {
    humanAttributes(values);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  function handleModalFilter() {
    setFilter(!filter);
  }

  function handleModalUpdate(values, isClick) {
    if (values) {
      humanAttributes(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function closeAlert(event, reason) {
    setUpdateInfo();
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  }

  function fullName(values) {
    return `${values.name} ${values.lastName || ''}`;
  }

  function onSubmit(values) {
    const searchValues = {
      ...values,
      bornIn:
        isValid(values.bornIn) && format(new Date(values.bornIn), 'yyyy-MM-dd'),
    };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
    });
    setSearch(emptyObject(searchValues));
  }

  function onUpdate(values) {
    const updateValues = {
      ...values,
      bornIn:
        isValid(values.bornIn) && format(new Date(values.bornIn), 'yyyy-MM-dd'),
    };
    updateLoad({
      data: updateValues,
    });
    setView(!view);
    setAlert(true);
  }

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, submitForm, setFieldValue }) => (
          <Form onChange={() => submitForm()}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Filtrar clientes" />
                <UIFilter onClick={handleModalFilter} />
              </Grid>
              <Grid item xs={2} md={1}>
                <Field
                  name="id"
                  label="ID"
                  placeholder="ID"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <Field
                  name="name"
                  label="Nome"
                  placeholder="Informe o nome do cliente"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <Field
                  name="lastName"
                  label="Sobrenome"
                  placeholder="Informe o sobrenome do cliente"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden smDown>
                <Grid item xs={3}>
                  <Field
                    name="email"
                    label="E-mail"
                    type="email"
                    placeholder="Informe o e-mail do cliente"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputMask
                    value={values.whatsapp}
                    mask="(99) 9 9999-9999"
                    onChange={(ev) =>
                      setFieldValue(
                        'whatsapp',
                        ev.target.value.replace(/[^0-9]/g, ''),
                      )
                    }
                  >
                    {() => (
                      <TextField
                        label="Whatsapp"
                        placeholder="Informe o numero do whatsapp"
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Grid>
                <UIButtonSearch xs={1} />
              </Hidden>
            </UIContainer>

            <UIModalFilter
              open={filter}
              changeModal={handleModalFilter}
              submit={submitForm}
            >
              <Hidden mdUp>
                <Grid item xs={6}>
                  <Field
                    name="email"
                    label="E-mail"
                    type="email"
                    placeholder="Informe o e-mail do cliente"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputMask
                    value={values.whatsapp}
                    mask="(99) 9 9999-9999"
                    onChange={(ev) =>
                      setFieldValue(
                        'whatsapp',
                        ev.target.value.replace(/[^0-9]/g, ''),
                      )
                    }
                  >
                    {() => (
                      <TextField
                        label="Whatsapp"
                        placeholder="Informe o numero do whatsapp"
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Grid>
              </Hidden>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel shrink id="client-search-gender">
                    Gênero
                  </InputLabel>
                  <Field
                    name="gender"
                    labelId="client-search-gender"
                    displayEmpty
                    fullWidth
                    as={Select}
                  >
                    <MenuItem value="">
                      <em>Selecione o gênero</em>
                    </MenuItem>
                    <MenuItem value={1}>Masculino</MenuItem>
                    <MenuItem value={2}>Feminino</MenuItem>
                    <MenuItem value={3}>Indefinido</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel shrink id="client-search-marialStutus">
                    Estado civil
                  </InputLabel>
                  <Field
                    name="marialStatus"
                    labelId="client-search-marialStutus"
                    displayEmpty
                    fullWidth
                    as={Select}
                  >
                    <MenuItem value="">
                      <em>Selecione estado civil</em>
                    </MenuItem>
                    <MenuItem value={1}>Solteiro</MenuItem>
                    <MenuItem value={2}>Casado</MenuItem>
                    <MenuItem value={3}>Divorciado</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="children"
                  type="number"
                  label="Filhos"
                  placeholder="Numero de filhos"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="houseNumber"
                  label="Número"
                  placeholder="Informe o numero da casa"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="neighborhood"
                  label="Bairro"
                  placeholder="Informe o nome da bairro"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="street"
                  label="Rua / Avenida"
                  placeholder="Informe o nome da rua"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="city"
                  label="Cidade"
                  placeholder="Informe o nome da cidade"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputDate
                  value={values.bornIn}
                  clearable
                  label="Data de nascimento"
                  placeholder="Informe a data nascimento do cliente"
                  onChange={(value) => setFieldValue('bornIn', value)}
                />
              </Grid>
            </UIModalFilter>
          </Form>
        )}
      </Formik>
      <UIContainer>
        <Grid item xs>
          <UIFormTitle title="Lista de clientes" />
        </Grid>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Nascimento</TableCell>
                  <TableCell>Nº Whatsapp</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterInfo.loading && <CircularProgress />}
                {filterInfo.data &&
                  filterInfo.data.map((data) => (
                    <TableRow
                      onDoubleClick={() => handleModalUpdate(data)}
                      onContextMenu={
                        !mobile ? (ev) => handleClickRight(ev, data) : undefined
                      }
                      style={{ cursor: 'context-menu' }}
                      onClick={
                        mobile ? (ev) => handleClickLeft(ev, data) : undefined
                      }
                      hover
                      key={data.id}
                    >
                      <TableCell component="th" scope="row">
                        {data.id}
                      </TableCell>
                      <TableCell>{fullName(data)}</TableCell>
                      <TableCell>{data.bornIn}</TableCell>
                      <TableCell>{data.whatsapp || 'Sem numero'}</TableCell>
                      <TableCell>
                        <IconButton
                          title="Visualizar/Editar"
                          onClick={() => handleModalUpdate(data)}
                        >
                          <SearchIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                <Menu
                  keepMounted
                  open={clickRight.mouseY !== null}
                  onClose={handleCloseClickRight}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    clickRight.mouseY !== null && clickRight.mouseX !== null
                      ? {
                          top: clickRight.mouseY,
                          left: clickRight.mouseX,
                        }
                      : undefined
                  }
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseClickLeft()}
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                </Menu>
                {filterInfo.error && (
                  <TableRow>
                    <TableCell>Sem resultados encotrados</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </UIContainer>
      {modalValues && (
        <Dialog
          open={view}
          onClose={() => {
            handleModalUpdate();
            setModalValues(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <Formik
            enableReinitialize
            onSubmit={onUpdate}
            initialValues={modalValues}
          >
            {({ values, errors, setFieldValue, submitForm }) => (
              <Form>
                <UIDialogTitle
                  id="form-dialog-title"
                  onClose={() => handleModalUpdate()}
                >
                  {fullName(modalValues)}
                </UIDialogTitle>
                <DialogContent>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>
                      <Field
                        name="name"
                        label="Nome"
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        placeholder="Nome do produto"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="lastName"
                        label="Sobrenome"
                        placeholder="Informe o sobrenome do cliente"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="email"
                        label="E-mail"
                        type="email"
                        placeholder="Informe o e-mail do cliente"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputMask
                        value={values.whatsapp}
                        mask="(99) 9 9999-9999"
                        onChange={(ev) =>
                          setFieldValue(
                            'whatsapp',
                            ev.target.value.replace(/[^0-9]/g, ''),
                          )
                        }
                      >
                        {() => (
                          <TextField
                            label="Whatsapp"
                            placeholder="Informe o numero do whatsapp"
                            fullWidth
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel shrink id="client-search-gender">
                          Gênero
                        </InputLabel>
                        <Field
                          name="gender"
                          labelId="client-search-gender"
                          displayEmpty
                          fullWidth
                          as={Select}
                        >
                          <MenuItem value="">
                            <em>Selecione o gênero</em>
                          </MenuItem>
                          <MenuItem value={1}>Masculino</MenuItem>
                          <MenuItem value={2}>Feminino</MenuItem>
                          <MenuItem value={3}>Indefinido</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel shrink id="client-search-marialStutus">
                          Estado civil
                        </InputLabel>
                        <Field
                          name="marialStatus"
                          labelId="client-search-marialStutus"
                          displayEmpty
                          fullWidth
                          as={Select}
                        >
                          <MenuItem value="">
                            <em>Selecione estado civil</em>
                          </MenuItem>
                          <MenuItem value={1}>Solteiro</MenuItem>
                          <MenuItem value={2}>Casado</MenuItem>
                          <MenuItem value={3}>Divorciado</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="children"
                        type="number"
                        label="Filhos"
                        placeholder="Numero de filhos"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="houseNumber"
                        label="Número"
                        placeholder="Informe o numero da casa"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="neighborhood"
                        label="Bairro"
                        placeholder="Informe o nome da bairro"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="street"
                        label="Rua / Avenida"
                        placeholder="Informe o nome da rua"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="city"
                        label="Cidade"
                        placeholder="Informe o nome da cidade"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInputDate
                        value={values.bornIn}
                        clearable
                        label="Data de nascimento"
                        placeholder="Informe a data nascimento do cliente"
                        onChange={(value) => setFieldValue('bornIn', value)}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {JSON.stringify(values) !== JSON.stringify(modalValues) && (
                    <Button onClick={() => handleModalUpdate()} color="primary">
                      Cancelar
                    </Button>
                  )}
                  <Button
                    onClick={
                      JSON.stringify(values) === JSON.stringify(modalValues)
                        ? () => handleModalUpdate()
                        : () => submitForm()
                    }
                    color="primary"
                  >
                    {JSON.stringify(values) === JSON.stringify(modalValues)
                      ? 'Fechar'
                      : 'Salvar'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}

      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={updateInfo}
        setrequestinfo={closeAlert}
        messageSucess={
          updateInfo.data
            ? updateInfo.data.message
            : 'Cliente atualizado com sucesso'
        }
        messageError="Alguma coisa está errada, verifique novamente"
        type="success"
      />
    </>
  );
};

export default ClientSearch;
