import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Dialog,
  DialogActions,
  FormControlLabel,
  FormLabel,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Hidden,
  ListItem,
  Collapse,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import ChipInput from 'material-ui-chip-input';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import UIButtonSearch from '../../UI/Button/Search/Search';
import { capitalize, emptyObject, isEqualObject } from '../../../utils/utils';
import useApi from '../../../utils/useApi';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import validationSchema from '../New/validationSchema';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import UITablePagination from '../../UI/Table/Pagination/UITablePagination';

const initialSearch = {
  id: '',
  name: '',
  description: '',
  particulars: '',
  isActive: true,
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const CategorySearch = (children) => {
  const [alert, setAlert] = useState(false);
  const [alertUpdate, setAlertUpdate] = useState(false);
  const [particular, setParticular] = useState('');

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState({ isActive: 1 });

  // verificar se é mobile
  const { mobile, collapseSearch, changeCollapseSearch, type } = children;

  // modais de pesquisa e filtragem
  const [view, setView] = useState(false);
  const [changeActive, setChangeActive] = useState(false);
  const [modalValues, setModalValues] = useState({});
  const [filter, setFilter] = useState(false);

  // paginação
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ação para click direito
  const [clickRight, setClickRight] = useState(initialClick);
  const handleClickRight = (event, values) => {
    event.preventDefault();
    if (values) {
      const valuesTreated = {
        ...values,
        isActive: values.isActive === '1' && true,
        particulars: values.particulars,
      };
      setModalValues(valuesTreated);
    }
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickLeft = (event, values) => {
    if (values) {
      const valuesTreated = {
        ...values,
        isActive: values.isActive === '1' && true,
        particulars: values.particulars
      };
      setModalValues(valuesTreated);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: type === 'p' ? '/products/categories' : '/services/categories',
    params: { isActive: 1 },
  });

  const [activeLoad, activeInfo, setActiveInfo] = useApi({
    method: 'patch',
    url: '/products/categories',
  });

  const [updateLoad, updateInfo, setUpdateInfo] = useApi({
    method: 'put',
    url: '/products/categories',
  });

  useEffect(() => {
    filterLoad();
  }, []);

  function handleModalFilter() {
    setFilter(!filter);
  }

  function handleModalUpdate(values, isClick) {
    if (values) {
      const valuesTreated = {
        ...values,
        isActive: values.isActive === '1' && true,
        particulars: values.particulars,
      };
      setModalValues(valuesTreated);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function handleModalActive(values, isClick) {
    if (values) {
      setModalValues(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setChangeActive(!changeActive);
  }

  function onSearch(values) {
    const searchValues = { ...values, isActive: values.isActive ? 1 : '0' };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
    });
    setSearch(emptyObject(searchValues));
  }

  function onActive() {
    const data = { id: modalValues.id };
    activeLoad({
      data,
      onCompleted: (res) => {
        if (res) {
          filterLoad({
            params: search,
          });
        }
      },
    });
    setAlert(true);
    setChangeActive(false);
  }

  function onUpdate(values) {
    const data = {
      ...values,
    };
    delete data.isActive;
    updateLoad({
      data,
      onCompleted: (res) => {
        if (res) {
          filterLoad({
            params: search,
          });
        }
      },
    });
    setView(!view);
    setAlertUpdate(true);
  }

  return (
    <>
      <UIContainer container direction="row">
        <ListItem
          button
          onClick={changeCollapseSearch}
          style={{ justifyContent: 'space-between' }}
        >
          <UIFormTitle title="Filtrar categorias" />
          {collapseSearch ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={collapseSearch}
          timeout="auto"
          unmountOnExit
          style={{ width: '100%' }}
        >
          <>
            <Formik onSubmit={onSearch} initialValues={initialSearch}>
              {({ values, submitForm, setFieldValue }) => (
                <Form style={{ width: '100%' }} onChange={() => submitForm()}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={2} md={1}>
                      <Field
                        name="id"
                        label="ID"
                        placeholder="ID"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <Field
                        name="name"
                        label="Nome"
                        placeholder="Nome da categoria"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Hidden smDown>
                      <Grid item xs={12} md={3}>
                        <Field
                          name="description"
                          label="Descrição"
                          placeholder="Descrição da categoria"
                          fullWidth
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Field
                          name="particulars"
                          label="Particularidade"
                          placeholder="Particularidade da categoria"
                          fullWidth
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Categorias</FormLabel>
                          <FormControlLabel
                            label={values.isActive ? 'Ativas' : 'Inativas'}
                            labelPlacement="start"
                            control={
                              <Switch
                                color="default"
                                checked={values.isActive}
                                onChange={(ev) =>
                                  setFieldValue('isActive', ev.target.checked)
                                }
                              />
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Hidden>
                    <Hidden mdUp>
                      <UIFilter onClick={handleModalFilter} />
                    </Hidden>
                    <Hidden smDown>
                      <UIButtonSearch xs={1} />
                    </Hidden>
                  </Grid>

                  <UIModalFilter
                    open={filter}
                    changeModal={handleModalFilter}
                    submit={submitForm}
                  >
                    <Grid item xs={12} md={3}>
                      <Field
                        name="description"
                        label="Descrição"
                        placeholder="Descrição da categoria"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        name="particulars"
                        label="Particularidade"
                        placeholder="Particularidade da categoria"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Categorias</FormLabel>
                        <FormControlLabel
                          label={values.isActive ? 'Ativas' : 'Inativas'}
                          labelPlacement="start"
                          control={
                            <Switch
                              color="default"
                              checked={values.isActive}
                              onChange={(ev) =>
                                setFieldValue('isActive', ev.target.checked)
                              }
                            />
                          }
                        />
                      </FormControl>
                    </Grid>
                  </UIModalFilter>
                </Form>
              )}
            </Formik>

            <Grid item xs style={{ marginTop: '15px' }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterInfo.loading && <CircularProgress />}
                    {filterInfo.data &&
                      filterInfo.data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((data) => (
                          <TableRow
                            onDoubleClick={() => handleModalUpdate(data)}
                            onContextMenu={
                              !mobile
                                ? (ev) => handleClickRight(ev, data)
                                : undefined
                            }
                            style={{ cursor: 'context-menu' }}
                            onClick={
                              mobile
                                ? (ev) => handleClickLeft(ev, data)
                                : undefined
                            }
                            hover
                            key={data.id}
                          >
                            <TableCell component="th" scope="row">
                              {data.id}
                            </TableCell>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{data.description}</TableCell>
                            <TableCell>
                              {Number(data.isActive) === 1
                                ? 'Ativa'
                                : 'Desativada'}
                            </TableCell>
                            <TableCell>
                              <Switch
                                title={
                                  Number(data.isActive) === 1
                                    ? 'Desativar categoria'
                                    : 'Ativar categoria'
                                }
                                onClick={() => handleModalActive(data)}
                                color="primary"
                                checked={Number(data.isActive) === 1}
                              />
                              <IconButton
                                title="Visualizar/Editar"
                                onClick={() => handleModalUpdate(data)}
                              >
                                <SearchIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    <Menu
                      keepMounted
                      open={clickRight.mouseY !== null}
                      onClose={handleCloseClickRight}
                      anchorReference="anchorPosition"
                      anchorPosition={
                        clickRight.mouseY !== null && clickRight.mouseX !== null
                          ? {
                              top: clickRight.mouseY,
                              left: clickRight.mouseX,
                            }
                          : undefined
                      }
                    >
                      <MenuItem onClick={() => handleModalUpdate(null, true)}>
                        Visualizar/Editar
                      </MenuItem>
                      <MenuItem onClick={() => handleModalActive(null, true)}>
                        {Number(modalValues.isActive) === 1
                          ? 'Desativar categoria'
                          : 'Ativar categoria'}
                      </MenuItem>
                    </Menu>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => handleCloseClickLeft()}
                    >
                      <MenuItem onClick={() => handleModalUpdate(null, true)}>
                        Visualizar/Editar
                      </MenuItem>
                      <MenuItem onClick={undefined}>
                        {Number(modalValues.isActive) === 1
                          ? 'Desativar categoria'
                          : 'Ativar categoria'}
                      </MenuItem>
                    </Menu>
                    {filterInfo.error && (
                      <TableRow>
                        <TableCell>Sem resultados encotrados</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <UITablePagination
                  count={filterInfo.data ? filterInfo.data.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </>
        </Collapse>
      </UIContainer>

      {modalValues && (
        <Dialog open={view} onClose={() => handleModalUpdate()}>
          <Formik
            enableReinitialize
            onSubmit={onUpdate}
            initialValues={modalValues}
            validationSchema={validationSchema}
          >
            {({ values, submitForm, setFieldValue, errors }) => (
              <Form>
                <UIDialogTitle
                  id="form-dialog-title"
                  onClose={() => handleModalUpdate()}
                >
                  {modalValues.name}
                </UIDialogTitle>
                <DialogContent>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={5}>
                      <Field
                        name="name"
                        label="Nome da categoria"
                        placeholder="Informe o nome da categoria"
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Field
                        name="description"
                        label="Descrição da categoria"
                        placeholder="Informe a descrição da categoria"
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    {Object.entries(values.particulars).map(([key, value]) => (
                      <Grid
                        key={key}
                        container
                        justify="space-between"
                        alignItems="flex-end"
                        item
                        xs={12}
                      >
                        <ChipInput
                          label={capitalize(key)}
                          placeholder="Adicione atributos a particulariedade"
                          value={value}
                          onAdd={(chip) =>
                            setFieldValue(`particulars.${key}`, [
                              ...values.particulars[key],
                              chip,
                            ])
                          }
                          onDelete={(chip) => {
                            const newValuesChip = values.particulars[
                              key
                            ].filter((item) => item !== chip);
                            setFieldValue(`particulars.${key}`, newValuesChip);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    const newObject = { ...values.particulars };
                                    delete newObject[key];
                                    setFieldValue('particulars', newObject);
                                  }}
                                  aria-label="delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <TextField
                        value={particular}
                        label="Adicionar particulariedade"
                        placeholder="Informe o nome da particulariedade"
                        onChange={(ev) => setParticular(ev.target.value)}
                        onKeyUp={(ev) => {
                          if (ev.key === 'Enter') {
                            const keysParticulars = Object.keys(
                              values.particulars,
                            );
                            const partcularsLowCase = particular.toLowerCase();
                            if (
                              particular.trim() &&
                              !keysParticulars.includes(partcularsLowCase)
                            ) {
                              setFieldValue('particulars', {
                                ...values.particulars,
                                [partcularsLowCase]: [],
                              });
                              setParticular('');
                            }
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                title="Adicionar"
                                style={{ color: '#23a114' }}
                                onClick={() => {
                                  const keysParticulars = Object.keys(
                                    values.particulars,
                                  );
                                  const partcularsLowCase =
                                    particular.toLowerCase();
                                  if (
                                    particular.trim() &&
                                    !keysParticulars.includes(partcularsLowCase)
                                  ) {
                                    setFieldValue('particulars', {
                                      ...values.particulars,
                                      [partcularsLowCase]: [],
                                    });
                                    setParticular('');
                                  }
                                }}
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {!isEqualObject(values, modalValues) && (
                    <Button onClick={() => handleModalUpdate()} color="primary">
                      Cancelar
                    </Button>
                  )}
                  <Button
                    onClick={
                      isEqualObject(values, modalValues)
                        ? () => handleModalUpdate()
                        : () => submitForm()
                    }
                    color="primary"
                  >
                    {isEqualObject(values, modalValues) ? 'Fechar' : 'Salvar'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}

      <Dialog open={changeActive} onClose={() => setChangeActive(false)}>
        <UIDialogTitle id="alert-dialog-title">
          Deseja {Number(modalValues.isActive) === 1 ? 'desativar' : 'ativar'} a{' '}
          categoria {modalValues.name}
        </UIDialogTitle>
        <DialogActions>
          <Button onClick={() => setChangeActive(false)} color="primary">
            Não
          </Button>
          <Button onClick={() => onActive()} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={activeInfo}
        setrequestinfo={setActiveInfo}
        messageSucess={`Categoria ${
          Number(modalValues.isActive) === 1 ? 'desativada' : 'ativada'
        } com sucesso!`}
        messageError="Ops, ocorreu um erro ao manipular a categoria"
      />

      <UIDialogConfirm
        open={alertUpdate}
        close={() => setAlertUpdate(false)}
        data={updateInfo}
        setrequestinfo={setUpdateInfo}
        messageSucess="Categoria atualizada com sucesso"
        messageError="Não é possivel atualizar a categoria"
      />
    </>
  );
};

export default CategorySearch;
