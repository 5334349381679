import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import CheckIcon from '@material-ui/icons/Check';
import { Field, Form, Formik } from 'formik';
import useApi from '../../../utils/useApi';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import { emptyObject, formattedCurrency } from '../../../utils/utils';
import debounce from 'lodash/debounce';

const initialState = {
  name: '',
  category: null,
  value: 0,
};

const SaleSearchItem = ({ ...children }) => {
  const [item, setItem] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [amountSelected, setAmountSelected] = useState(1);

  const handleClickLeft = (event, data) => {
    setItem(data);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setItem(null);
    setAnchorEl(null);
  };

  const [searchItemLoad, searchItemInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    onCompleted: (res) => {
      if (!res) setDataTable([]);
    },
  });

  const [productsCategoryLoad, productsCategoryInfo] = useApi({
    method: 'get',
    url: '/products/categories',
    params: { isActive: 1 },
  });

  const [servicesCategoryLoad, servicesCategoryInfo] = useApi({
    method: 'get',
    url: '/services/categories',
    params: { isActive: 1 },
  });

  useEffect(() => {
    productsCategoryLoad();
    servicesCategoryLoad();
  }, []);

  function selectItem(data) {
    if (children.mobile) {
      handleCloseClickLeft();
    }
    data.amountSelected = Number(amountSelected);
    children.selectItem(data);
  }

  async function onSearch(values) {
    const searchValues = {
      ...values,
      category: values.category ? Number(values.category.id) : '',
    };

    const productsParams = { ...emptyObject(searchValues), lacking: 0 };
    delete productsParams.amountSelected;

    let searchData = [];
    await searchItemLoad({
      params: productsParams,
      url: '/products',
      onCompleted: (res) => {
        if (res) {
          let products = res.data.map((p) => ({
            ...p,
            typeItem: 'product',
          }));
          searchData = searchData.concat(products);
        }
      },
    });

    const serviceParams = { ...emptyObject(searchValues), isActive: 1 };
    delete serviceParams.amountSelected;
    await searchItemLoad({
      params: serviceParams,
      url: '/services',
      onCompleted: (res) => {
        if (res) {
          let services = res.data.map((p) => ({
            ...p,
            typeItem: 'service',
          }));
          searchData = searchData.concat(services);
        }
      },
    });

    setDataTable(searchData);
  }
  const debouncedOnSearch = debounce(onSearch, 500);

  function getCategory() {
    if (servicesCategoryInfo.data && productsCategoryInfo.data) {
      return servicesCategoryInfo.data.concat(productsCategoryInfo.data);
    }

    if (servicesCategoryInfo.data !== null) {
      return servicesCategoryInfo.data;
    }

    if (productsCategoryInfo.data !== null) {
      return productsCategoryInfo.data;
    }

    return [];
  }

  return (
    <Dialog open={children.open} onClose={children.handleModal} maxWidth="md">
      <Formik onSubmit={debouncedOnSearch} initialValues={initialState}>
        {({ values, setFieldValue, submitForm }) => (
          <Form>
            <UIDialogTitle onClose={children.handleModal}>
              Procurar Item
            </UIDialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={7}>
                  <Field
                    name="name"
                    label="Nome do item"
                    placeholder="Pesquise pelo nome do item"
                    fullWidth
                    as={TextField}
                    onChange={(ev) => {
                      setFieldValue('name', ev.target.value);
                      submitForm(ev);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Autocomplete
                    clearOnEscape
                    noOptionsText="Sem opções"
                    value={values.category}
                    disabled={
                      productsCategoryInfo.loading &&
                      servicesCategoryInfo.loading
                    }
                    onChange={(ev, category) => {
                      setFieldValue('category', category);
                      submitForm(ev);
                    }}
                    options={getCategory()}
                    getOptionLabel={(category) => category.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Categoria"
                        name="category"
                        variant="standard"
                        placeholder="Informe a categoria do produto"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <CurrencyTextField
                    label="Preço do item"
                    variant="standard"
                    value={values.value}
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    fullWidth
                  />
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  item
                  xs={12}
                >
                  <Button
                    onClick={() => submitForm(values)}
                    color="primary"
                    startIcon={
                      (searchItemInfo.loading && (
                        <CircularProgress size="0.9rem" color="inherit" />
                      )) ||
                      (searchItemInfo.loading && (
                        <CircularProgress size="0.9rem" color="inherit" />
                      ))
                    }
                    autoFocus
                  >
                    Pesquisar
                  </Button>
                </Grid>
                <Grid container justify="center" alignItems="center" item xs>
                  {searchItemInfo.error &&
                    dataTable.length === 0 &&
                    'Sem resultado'}
                </Grid>
                {dataTable.length > 0 && (
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Categoria</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell>Preço</TableCell>
                            <TableCell>
                              <TextField
                                value={amountSelected || 1}
                                onChange={(ev) => {
                                  if (ev.target.value >= 0) {
                                    setAmountSelected(ev.target.value);
                                  }
                                }}
                                label="Quantos"
                                type="number"
                                fullWidth
                              />
                            </TableCell>
                            <TableCell>Selecionar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {searchItemInfo.loading ||
                            (searchItemInfo.loading && (
                              <TableRow>
                                <TableCell>
                                  <CircularProgress />
                                </TableCell>
                              </TableRow>
                            ))}
                          {dataTable.map((data) => (
                            <TableRow
                              hover
                              key={data.id}
                              onClick={
                                children.mobile
                                  ? (ev) => handleClickLeft(ev, data)
                                  : undefined
                              }
                            >
                              <TableCell component="th" scope="row">
                                {data.name}
                              </TableCell>
                              <TableCell>
                                {
                                  getCategory().filter(
                                    (category) => category.id == data.category,
                                  )[0]?.name
                                }
                              </TableCell>
                              <TableCell style={{ fontWeight: 'bold' }}>
                                {data.typeItem === 'product'
                                  ? 'Produto'
                                  : 'Serviço'}
                              </TableCell>

                              <TableCell>
                                {formattedCurrency(data.value)}
                              </TableCell>
                              <TableCell>
                                Estoque: {data.amount || 'Serviço'}
                              </TableCell>
                              <TableCell>
                                <span title="Adicionar produto">
                                  <IconButton onClick={() => selectItem(data)}>
                                    <CheckIcon />
                                  </IconButton>
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions />
          </Form>
        )}
      </Formik>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseClickLeft}
      >
        <MenuItem onClick={() => selectItem(item)}>Selecionar produto</MenuItem>
      </Menu>
    </Dialog>
  );
};

export default SaleSearchItem;
