import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import StoreContext from '../../Store/context';

import OverviewCard from '../Cards/overviewCard';
import UIContainer from '../../UI/Container/UIContainer';

import './overviewShow.css';

const OverviewShow = () => {
  const { credencials } = useContext(StoreContext);
  return (
    <UIContainer className="overview-show" fullHeight title="Visão geral: vendas">
      <iframe
        src={`https://duzz-relatorios.streamlit.app/resumo?pseudonym=${credencials.companyData.pseudonimo}&company=${credencials.company}&session_token=${credencials.sessionToken}&embed=true`}
        title="Visao Geral"
      />
    </UIContainer>
  );
};

export default OverviewShow;
