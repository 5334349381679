import React from 'react';
import TransferShow from '../../../components/Cashier/Transfer/Show/transferShow';
import PageContent from '../../../components/Main/Content/mainContent';

const CaixasTransferencia = () => (
  <PageContent>
    <TransferShow />
  </PageContent>
);

export default CaixasTransferencia;
