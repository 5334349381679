import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import StoreContext from '../../Store/context';

// eslint-disable-next-line
const RoutesPrivate = ({ component: Component, ...rest }) => {
  const { credencials } = useContext(StoreContext);

  return (
    <Route
      {...rest}
      render={() =>
        credencials ? <Component {...rest} /> : <Redirect to="/" />
      }
    />
  );
};

export default RoutesPrivate;
