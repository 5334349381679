import { React, useState, useRef } from 'react';
import CountUp from 'react-countup';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Editable from 'react-editable-title';
import { emptyObject, formattedCurrency } from '../../../../utils/utils';
import useApi from '../../../../utils/useApi';
import { useReactToPrint } from 'react-to-print';
import '../../cupom.css';
import './sessionCard.css';

const SessionCard = (children) => {
  const { data, handleModalSale, setOldValues, refreshCards } = children;
  const {
    session_id: sessionId,
    identifier,
    sale_metadata: saleMetadata,
    oldValue,
    id,
    responsible,
  } = data;
  const [modalExclude, setModelExclude] = useState(false);
  const [textTitle, setTextTitle] = useState(identifier);
  const [textTiny, setTextTiny] = useState(responsible);

  const contentRef = useRef();
  const reactToPrintFn = useReactToPrint({ contentRef });

  const [salesLoad] = useApi({
    method: 'put',
    url: '/sales/sessions',
  });

  const [sessionDelete] = useApi({
    method: 'delete',
    url: '/sales/sessions',
  });

  const deleteSession = () => {
    sessionDelete({
      data: emptyObject({ session_id: sessionId }),
      onCompleted: () => {
        refreshCards({});
      },
    });
  };

  const handleModalExclude = () => {
    setModelExclude(!modalExclude);
  };

  const handleTextTitleUpdate = (current) => {
    if (current !== textTitle) {
      setTextTitle(current);
      salesLoad({
        data: emptyObject({ session_id: sessionId, identifier: current }),
        onCompleted: () => {
          refreshCards({});
        },
      });
    }
  };

  const handleTextTinyUpdate = (current) => {
    if (current !== textTiny) {
      setTextTiny(current);
      salesLoad({
        data: emptyObject({ session_id: sessionId, responsible: current }),
        onCompleted: () => {
          refreshCards({});
        },
      });
    }
  };

  return (
    <div className="sessions-card__card">
      <Typography className="sessions-card__title">
        <Editable
          text={textTitle}
          placeholder="Nome"
          cb={handleTextTitleUpdate}
          className="sessions-card__title"
        />
      </Typography>
      <Typography className="sessions-card__tranfer">
        <Editable
          style={{ fontWeight: 1000 }}
          text={textTiny}
          placeholder="Funcionário"
          cb={handleTextTinyUpdate}
        />
      </Typography>
      <Typography
        noWrap
        className="sessions-card__money"
        onClick={() => handleModalSale(data)}
      >
        <span>
          <CountUp
            startOnMount
            decimals={2}
            decimal=","
            prefix="R$ "
            end={saleMetadata.total}
            start={oldValue}
            duration={2}
            onEnd={() => {
              if (Number(oldValue) !== Number(saleMetadata.total))
                setOldValues(id, saleMetadata.total);
            }}
          />
        </span>
      </Typography>
      <Grid
        ref={contentRef}
        container
        direction="row"
        spacing={1}
        class="sale-resume-print"
        hidden
      >
        <TableContainer>
          <Typography align="center" variant="h6" component="div">
            Resumo do pedido #{data.identifier}
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Responsável</TableCell>
                <TableCell>{data.responsible}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Comanda Aberta em</TableCell>
                <TableCell>
                  {new Date(data.created_at * 1000).toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Itens</TableCell>
                {data.sale_metadata.products.map((p) => (
                  <TableRow>
                    <TableCell>
                      {p.amount}x {formattedCurrency(p.price)}
                    </TableCell>
                    <p style={{ padding: 0, margin: 0 }}>
                      <TableCell>{p.item}</TableCell>
                    </p>
                  </TableRow>
                ))}
                {data.sale_metadata.services.map((p) => (
                  <TableRow>
                    <TableCell>
                      {p.amount}x {formattedCurrency(p.price)}
                    </TableCell>
                    <p style={{ padding: 0, margin: 0 }}>
                      <TableCell> {p.item}</TableCell>
                    </p>
                  </TableRow>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Valor da compra</TableCell>
                <TableCell>
                  {formattedCurrency(data.sale_metadata.totalSum)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Desconto</TableCell>
                <TableCell>
                  {formattedCurrency(data.sale_metadata.discount)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Acréscimo juros</TableCell>
                <TableCell>
                  {formattedCurrency(data.sale_metadata.increase)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 700, fontSize: 20 }}>
                  Valor Total
                </TableCell>
                <TableCell style={{ fontWeight: 700, fontSize: 20 }}>
                  {formattedCurrency(data.sale_metadata.total)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Button onClick={reactToPrintFn} color="primary" autoFocus>
        🖨️ Imprimir
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={() => handleModalExclude()}
      />
      <Dialog
        open={modalExclude}
        onClose={handleModalExclude}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Cancelar Comanda
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja encerrar e apagar essa comanda?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalExclude} color="primary">
            Voltar
          </Button>
          <Button onClick={deleteSession} color="secondary">
            Apagar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SessionCard;
