import React from 'react';
import PageContent from '../../../components/Main/Content/mainContent';
import ServiceNew from '../../../components/Services/New/serviceNew';

const ServicoNovo = () => (
  <PageContent>
    <ServiceNew />
  </PageContent>
);

export default ServicoNovo;
