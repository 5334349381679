import React, { useContext, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Hidden,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './styleForm.css';
import './style.css';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import validationSchema from './validationSchema';
import validationSchemaCompany from './validationSchemaCompany';
import StoreContext from '../../Store/context';
import useApi from '../../../utils/useApi';
import UILogo from '../../UI/Logo/UILogo';
import { useCookies } from 'react-cookie';

const LoginForm = () => {
  const [isCompany, setIsCompany] = useState(false);
  const { setCredencials } = useContext(StoreContext);
  const [cookies, setCookie] = useCookies(['company']);

  const initialState = {
    username: '',
    password: '',
    company: cookies.company,
    cnpj: '',
  };

  const userAcess = {};
  const history = useHistory();
  const [signIn, signInInfo] = useApi({
    method: 'get',
    onCompleted: (response) => {
      if (response) {
        userAcess.id = response.data.id;
        userAcess.name = response.data.name;
        userAcess.lastName = response.data.lastName;
        userAcess.cashSession = 0;
        userAcess.username = response.data.username;
        userAcess.notifications = response.data.notifications;
        userAcess.profile = response.data.profile;
        userAcess.sessionToken = response.data.sessionToken;
        userAcess.companyData = response.data.companyData;
        setCredencials(userAcess);
        history.push('/home');
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  function toggleCompany() {
    setIsCompany(!isCompany);
  }

  function onSubmit(values) {
    const valuesUser = {
      username: values.username,
      password: values.password,
      company: values.company,
    };

    userAcess.company = values.company;
    setCookie('company', values.company, { path: '/' });

    const valuesCompany = {
      cnpj: values.cnpj,
      password: values.password,
    };

    signIn({
      params: isCompany ? valuesCompany : valuesUser,
      url: isCompany ? '/auth/company' : '/auth/user',
    });
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="user-login__global">
      <Container>
        <Grid
          className="user-login__top-nav"
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <UILogo className="user-login__duzz-commercial" size="32px" />
          <a href="https://loja.infinitepay.io/duzz_system" target="_blank">
            <Button className="user-login__btn-adquira" variant="contained">
              ADQUIRA JÁ
            </Button>
          </a>
        </Grid>
        <Grid container justify="center" className="user-login__content">
          <Grid container className="user-login__form">
            {/* Formulario para empresa */}
            <Hidden smDown={!isCompany}>
              <Grid item md={6} sm={12} className="user-login__form-form">
                <Hidden smDown>
                  <Slide direction="left" in={!isCompany}>
                    <Grid item className="user-login__img-login">
                      <img alt="Logo Duzz System" src="image/Alinhado.png" />
                    </Grid>
                  </Slide>
                </Hidden>

                <Grid item className="user-login__login-position">
                  <Grid container justify="space-between">
                    <Hidden mdUp>
                      <Grid item className="user-login__logo-hidden">
                        <img alt="Logo Duzz Syster" src="image/logo2.png" />
                      </Grid>
                    </Hidden>
                    <Grid item xs />
                    <Grid item>
                      <Button
                        variant="contained"
                        className="user-login__toggle"
                        onClick={toggleCompany}
                      >
                        {isCompany
                          ? 'Entrar como usuario'
                          : 'Entrar como empresa'}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    direction="column"
                    className="user-login__welcome"
                  >
                    <Typography variant="h6">Entrar</Typography>
                    <Typography paragraph>
                      Seja bem vindo, por favor se identifique para continuar
                    </Typography>
                  </Grid>
                  <Grid />
                  <Formik
                    initialValues={initialState}
                    validationSchema={validationSchemaCompany}
                    onSubmit={onSubmit}
                  >
                    {({ errors }) => (
                      <Form className="user-login__form-inputs">
                        <FormControl fullWidth>
                          <Field
                            as={TextField}
                            label="CNPJ"
                            name="cnpj"
                            placeholder="Insira o CNPJ da empresa"
                            margin="dense"
                            helperText={errors.cnpj}
                            error={Boolean(errors.cnpj)}
                            type="number"
                            className="user-login__form-inputs-cnpj"
                          />
                          <Field
                            as={TextField}
                            label="Senha"
                            name="password"
                            type="password"
                            placeholder="Insira sua senha"
                            margin="dense"
                            helperText={errors.password}
                            error={Boolean(errors.password)}
                          />
                        </FormControl>

                        {signInInfo.error && (
                          <Typography color="error">
                            Sem sucesso, verifique o login e tente novamente
                          </Typography>
                        )}
                        <Button
                          variant="contained"
                          type="submit"
                          className="user-login__btn-entrar"
                          startIcon={
                            signInInfo.loading && (
                              <CircularProgress size="0.9rem" color="inherit" />
                            )
                          }
                        >
                          {signInInfo.loading ? 'Entrando' : 'Entrar'}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Hidden>
            {/* Formulario para usuario */}
            <Hidden smDown={isCompany}>
              <Grid item md={6} sm={12} className="user-login__form-form">
                <Hidden smDown>
                  <Slide direction="right" in={isCompany}>
                    <Grid item className="user-login__img-login">
                      <img alt="Logo Duzz System" src="image/Alinhado.png" />
                    </Grid>
                  </Slide>
                </Hidden>
                <Grid className="user-login__login-position">
                  <Grid container justify="space-between">
                    <Hidden mdUp>
                      <Grid item className="user-login__logo-hidden">
                        <img alt="Logo Duzz Syster" src="image/logo2.png" />
                      </Grid>
                    </Hidden>
                    <Grid item xs />
                    <Grid item>
                      <Button
                        variant="contained"
                        className="user-login__toggle"
                        onClick={toggleCompany}
                      >
                        {isCompany
                          ? 'Entrar como usuario'
                          : 'Entrar como empresa'}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    className="user-login__welcome"
                  >
                    <Typography variant="h6">Entrar</Typography>
                    <Typography paragraph>
                      Seja bem vindo, por favor se identifique para continuar
                    </Typography>
                  </Grid>
                  <Grid>
                    <Formik
                      initialValues={initialState}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ errors }) => (
                        <Form className="user-login__form-inputs">
                          <FormControl fullWidth>
                            <Field
                              as={TextField}
                              name="username"
                              margin="dense"
                              label="Login"
                              placeholder="Insira seu login"
                              helperText={errors.username}
                              error={Boolean(errors.username)}
                            />
                            <Field
                              as={TextField}
                              label="Senha"
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Insira sua senha"
                              margin="dense"
                              helperText={errors.password}
                              error={Boolean(errors.password)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="Clique para exibir a senha"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Field
                              as={TextField}
                              label="Empresa"
                              name="company"
                              placeholder="Informe o codigo da sua empresa"
                              margin="dense"
                              type="number"
                              helperText={errors.company}
                              error={Boolean(errors.company)}
                            />
                          </FormControl>

                          {signInInfo.error && (
                            <Typography color="error">
                              Sem sucesso, verifique o login e tente novamente
                            </Typography>
                          )}
                          <Button
                            variant="contained"
                            type="submit"
                            className="user-login__btn-entrar"
                            startIcon={
                              signInInfo.loading && (
                                <CircularProgress
                                  size="0.9rem"
                                  color="inherit"
                                />
                              )
                            }
                          >
                            {signInInfo.loading ? 'Entrando' : 'Entrar'}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default LoginForm;
