import React, { useEffect, useState } from 'react';
import {
  Switch,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import useApi from '../../../utils/useApi';
import validationSchema from './validationSchema';
import UIButtonSearch from '../../UI/Button/Search/Search';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import {
  emptyObject,
  capitalize,
  clearObject,
  formattedCurrency,
  isEqualObject,
} from '../../../utils/utils';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import UITablePagination from '../../UI/Table/Pagination/UITablePagination';

const initialState = {
  id: '',
  name: '',
  category: '',
  particulars: '',
  value: '',
  barCode: '',
  supplier: '',
  isActive: '1',
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const ServiceSearch = (children) => {
  const { referenceId } = children;

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // mensagem de confirmação para operações
  const [alert, setAlert] = useState(false);

  // modais de pesquisa e filtragem
  const [view, setView] = useState(!!referenceId);
  const [changeActive, setChangeActive] = useState(false);
  const [filter, setFilter] = useState(false);
  const [modalValues, setModalValues] = useState({});

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState(null);

  // paginação
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // provider do click do lado direito
  const [clickRight, setClickRight] = useState(initialClick);
  const handleClickRight = (event, values) => {
    event.preventDefault();
    setModalValues({
      ...values,
      particulars: values.particulars,
    });
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };
  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickLeft = (event, values) => {
    setModalValues({
      ...values,
      particulars: values.particulars,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  // chamadas á API
  const [categoryLoad, categoryInfo] = useApi({
    method: 'get',
    url: '/services/categories',
  });

  const [suppliersLoad, suppliersInfo] = useApi({
    method: 'get',
    url: '/suppliers',
  });

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/services',
    params: { isActive: '1' },
  });

  const [updateLoad, updateInfo, setUpdateInfo] = useApi({
    method: 'put',
    url: '/services',
    onCompleted: (response) => {
      if (response) {
        filterLoad({
          params: search,
        });
      }
    },
  });

  const [activeLoad, activeInfo, setActiveInfo] = useApi({
    method: 'patch',
    url: '/services',
  });

  useEffect(() => {
    if (referenceId) initialState.id = referenceId;
    const params = referenceId ? { id: referenceId } : '';
    filterLoad({
      params,
      onCompleted: (res) => {
        if (res && referenceId) {
          const [data] = res.data;
          setModalValues({
            ...data,
            particulars: data.particulars,
          });
        }
      },
    });
    suppliersLoad();
    categoryLoad();
  }, []);

  function handleModalFilter() {
    setFilter(!filter);
  }

  function handleModalUpdate(values, isClick) {
    if (values) {
      const valuesTreated = {
        ...values,
        isActive: values.isActive === '1' && true,
        particulars: values.particulars,
      };
      setModalValues(valuesTreated);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function handleModalActive(values, isClick) {
    if (values) {
      setModalValues(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setChangeActive(!changeActive);
  }

  function closeAlert(event, reason) {
    setUpdateInfo();
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  }

  function onSearch(values) {
    const searchValues = {
      ...values,
      isActive: values.isActive === true || values.isActive === '1' ? '1' : '0',
    };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
    });
    setSearch(emptyObject(searchValues));
  }

  function onActive() {
    const data = { id: modalValues.id };
    activeLoad({
      data,
      onCompleted: (res) => {
        if (res) {
          filterLoad({
            params: search,
          });
        }
      },
    });
    setAlert(true);
    setChangeActive(false);
  }

  function onUpdate(values) {
    const updateValues = {
      id: values.id,
      name: values.name,
      barCode: values.barCode,
      category: Number(values.category),
      particulars: values.particulars,
      value: values.value,
      supplier: values.supplier.id,
    };
    updateLoad({
      data: updateValues,
    });
    setView(!view);
    setAlert(true);
  }

  return (
    <>
      <Formik onSubmit={onSearch} initialValues={initialState}>
        {({ values, submitForm, setFieldValue }) => (
          <Form onChange={() => submitForm()}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Filtrar serviços" />
                <UIFilter onClick={handleModalFilter} />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} md={1}>
                  <Field
                    name="id"
                    label="ID"
                    placeholder="ID"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={6} md={2}>
                <Field
                  name="name"
                  label="Nome"
                  placeholder="Nome do serviço"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <Autocomplete
                  clearOnEscape
                  noOptionsText="Sem opções"
                  disabled={categoryInfo.loading}
                  options={categoryInfo.data !== null ? categoryInfo.data : []}
                  getOptionSelected={(category) => category.name}
                  getOptionLabel={(category) => category.name}
                  onChange={(event, category) => {
                    setFieldValue('category', category && category.id);
                    submitForm();
                  }}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      label="Categoria"
                      name="category"
                      variant="standard"
                      placeholder="Informe a categoria do serviço"
                      component={TextField}
                    />
                  )}
                />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} md={2}>
                  <Field
                    name="barCode"
                    label="Código serviço"
                    placeholder="Código do serviço"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item xs={12} md={2}>
                  <CurrencyTextField
                    type="tel"
                    value={values.value}
                    label="Valor de venda"
                    variant="standard"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    onChange={(ev, value) => setFieldValue('value', value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Disponível</FormLabel>
                    <FormControlLabel
                      label={values.isActive ? 'Sim' : 'Não'}
                      labelPlacement="start"
                      control={
                        <Switch
                          color="default"
                          checked={values.isActive}
                          onChange={(ev) =>
                            setFieldValue('isActive', ev.target.checked)
                          }
                        />
                      }
                    />
                  </FormControl>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <UIButtonSearch xs={1} />
              </Hidden>
            </UIContainer>

            <UIModalFilter
              open={filter}
              changeModal={handleModalFilter}
              submit={submitForm}
            >
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Field
                    name="id"
                    label="ID"
                    placeholder="ID"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12}>
                <Autocomplete
                  clearOnEscape
                  noOptionsText="Sem opções"
                  value={values.supplier}
                  disabled={suppliersInfo.loading}
                  options={
                    suppliersInfo.data !== null ? suppliersInfo.data : []
                  }
                  getOptionLabel={(supplier) => supplier.name}
                  onChange={(event, supplier) => {
                    setFieldValue('supplier', supplier);
                  }}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      label="Fornecedor"
                      name="supplier"
                      variant="standard"
                      placeholder="Informe o fornecedor do serviço"
                      component={TextField}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="particulars"
                  label="Caracteristica do serviço"
                  placeholder="Informe a caracteristica do serviço"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Field
                    name="barCode"
                    label="Código serviço"
                    placeholder="Código do serviço"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <CurrencyTextField
                    type="tel"
                    value={values.value}
                    label="Valor de venda"
                    variant="standard"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    onChange={(ev, value) => setFieldValue('value', value)}
                    fullWidth
                  />
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Field
                    name="isActive"
                    label="isActive"
                    placeholder="Está disponível"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
            </UIModalFilter>
          </Form>
        )}
      </Formik>
      <UIContainer>
        <Grid item xs>
          <UIFormTitle title="Lista de serviços" />
        </Grid>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Categoria</TableCell>
                  <TableCell>Valor de venda</TableCell>
                  <TableCell> </TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterInfo.loading && <CircularProgress />}
                {filterInfo.data &&
                  categoryInfo.data &&
                  filterInfo.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data) => (
                      <TableRow
                        onDoubleClick={() => handleModalUpdate(data)}
                        onContextMenu={
                          !mobile
                            ? (ev) => handleClickRight(ev, data)
                            : undefined
                        }
                        style={{ cursor: 'context-menu' }}
                        onClick={
                          mobile ? (ev) => handleClickLeft(ev, data) : undefined
                        }
                        hover
                        key={data.id}
                      >
                        <TableCell component="th" scope="row">
                          {data.id}
                        </TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>
                          {
                            categoryInfo.data.find(
                              (item) => item.id === data.category,
                            ).name
                          }
                        </TableCell>
                        <TableCell>{formattedCurrency(data.value)}</TableCell>
                        <TableCell>{data.amount}</TableCell>
                        <TableCell>
                          <Switch
                            title={
                              Number(data.isActive) === 1
                                ? 'Desativar categoria'
                                : 'Ativar categoria'
                            }
                            onClick={() => handleModalActive(data)}
                            color="primary"
                            checked={Number(data.isActive) === 1}
                          />
                          <IconButton
                            title="Visualizar/Editar"
                            onClick={() => handleModalUpdate(data)}
                          >
                            <SearchIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                <Menu
                  keepMounted
                  open={clickRight.mouseY !== null}
                  onClose={handleCloseClickRight}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    clickRight.mouseY !== null && clickRight.mouseX !== null
                      ? {
                          top: clickRight.mouseY,
                          left: clickRight.mouseX,
                        }
                      : undefined
                  }
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  <MenuItem onClick={() => handleModalActive(null, true)}>
                    {Number(modalValues.isActive) === 1
                      ? 'Desativar categoria'
                      : 'Ativar categoria'}
                  </MenuItem>
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseClickLeft()}
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  <MenuItem onClick={undefined}>
                    {Number(modalValues.isActive) === 1
                      ? 'Desativar categoria'
                      : 'Ativar categoria'}
                  </MenuItem>
                </Menu>
                {filterInfo.error && (
                  <TableRow>
                    <TableCell>Sem resultados encotrados</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <UITablePagination
              count={filterInfo.data ? filterInfo.data.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </UIContainer>
      {modalValues && categoryInfo.data && suppliersInfo.data && (
        <Dialog
          open={view}
          onClose={() => {
            handleModalUpdate();
            setModalValues(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <Formik
            enableReinitialize
            onSubmit={onUpdate}
            initialValues={modalValues}
            validationSchema={validationSchema}
          >
            {({ values, errors, setFieldValue, submitForm }) => (
              <Form>
                <UIDialogTitle
                  id="form-dialog-title"
                  onClose={() => handleModalUpdate()}
                >
                  {modalValues.name}
                </UIDialogTitle>
                <DialogContent>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="name"
                        label="Nome"
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        placeholder="Nome do serviço"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableClearable
                        noOptionsText="Sem opções"
                        value={categoryInfo.data.find(
                          (item) => item.id === values.category,
                        )}
                        disabled={categoryInfo.loading}
                        options={
                          categoryInfo.data !== null ? categoryInfo.data : []
                        }
                        getOptionLabel={(category) => category.name}
                        onChange={(event, category) => {
                          setFieldValue('category', category && category.id);
                          if (event.target.value !== '') {
                            setFieldValue(
                              'particulars',
                              clearObject(category.particulars, true),
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <Field
                            {...params}
                            label="Categoria"
                            name="category"
                            variant="standard"
                            placeholder="Informe a categoria do serviço"
                            component={TextField}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableClearable
                        noOptionsText="Sem opções"
                        value={suppliersInfo.data.find(
                          (item) => item.id === values.supplier,
                        )}
                        options={
                          suppliersInfo.data !== null ? suppliersInfo.data : []
                        }
                        getOptionLabel={(supplier) => supplier.name}
                        onChange={(event, supplier) => {
                          setFieldValue('supplier', supplier.id);
                        }}
                        renderInput={(params) => (
                          <Field
                            {...params}
                            label="Fornecedor"
                            name="supplier"
                            variant="standard"
                            placeholder="Informe o fornecedor do serviço"
                            component={TextField}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="barCode"
                        label="Codigo de serviço"
                        placeholder="Código do serviço"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CurrencyTextField
                        type="tel"
                        value={values.value}
                        label="Valor de venda"
                        variant="standard"
                        error={Boolean(errors.value)}
                        helperText={errors.value}
                        onChange={(ev, value) => setFieldValue('value', value)}
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        fullWidth
                      />
                    </Grid>
                    {values.particulars &&
                      categoryInfo.data &&
                      Object.entries(
                        categoryInfo.data.find(
                          (item) => item.id === values.category,
                        ).particulars,
                      ).map(([key, value]) => (
                        <Grid item xs={12} md={4}>
                          <InputLabel shrink id={key}>
                            {capitalize(key)}
                          </InputLabel>
                          <Field
                            name={`particulars.${key}`}
                            disabled={!values.category}
                            labelId={key}
                            displayEmpty
                            fullWidth
                            as={Select}
                          >
                            <MenuItem disabled value="">
                              <em>Informe o(a) {key}</em>
                            </MenuItem>
                            {typeof value === 'object'
                              ? value.map((vl) => (
                                  <MenuItem key={vl} value={vl}>
                                    {vl}
                                  </MenuItem>
                                ))
                              : value && (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                )}
                          </Field>
                        </Grid>
                      ))}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {!isEqualObject(values, modalValues) && (
                    <Button onClick={() => handleModalUpdate()} color="primary">
                      Cancelar
                    </Button>
                  )}
                  <Button
                    onClick={
                      isEqualObject(values, modalValues)
                        ? () => handleModalUpdate()
                        : () => submitForm()
                    }
                    color="primary"
                  >
                    {isEqualObject(values, modalValues) ? 'Fechar' : 'Salvar'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}

      <Dialog open={changeActive} onClose={() => setChangeActive(false)}>
        <UIDialogTitle id="alert-dialog-title">
          Deseja tornar o serviço{' '}
          {Number(modalValues.isActive) === 1 ? 'indisponível' : 'disponível'} ?
        </UIDialogTitle>
        <DialogActions>
          <Button onClick={() => setChangeActive(false)} color="primary">
            Não
          </Button>
          <Button onClick={() => onActive()} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={activeInfo}
        setrequestinfo={setActiveInfo}
        messageSucess={`Serviço ${
          Number(modalValues.isActive) === 1 ? 'desativado' : 'ativado'
        } com sucesso!`}
        messageError="Ops, ocorreu um erro ao manipular o serviço"
      />

      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={updateInfo}
        setrequestinfo={closeAlert}
        messageSucess={
          updateInfo.data
            ? updateInfo.data.message
            : 'Serviço atualizado com sucesso'
        }
        type="success"
      />
    </>
  );
};

export default ServiceSearch;
