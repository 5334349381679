import React from 'react';
import ClientNew from '../../../components/Client/New/clientNew';
import PageContent from '../../../components/Main/Content/mainContent';

const ClienteNovo = () => (
  <PageContent>
    <ClientNew />
  </PageContent>
);

export default ClienteNovo;
