import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Grid,
  Tab,
  Tabs,
  Typography,
  DialogActions,
  DialogContent,
  Tooltip,
  Fab,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { TabContext, TabPanel } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import useApi from '../../../utils/useApi';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import StoreContext from '../../Store/context';
import CashierLogisticOpen from './Open/cashierLogisticOpen';
import CashierLogisticTable from './Table/casherLogisticTable';
import { formattedCurrency } from '../../../utils/utils';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import CashierLogisticBar from './Graph/cashierLogisticBar';
import CashierLogisticPie from './Graph/cashierLogisticPie';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import CashierLogisticBarUsers from './Graph/cashierLogisticBarUsers';
import CashierLogisticRadar from './Graph/cashierLogisticRadar';

const initialValues = {
  value: 0,
  errors: null,
};

const CashierLogistic = () => {
  const { credencials } = useContext(StoreContext);
  const { cashSession } = credencials;

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // define os valores de outros no grafico
  const [othersValues, setOthersValues] = useState(null);

  // valores do caixa
  const [cashier, setCashier] = useState(null);

  const [closeCashier, setCloseCashier] = useState(false);
  const [modalValues, setModalValues] = useState(initialValues);
  const [alert, setAlert] = useState(false);

  // modais de pesquisa e filtragem
  const [tab, setTab] = useState('4');

  const handleTab = (event, newValue) => {
    setTab(newValue);
    if (othersValues) setOthersValues(null);
  };

  const handleModalCashier = () => {
    setCloseCashier(!closeCashier);
  };

  const changeValues = (name, value) => {
    if (Number(value) >= 0) {
      setModalValues({ ...modalValues, [name]: value, errors: null });
    } else {
      setModalValues({ ...modalValues, errors: 'Pagamento invalido' });
    }
  };

  const [cashierMoveLoad, cashierMoveInfo, setCashierMove] = useApi({
    method: 'get',
    params: { cashRegister: cashSession },
    url: '/cash-registers/moves',
  });

  const [cashierLoad, cashierInfo, setCashierInfo] = useApi({
    url: '/cash-registers',
  });

  const [paymentLoad, paymentInfo] = useApi({
    method: 'get',
    url: '/payments/methods',
  });

  const [userLoad, userInfo] = useApi({
    method: 'get',
    url: '/users',
  });

  useEffect(() => {
    userLoad();
    paymentLoad();

    if (credencials.cashSession) {
      cashierLoad({
        method: 'get',
        params: { id: cashSession },
        onCompleted: (res) => {
          if (res) {
            const data = res.data[0];
            setCashier(data);
          }
        },
      });
      cashierMoveLoad();
    } else {
      setCashier(null);
    }
  }, [credencials]);

  function openOtherValues(data, tabIndex) {
    setOthersValues(data);
    setTab(tabIndex);
  }

  function onOpenCloseCash() {
    const data = {
      cashRegister: cashSession,
      value: modalValues.value,
    };
    cashierLoad({
      method: 'patch',
      data,
      onCompleted: (res) => {
        if (res) {
          if (cashier.isOpen === '1') {
            setCashierMove();
          }
          cashier.isOpen = cashier.isOpen === '1' ? 0 : '1';
          setCashier(cashier);
        }
        setAlert(!alert);
        setCloseCashier(false);
      },
    });
  }

  function toggleBackForOthers() {
    setOthersValues(!othersValues);
    setTab('4');
  }

  if (cashSession && cashier)
    return (
      <>
        <UIContainer container>
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            xs={12}
          >
            <UIFormTitle title={cashier.name} />
          </Grid>
          {cashier.isOpen === '1' ? (
            <Typography
              style={{ fontSize: 18 }}
              className="cashier-logistic-open__text"
              onClick={() => setTab('4')}
            >
              Olá {credencials.name}, o {cashier.name} está com o{' '}
              <span>saldo de {formattedCurrency(cashier.currentValue)}</span>
            </Typography>
          ) : (
            <Typography
              className="cashier-logistic-open__text"
              style={{ fontSize: 18 }}
              onClick={handleModalCashier}
            >
              Olá {credencials.name}, o {cashier.name}
              <span> não está aberto.</span>
            </Typography>
          )}
        </UIContainer>
        <UIContainer style={{ height: '62.5%', overflow: 'auto' }}>
          <TabContext value={tab}>
            <Grid item xs>
              <Tabs
                value={tab}
                onChange={handleTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab value="1" label="Últimas transações entrada" />
                <Tab value="2" label="Últimas transações saída" />
                <Tab value="3" label="Descontos aplicados" />
                <Tab value="4" label="Resumo" />
                <Tab value="5" label="Balanço" />
              </Tabs>
            </Grid>

            {cashierMoveInfo.data && (
              <>
                <TabPanel value="1" index="1" style={{ padding: 0 }}>
                  <CashierLogisticTable
                    mobile={mobile}
                    data={othersValues || cashierMoveInfo.data.entries.moves}
                    loading={cashierMoveInfo.loading}
                    error={cashierMoveInfo.error}
                  />
                </TabPanel>
                <TabPanel value="2" index="2" style={{ padding: 0 }}>
                  <CashierLogisticTable
                    mobile={mobile}
                    data={othersValues || cashierMoveInfo.data.outs.moves}
                    loading={cashierMoveInfo.loading}
                    error={cashierMoveInfo.error}
                  />
                </TabPanel>
                <TabPanel value="3" index="3" style={{ padding: 0 }}>
                  <CashierLogisticTable
                    mobile={mobile}
                    data={othersValues || cashierMoveInfo.data.discounts.moves}
                    loading={cashierMoveInfo.loading}
                    error={cashierMoveInfo.error}
                  />
                </TabPanel>
                <TabPanel
                  value="4"
                  index="4"
                  style={{ padding: 0, height: '81%' }}
                >
                  <Grid container style={{ width: '100%', height: '100%' }}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justify="center"
                      style={{ height: '100%', marginBottom: '30px' }}
                    >
                      <CashierLogisticBarUsers
                        dataApi={cashierMoveInfo.data}
                        color="#1B79DF"
                        caption="Movimentações por operador"
                        total={cashierMoveInfo.data.entries.total}
                        dataLabel={userInfo.data}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justify="center"
                      style={{ height: '100%', marginBottom: '10px' }}
                    >
                      <CashierLogisticRadar
                        moves={cashierMoveInfo.data.entries.moves}
                        caption="Entradas por método de pagamento"
                        dataLabel={paymentInfo.data}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel
                  value="5"
                  index="5"
                  style={{ padding: 10, height: '100%' }}
                >
                  <Grid container style={{ width: '100%', height: '100%' }}>
                    {!mobile ? (
                      <Grid
                        container
                        style={{
                          width: '50%',
                          height: '60%',
                          marginLeft: '50px',
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          md={4}
                          justify="center"
                          style={{ height: '100%' }}
                        >
                          <CashierLogisticPie
                            moves={cashierMoveInfo.data.entries.moves}
                            color="#1B79DF"
                            caption="Transações de entrada"
                            total={cashierMoveInfo.data.entries.total}
                            openOtherValues={openOtherValues}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          md={4}
                          justify="center"
                          style={{ height: '100%', marginBottom: '10px' }}
                        >
                          <CashierLogisticPie
                            moves={cashierMoveInfo.data.outs.moves}
                            color="#D60429"
                            caption="Transações de saida"
                            total={cashierMoveInfo.data.outs.total}
                            openOtherValues={openOtherValues}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          md={4}
                          justify="center"
                          style={{ height: '100%', marginBottom: '10px' }}
                        >
                          <CashierLogisticPie
                            moves={cashierMoveInfo.data.discounts.moves}
                            color="#f39708"
                            caption="Descontos Aplicados"
                            total={cashierMoveInfo.data.discounts.total}
                            openOtherValues={(data) => {
                              openOtherValues(data, '3');
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        height: !mobile ? '50%' : '90%',
                        marginBottom: '10px',
                        marginLeft: '50px',
                      }}
                    >
                      <CashierLogisticBar
                        entriesMoves={cashierMoveInfo.data.entries.total}
                        outsMoves={cashierMoveInfo.data.outs.total}
                        discounts={cashierMoveInfo.data.discounts.total}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </>
            )}
          </TabContext>
        </UIContainer>
        <UIContainer
          container
          direction="row"
          alignItems="center"
          style={{ minHeight: '15%' }}
        >
          <>
            <Grid
              item
              xs={12}
              md={5}
              className="form-container-sale__table-info-payment"
            >
              <Typography
                paragraph
                style={{
                  margin: 0,
                  color:
                    cashierMoveInfo.data && cashierMoveInfo.data.total < 0
                      ? 'red'
                      : 'green',
                }}
              >
                BALANÇO:{' '}
                {cashierMoveInfo.data && (
                  <span>{formattedCurrency(cashierMoveInfo.data.total)}</span>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              className="form-container-sale__table-info-payment"
            >
              <Typography paragraph style={{ margin: 0 }}>
                TOTAL MOVIMENTADO:{' '}
                {cashierMoveInfo.data && (
                  <span>
                    {formattedCurrency(cashierMoveInfo.data.totalBustling)}
                  </span>
                )}
              </Typography>
            </Grid>
          </>
          <Grid xs={12} md={2} item container>
            <Grid item xs />
            <Button
              className="UIModal__button-search"
              variant="contained"
              type="submit"
              size="large"
              onClick={handleModalCashier}
            >
              {cashier.isOpen === '1' ? 'Fechar caixa' : 'Abrir caixa'}
            </Button>
          </Grid>
        </UIContainer>
        <Dialog
          open={closeCashier}
          fullWidth
          onClose={handleModalCashier}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <UIDialogTitle id="alert-dialog-title">
            Deseja {cashier.isOpen === '1' ? 'fechar' : 'abrir'} {cashier.name}?
          </UIDialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CurrencyTextField
                  type="tel"
                  value={modalValues.value}
                  error={Boolean(modalValues.errors)}
                  helperText={modalValues.errors}
                  decimalCharacter=","
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  onChange={(ev, value) => changeValues('value', value)}
                  label="Informe o valor do caixa"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalCashier} color="primary">
              Cancelar
            </Button>
            <Button
              disabled={Boolean(modalValues.errors)}
              onClick={onOpenCloseCash}
              color="primary"
              autoFocus
            >
              {cashier.isOpen === '1' ? 'Fechar caixa' : 'Abrir caixa'}
            </Button>
          </DialogActions>
        </Dialog>
        <UIDialogConfirm
          open={alert}
          setrequestinfo={setCashierInfo}
          close={() => setAlert(false)}
          data={cashierInfo}
          messageSucess={cashierInfo.data && cashierInfo.data.Message}
        />
        {othersValues && (tab === '1' || tab === '2' || tab === '3') && (
          <Tooltip title="Fechar 'Outros'">
            <Fab
              style={{
                backgroundColor: '#94031f',
                color: '#fff',
                right: '50%',
                left: '50%',
                bottom: '35px',
                position: 'relative',
              }}
              size="small"
              variant="extended"
              onClick={toggleBackForOthers}
            >
              Voltar
            </Fab>
          </Tooltip>
        )}
      </>
    );

  return <CashierLogisticOpen />;
};

export default CashierLogistic;
