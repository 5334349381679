import React from 'react';
import CountUp from 'react-countup';
import { Typography } from '@material-ui/core';
import './cashierCard.css';

const CashierCard = (children) => {
  const { data, handleModalTransfer, setOldValues } = children;
  const { name, currentValue, oldValue, id } = data;

  return (
    <div className="cashier-card__card">
      <Typography className="cashier-card__title">{name}</Typography>
      <Typography noWrap className="cashier-card__money">
        <span>
          <CountUp
            startOnMount
            decimals={2}
            decimal=","
            prefix="R$ "
            end={currentValue}
            start={oldValue}
            duration={2}
            onEnd={() => {
              if (Number(oldValue) !== Number(currentValue))
                setOldValues(id, currentValue);
            }}
          />
        </span>
      </Typography>
      <Typography
        className="cashier-card__tranfer"
        variant="overline"
        onClick={() => handleModalTransfer(data)}
      >
        Transferir para ...
      </Typography>
    </div>
  );
};

export default CashierCard;
