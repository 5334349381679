import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

const FormInputDate = (children) => (
  <KeyboardDatePicker
    {...children}
    format="dd/MM/yyyy"
    invalidDateMessage="Formato de data invalida"
    minDateMessage="Informe uma data mínima válida"
    maxDateMessage="Informe uma data máxima válida"
    fullWidth
  />
);

export default FormInputDate;
