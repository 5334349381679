import React, { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormControlLabel,
  Switch,
  TableHead,
  TableRow,
  TextField,
  Hidden,
  ListItem,
  Collapse,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

import { Autocomplete } from '@material-ui/lab';
import { addDays, isEqual } from 'date-fns';
import UIContainer from '../../../UI/Container/UIContainer';
import UIFormTitle from '../../../UI/Form/Title/UIFormTitle';
import UIButtonSearch from '../../../UI/Button/Search/Search';
import StoreContext from '../../../Store/context';
import useApi from '../../../../utils/useApi';
import UIDialogTitle from '../../../UI/Dialog/Title/UIDialogTitle';
import UIFilter from '../../../UI/Button/Filter/UIFilter';
import UIModalFilter from '../../../UI/ModalFilter/UIModalFilter';
import UITablePagination from '../../../UI/Table/Pagination/UITablePagination';

import { emptyObject, formattedCurrency } from '../../../../utils/utils';
import useStyles from '../../../../utils/useStyle';
import MyDateRangePicker from '../../../UI/Form/DateRangePicker/DateRangePicker';
import UIDialogConfirm from '../../../UI/Dialog/Confirm/UIDialogConfirm';

const initialSearch = {
  id: '',
  sender: null,
  receiver: null,
  requestedBy: '',
  requestedAtInitialRange: '',
  requestedAtFinalRange: '',
  requestedValue: '',
  approvedValue: '',
  approvedBy: '',
  refusedBy: '',
  comment: '',
  accept: false,
  closed: true,
  closedAtInitialRange: '',
  closedAtFinalRange: '',
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const TransferSearch = (children) => {
  // verificar se é mobile
  const {
    mobile,
    collapseSearch,
    changeCollapseSearch,
    usersInfo,
    paymentInfo,
  } = children;

  const { credencials } = useContext(StoreContext);

  const { disabledTextField } = useStyles();

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState({ closed: true });

  // ação aceitar
  const [modalAccept, setModalAccept] = useState(false);
  const [alertAccept, setAlertAccept] = useState(false);

  // ação recusar
  const [modalRefuse, setModalRefuse] = useState(false);
  const [alertRefuse, setAlertRefuse] = useState(false);

  // paginação
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // modais de pesquisa e filtragem
  const [view, setView] = useState(false);
  const [modalValues, setModalValues] = useState(null);
  const [filter, setFilter] = useState(false);

  // ação para click direito
  const [clickRight, setClickRight] = useState(initialClick);

  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [dateRequestValues, setDateRequestValues] = useState({
    startDate: new Date(),
    endDate: null,
    key: 'selection',
  });

  const [dateClosedValues, setDateClosedValues] = useState({
    startDate: new Date(),
    endDate: null,
    key: 'selection',
  });

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/cash-registers/transfers',
    params: { closed: '0' },
  });

  const [acceptLoad, acceptInfo, setAcceptInfo] = useApi({
    method: 'patch',
    url: '/cash-registers/transfers',
  });

  const [refuseLoad, refuseInfo, setRefuseInfo] = useApi({
    method: 'delete',
    url: '/cash-registers/transfers',
  });

  const [transferLoad] = useApi({
    method: 'get',
    url: '/cash-registers/transfers',
  });

  const [cashierLoad, cashierInfo] = useApi({
    method: 'get',
    url: '/cash-registers',
    params: { isActive: 1, userId: Number(credencials.id) },
  });

  useEffect(() => {
    filterLoad();
    cashierLoad();
  }, [collapseSearch]);

  function getFullName(id) {
    if (!id) return null;
    const user = usersInfo.data.find((value) => value.id === id);
    const fullName = `${user.name} ${user.lastName || ''}`;
    return fullName;
  }

  function getName(dataInfo, id) {
    if (!dataInfo.data) return null;
    if (dataInfo.loading) return null;
    const client = dataInfo.data.find((value) => value.id === id);

    try {
      return client.name;
    } catch (e) {
      return 'Sem nome';
    }
  }

  const humanAttributes = (transferId) => {
    transferLoad({
      params: {
        id: transferId,
      },
      onCompleted: (res) => {
        if (res) {
          const data = res.data[0];
          setModalValues({
            ...data,
            sender: getName(cashierInfo, data.sender),
            receiver: getName(cashierInfo, data.receiver),
            requestedBy: getFullName(data.requestedBy),
            refusedBy: getFullName(data.refusedBy),
            approvedBy: getFullName(data.approvedBy),
            paymentMethod: getName(paymentInfo, data.paymentMethod),
            accept: data.accept,
            closed: data.closed,
            close: true, // campo para modal accept
          });
        }
      },
    });
  };

  const handleClickRight = (event, costId) => {
    event.preventDefault();
    if (costId) {
      humanAttributes(costId);
    }
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  const handleClickLeft = (event, costId) => {
    if (costId) {
      humanAttributes(costId);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  function handleModalFilter() {
    setFilter(!filter);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleModalView(costId, isClick) {
    if (costId) {
      humanAttributes(costId);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function handleModalAccept(costId, isClick) {
    if (costId) {
      humanAttributes(costId);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setModalAccept(!modalAccept);
  }

  function handleModalRefuse(costId, isClick) {
    if (costId) {
      humanAttributes(costId);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setModalRefuse(!modalRefuse);
  }

  function onSearch(values) {
    const params = {
      ...values,
      sender: values.sender && values.sender.id,
      receiver: values.receiver && values.receiver.id,
      requestedBy: values.requestedBy && values.requestedBy.id,
      approvedBy: values.approvedBy && values.approvedBy.id,
      refusedBy: values.refusedBy && values.refusedBy.id,
      accept: values.accept && '1',
      closed: !values.closed ? '1' : '0',
      closedAtInitialRange:
        dateClosedValues.endDate && dateClosedValues.startDate,
      closedAtFinalRange: isEqual(
        dateClosedValues.endDate,
        dateClosedValues.startDate,
      )
        ? addDays(dateClosedValues.endDate, 1)
        : dateClosedValues.endDate,
      requestedAtInitialRange:
        dateRequestValues.endDate && dateRequestValues.startDate,
      requestedAtFinalRange: isEqual(
        dateRequestValues.endDate,
        dateRequestValues.startDate,
      )
        ? addDays(dateRequestValues.endDate, 1)
        : dateRequestValues.endDate,
    };
    filterLoad({
      params: emptyObject(params),
      onCompleted: () => {
        setSearch(values);
      },
    });
  }

  function onAccept() {
    if (Number(modalValues.requestedValue) < 0) return;
    const data = {
      id: modalValues.id,
      approvedValue: modalValues.requestedValue,
      close: modalValues.close ? '1' : '0',
    };
    acceptLoad({
      data,
      onCompleted: (res) => {
        setAlertAccept(!alertAccept);
        if (res) {
          handleModalAccept();
          changeCollapseSearch();
        }
      },
    });
  }

  function onRefuse() {
    const data = {
      id: modalValues.id,
    };
    refuseLoad({
      data,
      onCompleted: (res) => {
        setAlertRefuse(!alertRefuse);
        if (res) {
          handleModalRefuse();
          onSearch(search);
        }
      },
    });
  }

  return (
    <>
      <UIContainer container direction="row">
        <ListItem
          button
          onClick={changeCollapseSearch}
          style={{ justifyContent: 'space-between' }}
        >
          <UIFormTitle title="Listar Sangrias" />
          {collapseSearch ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={collapseSearch}
          timeout="auto"
          unmountOnExit
          style={{ width: '100%' }}
        >
          <>
            <Formik onSubmit={onSearch} initialValues={initialSearch}>
              {({ values, submitForm, setFieldValue }) => (
                <Form style={{ width: '100%' }} onChange={() => submitForm()}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={2} md={1}>
                      <Field
                        name="id"
                        label="ID"
                        placeholder="ID"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={10} md={3}>
                      <Autocomplete
                        clearOnEscape
                        noOptionsText="Sem opções"
                        disabled={usersInfo.loading}
                        options={usersInfo.data !== null ? usersInfo.data : []}
                        getOptionLabel={(user) => user.name}
                        onChange={(ev, user) => {
                          setFieldValue('approvedBy', user);
                          submitForm();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Aprovador por"
                            name="supplier"
                            placeholder="Filtrar pelo operador que aceitou"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Autocomplete
                        clearOnEscape
                        noOptionsText="Sem opções"
                        disabled={cashierInfo.loading}
                        options={
                          cashierInfo.data !== null ? cashierInfo.data : []
                        }
                        getOptionLabel={(cashier) => cashier.name}
                        onChange={(ev, cashier) => {
                          setFieldValue('sender', cashier);
                          submitForm();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Caixa origem"
                            placeholder="Filtrar pelo caixa origem"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Autocomplete
                        clearOnEscape
                        noOptionsText="Sem opções"
                        disabled={cashierInfo.loading}
                        options={
                          cashierInfo.data !== null ? cashierInfo.data : []
                        }
                        getOptionLabel={(cashier) => cashier.name}
                        onChange={(ev, cashier) => {
                          setFieldValue('receiver', cashier);
                          submitForm();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Caixa destino"
                            placeholder="Filtrar pelo caixa destino"
                          />
                        )}
                      />
                    </Grid>
                    <Hidden smDown>
                      <Grid item xs={6} md={2}>
                        <CurrencyTextField
                          type="tel"
                          value={values.value}
                          label="Valor solicitado"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          fullWidth
                          onChange={(ev, value) =>
                            setFieldValue('requestedValue', value)
                          }
                        />
                      </Grid>
                    </Hidden>
                    <UIFilter onClick={handleModalFilter} />
                    <Hidden smDown>
                      <UIButtonSearch xs={1} />
                    </Hidden>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Transferencias</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            label={values.closed ? 'Em aberto' : 'Fechada'}
                            labelPlacement="start"
                            control={
                              <Switch
                                color="primary"
                                checked={values.closed}
                                onChange={(ev) =>
                                  setFieldValue('closed', ev.target.checked)
                                }
                              />
                            }
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <UIModalFilter
                    open={filter}
                    changeModal={handleModalFilter}
                    submit={submitForm}
                  >
                    <Grid item xs={6}>
                      <Autocomplete
                        clearOnEscape
                        noOptionsText="Sem opções"
                        disabled={usersInfo.loading}
                        options={usersInfo.data !== null ? usersInfo.data : []}
                        getOptionLabel={(user) => user.name}
                        onChange={(ev, user) => {
                          setFieldValue('requestedBy', user);
                          submitForm();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Solicitador por"
                            name="supplier"
                            placeholder="Filtrar pelo operador solicitante"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        clearOnEscape
                        noOptionsText="Sem opções"
                        disabled={usersInfo.loading}
                        options={usersInfo.data !== null ? usersInfo.data : []}
                        getOptionLabel={(user) => user.name}
                        onChange={(ev, user) => {
                          setFieldValue('refusedBy', user);
                          submitForm();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Operador recusante"
                            name="supplier"
                            placeholder="Filtrar pelo operador que rejeitou"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CurrencyTextField
                        type="tel"
                        value={values.value}
                        label="Valor aprovado"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        fullWidth
                        onChange={(ev, value) =>
                          setFieldValue('approvedValue', value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="comment"
                        label="Descrição"
                        placeholder="Descrição da sangria"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyDateRangePicker
                        label="Data de fechamento"
                        submitForm={submitForm}
                        state={dateClosedValues}
                        setState={setDateClosedValues}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyDateRangePicker
                        label="Data requisição"
                        submitForm={submitForm}
                        state={dateRequestValues}
                        setState={setDateRequestValues}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Sangrias</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            label="Ver aceitas"
                            labelPlacement="start"
                            control={
                              <Checkbox
                                checked={values.isOpen}
                                onChange={(ev) =>
                                  setFieldValue('accept', ev.target.checked)
                                }
                              />
                            }
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <CurrencyTextField
                          type="tel"
                          value={values.value}
                          label="Valor solicitado"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          fullWidth
                          onChange={(ev, value) =>
                            setFieldValue('requestedValue', value)
                          }
                        />
                      </Grid>
                    </Hidden>
                  </UIModalFilter>
                </Form>
              )}
            </Formik>

            <Grid item xs style={{ marginTop: '15px' }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Caixa origem</TableCell>
                      <TableCell>Caixa destino</TableCell>
                      <TableCell>Operador requisitante</TableCell>
                      <TableCell>Valor solicitado</TableCell>
                      <TableCell>Requisitado em</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterInfo.loading && <CircularProgress />}
                    {filterInfo.data &&
                      usersInfo.data &&
                      filterInfo.data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((data) => (
                          <TableRow
                            onDoubleClick={() => handleModalView(data.id)}
                            onContextMenu={
                              !mobile
                                ? (ev) => handleClickRight(ev, data.id)
                                : undefined
                            }
                            style={{ cursor: 'context-menu' }}
                            hover
                            key={data.id}
                          >
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                              component="th"
                              scope="row"
                            >
                              {data.id}
                            </TableCell>
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                            >
                              {getName(cashierInfo, data.sender)}
                            </TableCell>
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                            >
                              {getName(cashierInfo, data.receiver)}
                            </TableCell>
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                            >
                              {getFullName(data.requestedBy)}
                            </TableCell>
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                            >
                              {formattedCurrency(data.requestedValue)}
                            </TableCell>
                            <TableCell
                              onClick={
                                mobile
                                  ? (ev) => handleClickLeft(ev, data.id)
                                  : undefined
                              }
                            >
                              {data.requestedAt}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                disabled={data.closed === '1'}
                                title="Aceitar"
                                onClick={() => handleModalAccept(data.id)}
                              >
                                <DoneIcon />
                              </IconButton>
                              <IconButton
                                disabled={data.closed === '1'}
                                title="Recusar"
                                onClick={() => handleModalRefuse(data.id)}
                              >
                                <CloseIcon />
                              </IconButton>
                              <IconButton
                                title="Visualizar"
                                onClick={() => handleModalView(data.id)}
                              >
                                <SearchIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    <Menu
                      keepMounted
                      open={clickRight.mouseY !== null}
                      onClose={handleCloseClickRight}
                      anchorReference="anchorPosition"
                      anchorPosition={
                        clickRight.mouseY !== null && clickRight.mouseX !== null
                          ? {
                              top: clickRight.mouseY,
                              left: clickRight.mouseX,
                            }
                          : undefined
                      }
                    >
                      <MenuItem onClick={() => handleModalView(null, true)}>
                        Visualizar
                      </MenuItem>
                      <MenuItem onClick={() => handleModalRefuse(null, true)}>
                        Recusar
                      </MenuItem>
                      <MenuItem onClick={() => handleModalAccept(null, true)}>
                        Aceitar
                      </MenuItem>
                    </Menu>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => handleCloseClickLeft()}
                    >
                      <MenuItem onClick={() => handleModalView(null, true)}>
                        Visualizar
                      </MenuItem>
                      <MenuItem onClick={() => handleModalRefuse(null, true)}>
                        Recusar
                      </MenuItem>
                      <MenuItem onClick={() => handleModalAccept(null, true)}>
                        Aceitar
                      </MenuItem>
                    </Menu>
                    {filterInfo.error && (
                      <TableRow>
                        <TableCell>Sem resultados encotrados</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <UITablePagination
                  count={filterInfo.data ? filterInfo.data.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </>
        </Collapse>
      </UIContainer>

      {modalValues && (
        <>
          <Dialog
            open={view}
            onClose={() => handleModalView()}
            onExit={() => setModalValues(null)}
          >
            <UIDialogTitle
              id="form-dialog-title"
              onClose={() => handleModalView()}
            >
              {`Transação #${modalValues.id}`}
            </UIDialogTitle>
            <DialogContent>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Caixa origem"
                    value={modalValues.sender}
                    fullWidth
                    className={disabledTextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Caixa destino"
                    value={modalValues.receiver}
                    fullWidth
                    className={disabledTextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Solicitador por"
                    value={modalValues.requestedBy}
                    fullWidth
                    className={disabledTextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Aprovador por"
                    value={modalValues.approvedBy}
                    fullWidth
                    className={disabledTextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Data requisição"
                    value={modalValues.requestedAt}
                    fullWidth
                    className={disabledTextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Data de fechamento"
                    value={modalValues.closedAt}
                    fullWidth
                    className={disabledTextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CurrencyTextField
                    type="tel"
                    value={modalValues.requestedValue}
                    label="Valor solicitado"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    className={disabledTextField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <CurrencyTextField
                    type="tel"
                    value={modalValues.approvedValue}
                    label="Valor aprovado"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    className={disabledTextField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Metodo pagamento"
                    value={modalValues.paymentMethod}
                    fullWidth
                    className={disabledTextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Observações"
                    value={modalValues.comment}
                    fullWidth
                    className={disabledTextField}
                  />
                </Grid>
                {modalValues.refusedBy && (
                  <Grid item xs={12}>
                    <TextField
                      label="Recusada por"
                      value={modalValues.refusedBy}
                      fullWidth
                      className={disabledTextField}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Status da sangria
                    </FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        label={
                          modalValues.accept === '1' ? 'Aceita' : 'Recusada'
                        }
                        labelPlacement="start"
                        control={
                          <Switch
                            color="primary"
                            checked={modalValues.accept === '1'}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          modalValues.closed === '1' ? 'Em aberto' : 'Fechada'
                        }
                        labelPlacement="start"
                        control={
                          <Switch
                            color="primary"
                            checked={modalValues.closed === '1'}
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleModalView()} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={modalAccept}
            onClose={() => handleModalAccept()}
            onExit={() => setModalValues(null)}
          >
            <UIDialogTitle id="alert-dialog-title">
              Deseja aceitar transferêcia do {modalValues.sender}?
            </UIDialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CurrencyTextField
                    type="tel"
                    value={modalValues.requestedValue}
                    label="Valor aprovado"
                    error={Number(modalValues.requestedValue) < 0}
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    onChange={(ev, value) =>
                      setModalValues({
                        ...modalValues,
                        requestedValue: value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Sangrias</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        label="Manter em aberto"
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={!modalValues.close}
                            onChange={(ev) =>
                              setModalValues({
                                ...modalValues,
                                close: !ev.target.checked,
                              })
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => handleModalAccept()}>
                Não
              </Button>
              <Button onClick={onAccept} color="primary" autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={modalRefuse}
            onClose={() => handleModalRefuse()}
            onExit={() => setModalValues(null)}
          >
            <UIDialogTitle id="alert-dialog-title">
              Deseja recusar transferêcia do {modalValues.sender}?
            </UIDialogTitle>
            <DialogActions>
              <Button color="primary" onClick={() => handleModalRefuse()}>
                Não
              </Button>
              <Button onClick={onRefuse} color="primary" autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <UIDialogConfirm
        open={alertAccept}
        close={() => setAlertAccept(!alertAccept)}
        data={acceptInfo}
        timeSucess={3000}
        messageSucess="Sangria aceita com sucesso!"
        setrequestinfo={setAcceptInfo}
      />
      <UIDialogConfirm
        open={alertRefuse}
        close={() => setAlertRefuse(!alertRefuse)}
        data={refuseInfo}
        timeSucess={3000}
        messageSucess="A Sangria foi recusada!"
        setrequestinfo={setRefuseInfo}
      />
    </>
  );
};

export default TransferSearch;
