import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  disabledTextField: {
    borderBottom: '0px solid #Bdbec0',
    '& label.Mui-focused': {
      color: '#Bdbec0',
    },
    '& input': {
      color: '#8c8c8c',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0.1px solid #Bdbec0',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0.1px solid #Bdbec0',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: '0px solid #Bdbec0',
    },
    '& :MuiInput-underline': {
      '& fieldset': {
        borderBottom: '0px solid #Bdbec0',
      },
    },
    '&:hover': {},
  },
});

export default useStyles;
