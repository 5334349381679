import { Typography } from '@material-ui/core';
import React from 'react';
import './UIFormTitle.css';

const UIFormTitle = ({ ...children }) => (
  <Typography paragraph noWrap className="UIFormTitle">
    {children.title}
  </Typography>
);

export default UIFormTitle;
