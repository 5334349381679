import React from 'react';
import CashierLogistic from '../../../components/Cashier/Logistic/cashierLogistic';
import PageContent from '../../../components/Main/Content/mainContent';

const CaixaLogistica = () => (
  <PageContent>
    <CashierLogistic />
  </PageContent>
);

export default CaixaLogistica;
