import React, { useState } from 'react';

import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { formattedCurrency } from '../../../utils/utils';
import UITablePagination from '../../UI/Table/Pagination/UITablePagination';

const StockTable = (children) => {
  const { data, loading, error, actualId, doubleClickAction } = children;

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>CMV</TableCell>
            <TableCell />
            <TableCell>Início</TableCell>
            <TableCell>Fim</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <CircularProgress />}
          {data &&
            data
              .map((value, _index) => (
                <TableRow
                  tabIndex={-1}
                  onDoubleClick={() => doubleClickAction(_index)}
                  style={{ cursor: 'context-menu', background: value.id === actualId? '#8ecae6': ''}}
                  hover
                  key={value.id}
                >
                  <TableCell component="th" scope="row">
                    {value.id}
                  </TableCell>
                  <TableCell>{formattedCurrency(value.value)}</TableCell>
                  <TableCell>{formattedCurrency(value.cmv)}</TableCell>
                  <TableCell />
                  <TableCell>{value.startDate}</TableCell>
                  <TableCell>{value.dueDate || 'Em aberto'}</TableCell>
                </TableRow>
              ))}
          {error && (
            <TableRow>
              <TableCell>Sem resultados encotrados</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <UITablePagination
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default StockTable;
