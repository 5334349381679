import React from 'react';
import PageContent from '../../../components/Main/Content/mainContent';
import UserSearch from '../../../components/User/Search/userSearch';

const UsuarioPesquisar = () => (
  <PageContent>
    <UserSearch />
  </PageContent>
);

export default UsuarioPesquisar;
