import {
  Divider,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect } from 'react';
import useApi from '../../../utils/useApi';
import { formattedCurrency } from '../../../utils/utils';

const SaleSelect = (children) => {
  const {
    options,
    selectClient,
    selectPromotion,
    selectPlots,
    selectInterest,
    changeSearchItens,
    inputBarCode,
    confirmSale,
    qntd,
    setQntd,
  } = children;

  const inputCustumer = document.getElementById('sale-new-select-customer');
  const inputPromotion = document.getElementById('sale-new-select-promotion');
  const inputInterest = document.getElementById('sale-new-select-interest');

  const [promotionsLoad, promotionsInfo] = useApi({
    method: 'get',
    url: '/promotions',
    params: { isActive: 1 },
  });

  const [clientLoad, clientInfo] = useApi({
    method: 'get',
    url: '/customers',
  });

  useEffect(() => {
    clientLoad();
    promotionsLoad();
    return () => {
      document.onkeyup = null;
    };
  }, []);

  document.onkeyup = (event) => {
    const { code, shiftKey } = event;
    if (shiftKey && code === 'KeyA') {
      inputBarCode.focus();
    }
    if (shiftKey && code === 'KeyD') {
      inputPromotion.focus();
    }
    if (code === 'ArrowUp') setQntd(qntd + 1);

    if (code === 'ArrowDown') {
      if (Number(qntd) > 1) setQntd(qntd - 1);
    }
    if (shiftKey && code === 'ArrowRight') {
      // verifica se está focado para nao executar onfocus denovo
      if (inputInterest !== document.activeElement) {
        inputInterest.focus();
      }
      selectInterest(options.interest - 1);
    }
    if (shiftKey && code === 'ArrowLeft') {
      if (inputInterest !== document.activeElement) {
        inputInterest.focus();
      }
      selectInterest(options.interest + 1);
    }
    if (shiftKey && code === 'KeyC') {
      inputCustumer.focus();
    }
    if (shiftKey && code === 'Space') {
      changeSearchItens();
    }
    if (shiftKey && code === 'Enter') {
      confirmSale();
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          disableClearable
          id="sale-new-select-customer"
          noOptionsText="Sem opções"
          disabled={clientInfo.loading}
          value={options.customer}
          options={clientInfo.data !== null ? clientInfo.data : []}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(client) => client.name}
          renderOption={(option) => (
            <>
              {option.name} {option.lastName || ''}
            </>
          )}
          onChange={(ev, value) => selectClient(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Selecionar cliente (Shift + C)"
              variant="standard"
              placeholder="Cliente não selecionado"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disableClearable
          id="sale-new-select-promotion"
          noOptionsText="Sem opções"
          value={options.promotion}
          getOptionSelected={(option, value) => option.id === value.id}
          disabled={promotionsInfo.loading}
          options={promotionsInfo.data !== null ? promotionsInfo.data : []}
          onChange={(ev, value) => selectPromotion(value)}
          getOptionLabel={(promotion) => promotion.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Aplicar promoção (Shift + D)"
              variant="standard"
              placeholder="Aplicar uma promoção a venda"
            />
          )}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            id="sale-new-select-plots"
            label="Parcelamento"
            value={options.plot}
            onChange={(ev) => selectPlots(ev.target.value)}
            onBlur={(ev) => {
              if (!ev.target.value || ev.target.value === '0') {
                selectPlots(1);
              }
            }}
            placeholder="Qnts parcelas"
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Aplicar juros %"
            id="sale-new-select-interest"
            value={options.interest}
            onChange={(ev) => selectInterest(ev.target.value)}
            onBlur={(ev) => {
              if (!ev.target.value || ev.target.value === '0') {
                selectInterest(0);
              }
            }}
            placeholder="Informe a quantidade de juros "
            type="number"
            helperText="Shift + Setas ⬅ ➡"
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: '5px 0' }} />
      <TableContainer>
        <Typography align="center" variant="subtitle2" component="div">
          Info do pedido
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Cliente</TableCell>
              <TableCell>
                {options.customer
                  ? `${options.customer.name} ${
                      options.customer.lastName || ''
                    }`
                  : 'Avulso'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pagamento</TableCell>
              <TableCell>{`Em ${
                options.plot > 0 ? options.plot : 1
              }x`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Acréscimo</TableCell>
              <TableCell>{formattedCurrency(options.increase || 0)}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </>
  );
};

export default SaleSelect;
