import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { useTheme } from '@material-ui/core/styles';
import { addDays, isEqual } from 'date-fns';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import useApi from '../../../utils/useApi';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import {
  emptyObject,
  formattedCurrency,
  goToReferenceId,
} from '../../../utils/utils';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import UIButtonSearch from '../../UI/Button/Search/Search';
import MyDateRangePicker from '../../UI/Form/DateRangePicker/DateRangePicker';
import useStyles from '../../../utils/useStyle';

const initialState = {
  id: '',
  startRange: '',
  endRange: '',
  referenceTable: 3,
  referenceId: '',
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const PaymentsView = (children) => {
  const { referenceId } = children;
  const { disabledTextField } = useStyles();

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // modais de pesquisa e filtragem
  const [view, setView] = useState(false);
  const [modalValues, setModalValues] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [dateValues, setDateValues] = useState({
    startDate: new Date(),
    endDate: null,
    key: 'selection',
  });

  // ação para click direito e esquerdo
  const [clickRight, setClickRight] = useState(initialClick);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [userLoad, userInfo] = useApi({
    method: 'get',
    url: '/users',
  });

  const [paymentLoad, paymentInfo] = useApi({
    method: 'get',
    url: '/payments/methods',
  });

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/payments',
  });

  const [cashierLoad] = useApi({
    method: 'get',
    url: '/cash-registers',
  });

  useEffect(() => {
    const params = { referenceId };
    filterLoad({ params });
    userLoad();
    paymentLoad();
    if (referenceId) {
      initialState.referenceId = referenceId;
    }
  }, []);

  function humanAttributes(values, isClick) {
    const userId = userInfo.data.find((value) => value.id === values.userId);
    cashierLoad({
      params: { id: values.cashRegister },
      onCompleted: (res) => {
        if (res) {
          setModalValues({
            ...values,
            paymentMethod: paymentInfo.data.find(
              (value) => value.id === values.paymentMethod,
            ).name,
            userId: `${userId.name} ${userId.lastName || ''}`,
            cashRegister: res.data[0].name,
          });
          if (!isClick) setView(!view);
        }
      },
    });
  }

  const handleClickRight = (event, values) => {
    event.preventDefault();
    humanAttributes(values, true);
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  const handleClickLeft = (event, values) => {
    humanAttributes(values, true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  function handleModalUpdate(values, isClick) {
    if (values) {
      humanAttributes(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
      setView(!view);
    }
  }

  function onSearch(values) {
    const searchValues = {
      ...values,
      startRange: dateValues.endDate && dateValues.startDate,
      endRange: isEqual(dateValues.endDate, dateValues.startDate)
        ? addDays(dateValues.endDate, 1)
        : dateValues.endDate,
    };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
    });
  }

  return (
    <>
      <Formik onSubmit={onSearch} initialValues={initialState}>
        {({ submitForm }) => (
          <Form onChange={() => submitForm()}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Filtrar pagamentos" />
              </Grid>
              <Grid item xs={1}>
                <Field
                  name="id"
                  label="ID"
                  placeholder="ID"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <MyDateRangePicker
                  label="Data"
                  submitForm={submitForm}
                  state={dateValues}
                  setState={setDateValues}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Field
                  name="referenceId"
                  label="Venda"
                  placeholder="Informe o ID da venda"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden smDown>
                <UIButtonSearch xs={1} />
              </Hidden>
            </UIContainer>
          </Form>
        )}
      </Formik>
      <UIContainer>
        <Grid item xs>
          <UIFormTitle title="Lista de pagamentos" />
        </Grid>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Metódo de pagamento</TableCell>
                  <TableCell>Efetivada</TableCell>
                  <TableCell>Vendedor</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterInfo.loading && <CircularProgress />}
                {filterInfo.data &&
                  userInfo.data &&
                  paymentInfo.data &&
                  filterInfo.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data) => (
                      <TableRow
                        tabIndex={-1}
                        onDoubleClick={() => handleModalUpdate(data)}
                        onContextMenu={
                          !mobile
                            ? (ev) => handleClickRight(ev, data)
                            : undefined
                        }
                        style={{ cursor: 'context-menu' }}
                        onClick={
                          mobile ? (ev) => handleClickLeft(ev, data) : undefined
                        }
                        hover
                        key={data.id}
                      >
                        <TableCell component="th" scope="row">
                          {data.id}
                        </TableCell>
                        <TableCell>{formattedCurrency(data.value)}</TableCell>
                        <TableCell>
                          {
                            paymentInfo.data.find(
                              (value) => value.id === data.paymentMethod,
                            ).name
                          }
                        </TableCell>
                        <TableCell>{data.done}</TableCell>
                        <TableCell>
                          {
                            userInfo.data.find(
                              (value) => value.id === data.userId,
                            ).name
                          }
                        </TableCell>
                        <TableCell>
                          <IconButton
                            title="Visualizar"
                            onClick={() => handleModalUpdate(data)}
                          >
                            <SearchIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                <Menu
                  keepMounted
                  open={clickRight.mouseY !== null}
                  onClose={handleCloseClickRight}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    clickRight.mouseY !== null && clickRight.mouseX !== null
                      ? {
                          top: clickRight.mouseY,
                          left: clickRight.mouseX,
                        }
                      : undefined
                  }
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar
                  </MenuItem>
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseClickLeft()}
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar
                  </MenuItem>
                </Menu>
                {filterInfo.error && (
                  <TableRow>
                    <TableCell>Sem resultados encotrados</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
              labelRowsPerPage="Linhas por página"
              colSpan={3}
              component="div"
              count={filterInfo.data ? filterInfo.data.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />
          </TableContainer>
        </Grid>
      </UIContainer>
      {modalValues && (
        <Dialog open={view} onClose={() => setView(false)} fullWidth>
          <UIDialogTitle id="form-dialog-title" onClose={() => setView(false)}>
            Pagamento #{modalValues.id}
          </UIDialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <TextField
                  value={modalValues.paymentMethod}
                  label="Metódo de pagamento"
                  fullWidth
                  className={disabledTextField}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={modalValues.userId}
                  label="Vendedor"
                  fullWidth
                  className={disabledTextField}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={modalValues.done}
                  label="Venda feita em"
                  fullWidth
                  className={disabledTextField}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={modalValues.cashRegister}
                  label="Nome do caixa"
                  fullWidth
                  className={disabledTextField}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <CurrencyTextField
                  type="tel"
                  value={modalValues.value}
                  label="Valor pagamento"
                  variant="standard"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={modalValues.referenceId}
                  label="ID da venda"
                  fullWidth
                  className={disabledTextField}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Link
                          to={() =>
                            goToReferenceId(
                              modalValues.referenceTable,
                              modalValues.referenceId,
                            )
                          }
                        >
                          <IconButton title="Ir para venda">
                            <CallMadeIcon />
                          </IconButton>
                        </Link>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setView(false)} color="primary">
              Sair
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PaymentsView;
