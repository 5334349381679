import React from 'react';
import { useParams } from 'react-router-dom';
import PageContent from '../../../components/Main/Content/mainContent';
// import SaleSearch from '../../../components/Sale/Search/saleSearch';
import SaleSessionShow from '../../../components/Sale/Sessions/Show/saleSessionShow';

const VendaComanda = () => {
  const { id } = useParams();

  return (
    <PageContent>
      <SaleSessionShow referenceId={id ? Number.parseInt(id, 10) : null} />
    </PageContent>
  );
};

export default VendaComanda;
