import React, { useState } from 'react';
import {
  TextField,
  Popover,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { format } from 'date-fns';
import deLocale from 'date-fns/locale/pt-BR';
import ClearIcon from '@material-ui/icons/Clear';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { isEqualObject } from '../../../../utils/utils';

const initialDate = {
  startDate: new Date(),
  endDate: null,
  key: 'selection',
};

const MyDateRangePicker = (children) => {
  const { state, setState, submitForm, label } = children;
  const formatter = 'dd/MM/yyyy';
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [inputValue, setInputValue] = useState('Selecione uma data');

  function changeValues(values) {
    setState(values);
    const { startDate, endDate } = values;
    if (!isEqualObject(values, initialDate)) {
      setInputValue(
        `${format(startDate, formatter)} - ${format(endDate, formatter)}`,
      );
    } else setInputValue('Selecione uma data');
    submitForm();
  }

  const handleClick = (event) => {
    const { name } = event.target;
    if (name === 'UIDaterangePicker__changePopover')
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <TextField
        onClick={handleClick}
        name="UIDaterangePicker__changePopover"
        label={label}
        value={inputValue}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => changeValues(initialDate)}>
                <ClearIcon />
              </IconButton>
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <div style={{ padding: '16px' }}>
          <DateRange
            locale={deLocale}
            editableDateInputs
            onChange={(item) => changeValues(item.selection)}
            moveRangeOnFirstSelection={false}
            ranges={[state]}
            maxDate={new Date()}
            showMonthAndYearPickers
            showDateDisplay={false}
            scroll={{ enabled: true }}
          />
        </div>
      </Popover>
    </>
  );
};

export default MyDateRangePicker;
