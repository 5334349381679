import React, { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Grid,
  CircularProgress,
  TextField,
  ListItem,
  Collapse,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import UIFormTitle from '../../../UI/Form/Title/UIFormTitle';
import UIContainer from '../../../UI/Container/UIContainer';
import UIButtonRefresh from '../../../UI/Button/Refresh/UIButtonRefresh';

import StoreContext from '../../../Store/context';

import useApi from '../../../../utils/useApi';
import CashierCard from '../../Card/cashierCard';
import UIDialogTitle from '../../../UI/Dialog/Title/UIDialogTitle';
import { emptyObject } from '../../../../utils/utils';
import UIDialogConfirm from '../../../UI/Dialog/Confirm/UIDialogConfirm';

const initialState = {
  id: '',
  name: '',
  userId: null,
};

const TransferNew = (children) => {
  const { collapseNew, changeCollapseNew, usersInfo, cashierInfo } = children;
  const { credencials } = useContext(StoreContext);

  const [modalTransfer, setModalTransfer] = useState(false);
  const [modalValues, setModalValues] = useState(false);
  const [cardValues, setCardValues] = useState(null);
  const [advancedSetting, setAdvancedSetting] = useState(false);
  const [alertTransfer, setAlertTransfer] = useState(false);
  const [search, setSearch] = useState({ isActive: 1 });

  const [filterCashierLoad, filterCashierInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/cash-registers',
    params: { isActive: 1, userId: Number(credencials.id) },
  });

  const [transferLoad, transferInfo, setTransferInfo] = useApi({
    method: 'post',
    url: '/cash-registers/transfers',
    params: { isActive: 1 },
  });

  function handleAdvancedSetting() {
    setAdvancedSetting(!advancedSetting);
  }

  function handleModalTransfer(values) {
    if (values) {
      const data = {
        name: values.name,
        sender: values.id,
        receiver: null,
        requestedValue: 0,
        approver: null,
        notification: '',
        comment: '',
        value: values.currentValue,
      };
      setModalValues(data);
    }
    if (modalValues) setModalValues(null);
    setModalTransfer(!modalTransfer);
    setAdvancedSetting(false);
  }

  function onSubmit(values) {
    setCardValues(null);
    const params = {
      ...values,
      userId: Number(credencials.id),
      isActive: 1,
    };
    filterCashierLoad({
      params: emptyObject(params),
      onCompleted: (res) => {
        setSearch(values);
        if (res) {
          const { data } = res;
          const newCardValues = data.map((value) => {
            const existsCard =
              cardValues && cardValues.find((val) => val.id === value.id);
            if (existsCard) {
              const response = { ...value, oldValue: existsCard.oldValue };
              return response;
            }
            const response = { ...value, oldValue: 0 };
            return response;
          });
          setCardValues(newCardValues);
        } else {
          setCardValues(null);
        }
      },
    });
  }

  function onTransfer(values) {
    const data = {
      sender: values.sender,
      receiver: values.receiver && values.receiver.id,
      requestedValue: values.requestedValue,
    };
    if (advancedSetting) {
      data.approver = values.approver && values.approver.id;
      data.notification = values.notification;
      data.comment = values.comment;
    }
    transferLoad({
      data: emptyObject(data),
      onCompleted: (res) => {
        setAlertTransfer(!alertTransfer);
        if (res) {
          onSubmit(search);
          handleModalTransfer();
        }
      },
    });
  }

  function setOldValues(id, currentValue) {
    const newOldValues = cardValues.find((value) => value.id === id);
    newOldValues.oldValue = currentValue;
    setCardValues(cardValues);
  }

  useEffect(() => {
    onSubmit(search);
  }, [collapseNew]);

  return (
    <>
      <UIContainer container direction="row">
        <ListItem
          button
          onClick={changeCollapseNew}
          style={{ justifyContent: 'space-between' }}
        >
          <UIFormTitle title="Nova Sangria" />
          {collapseNew ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={collapseNew}
          timeout="auto"
          unmountOnExit
          style={{ width: '100%' }}
        >
          <Formik
            enableReinitialize
            initialValues={initialState}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, submitForm }) => (
              <Form onChange={submitForm}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Field
                      name="id"
                      label="ID"
                      type="number"
                      placeholder="Informe o nome do caixa"
                      fullWidth
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={5} md={5}>
                    <Field
                      name="name"
                      label="Nome do caixa"
                      placeholder="Informe o nome do caixa"
                      fullWidth
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={5} md={5}>
                    <Autocomplete
                      clearOnEscape
                      noOptionsText="Sem opções"
                      disabled={usersInfo.loading}
                      options={usersInfo.data !== null ? usersInfo.data : []}
                      getOptionLabel={(user) => user.name}
                      onChange={(ev, user) => {
                        setFieldValue('userId', user);
                        submitForm();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Operador do caixa"
                          name="supplier"
                          placeholder="Informe o operador do caixa"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <UIButtonRefresh />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <Grid container spacing={1} justify="center">
            {filterCashierInfo.loading && <CircularProgress />}
            {cardValues &&
              cardValues.map((values) => (
                <Grid item xs={12} md={6} lg={4} key={values.id}>
                  <CashierCard
                    setOldValues={setOldValues}
                    data={values}
                    handleModalTransfer={handleModalTransfer}
                  />
                </Grid>
              ))}
          </Grid>
        </Collapse>
      </UIContainer>

      {modalValues && (
        <Dialog open={modalTransfer} onClose={handleModalTransfer} fullWidth>
          <Formik
            onSubmit={onTransfer}
            initialValues={modalValues}
            validationSchema={undefined}
          >
            {({ errors, setFieldValue }) => (
              <Form>
                <UIDialogTitle
                  id="form-dialog-title"
                  onClose={handleModalTransfer}
                >
                  {`Transferir montante ${modalValues.name} para`}
                </UIDialogTitle>
                <DialogContent>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12}>
                      <Autocomplete
                        clearOnEscape
                        noOptionsText="Sem opções"
                        disabled={cashierInfo.loading}
                        options={
                          cashierInfo.data !== null
                            ? cashierInfo.data.filter(
                                (cash) => cash.id !== modalValues.sender,
                              )
                            : []
                        }
                        getOptionLabel={(cashier) => cashier.name}
                        onChange={(ev, cashier) =>
                          setFieldValue('receiver', cashier)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Caixa destino"
                            name="supplier"
                            placeholder="Informe o caixa destino"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CurrencyTextField
                        type="tel"
                        value={modalValues.value}
                        error={Boolean(errors.value)}
                        helperText={errors.value}
                        label="Valor a transferir"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        onChange={(ev, value) =>
                          setFieldValue('requestedValue', value)
                        }
                        outputFormat="string"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{ cursor: 'pointer', color: '#5f6363' }}
                        variant="button"
                        onClick={handleAdvancedSetting}
                      >
                        {advancedSetting ? '-' : '+'} Opções avançadas
                      </Typography>
                    </Grid>
                    {advancedSetting && (
                      <>
                        <Grid item xs={12}>
                          <Autocomplete
                            clearOnEscape
                            noOptionsText="Sem opções"
                            disabled={usersInfo.loading}
                            options={
                              usersInfo.data !== null ? usersInfo.data : []
                            }
                            getOptionLabel={(user) => user.name}
                            onChange={(ev, user) =>
                              setFieldValue('approver', user)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Usuário a aprovar"
                                placeholder="Informe o usuário a aprovar"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="notification"
                            label="Nome da notificação"
                            placeholder="Informe o nome da notificação"
                            fullWidth
                            as={TextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="comment"
                            label="Observações"
                            placeholder="Informe observações da sangria"
                            fullWidth
                            as={TextField}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleModalTransfer} color="primary">
                    Cancelar
                  </Button>
                  <Button type="submit" color="primary">
                    Salvar
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}

      <UIDialogConfirm
        open={alertTransfer}
        close={() => setAlertTransfer(!alertTransfer)}
        data={transferInfo}
        messageSucess="Montante transferido com sucesso!"
        setrequestinfo={setTransferInfo}
      />
    </>
  );
};

export default TransferNew;
