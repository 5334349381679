import React from 'react';
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './slideNotifications.css';
import { useHistory } from 'react-router-dom';
import { goToNotificationsTable } from '../../../../utils/utils';

const SlideNotifications = (children) => {
  const {
    notification,
    expanded,
    toggleExpandedCollapse,
    isPair,
    handleClose,
    closeNotification,
  } = children;
  const history = useHistory();

  function goToPage(notificationObj) {
    const { type, id: idNotification, viewed } = notificationObj;
    const { id } = notification.message;
    const URL = goToNotificationsTable(type, id);
    history.push(URL);
    handleClose();
    closeNotification(idNotification, viewed);
    toggleExpandedCollapse(false);
  }

  return (
    <Grid
      className="main-base-slide-item"
      style={{ backgroundColor: isPair ? '#E6E7E7' : '#F9F9F9' }}
    >
      <MenuItem
        className="slide-notifications__title"
        key={notification.id}
        onClick={() => toggleExpandedCollapse(notification.id)}
      >
        {notification.information}
        <IconButton
          onClick={() => {
            closeNotification(notification.id, notification.viewed);
          }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </MenuItem>
      <Collapse in={expanded === notification.id} timeout="auto" unmountOnExit>
        <Grid
          container
          className="slide-notifications__text"
          onClick={() =>
            goToNotificationsTable(
              notification.type,
              notification.message.id,
            )
          }
        >
          <Typography variant="body2">{notification.information}</Typography>
          <Grid container className="slide-notifications__button">
            <Button
              size="small"
              color="primary"
              onClick={() => goToPage(notification)}
            >
              Ir para item
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default SlideNotifications;
