import { Card, Typography } from '@material-ui/core';
import React from 'react';

import './UICardSale.css';

const UICardSale = (children) => {
  const { name, onClick } = children;
  return (
    <Card className="UICardSale__body" onClick={onClick}>
      <Typography gutterBottom paragraph className="UICardSale__name">
        {name}
      </Typography>
    </Card>
  );
};

export default UICardSale;
