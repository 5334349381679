import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, Typography } from '@material-ui/core';

import { getHours } from 'date-fns';
import Carousel from 'react-material-ui-carousel';
import UIClock from '../UI/Clock/UIClock';
import UILogo from '../UI/Logo/UILogo';

import './homePage.css';
import useStorage from '../../utils/useStorage';
import StickyNotes from './Cards/stickyNotes';
import GoalCard from './Cards/goalCard';
import GoalTags from './Tags/goalTags';
import { formattedDateTime } from '../../utils/utils';
import ErrorNoAutentication from '../Error/NoAutentication/ErrorNoAutentication';
import StoreContext from '../Store/context';

const HomePage = () => {
  const [credencials] = useStorage('userAcess');
  const { openModal, setOpenModal } = useContext(StoreContext);
  const history = useHistory();
  const linkToPage = (route) => {
    if (history.location.pathname !== route) {
      history.push(route);
    }
  };
  function getTimeDay() {
    const currentHours = getHours(new Date());
    if (currentHours >= 0 && currentHours < 12) return 'Bom dia';
    if (currentHours >= 12 && currentHours < 18) return 'Boa tarde';
    return 'Boa noite';
  }

  return (
    <Grid container className="home-page">
      <Grid item xs={12} md={8}>
        {/* <Grid item xs={12} md={20}> */}
        <Grid container item xs className="home-page__info">
          <Typography variant="h5">
            {getTimeDay()},{' '}
            <span>
              {credencials.name} {credencials.lastName}{' '}
            </span>{' '}
            👋
          </Typography>
          <UILogo
            name={credencials.companyData?.pseudonimo?.split(' ')[0]}
            lastName={credencials.companyData?.pseudonimo
              ?.split(' ')
              .slice(1)
              .join('')}
            size={40}
          />
          <UIClock />
        </Grid>
      </Grid>
      <Grid className="home-page__sliders" item xs={12} md={4}>
        <Carousel
          navButtonsAlwaysInvisible
          interval={100000}
          animation="slide"
          indicatorContainerProps={{ style: { marginTop: 0 } }}
          className="home-page__sliders-carousel"
        >
          <Grid container direction="column" key={0}>
            <Typography className="sticky-notes__title">
              <UILogo name="Duzz" lastName="Commercial" size={25} />
            </Typography>
            <Grid container item xs={12}>
              <GoalTags title="Notas da atualização" iconColor="#DDB217" />
            </Grid>
            <Divider style={{ margin: '15px 0px' }} />
            <Typography className="sticky-notes__title">
              <UILogo name="Novo" lastName=" Relatório!" size={18} />
            </Typography>
            <Grid container key={1}>
              <GoalCard
                title=""
                subtitle="O relatório de visão geral agora está disponível na aba de gestão"
                actionText="Conferir"
                iconColor="#BDCCFF"
                onClick={() => linkToPage('/home/gestao/visaogeral')}
              />
            </Grid>
          </Grid>

          {/* <Grid container direction="column" key={1}>
            <Typography className="sticky-notes__title">
              Metas, bora lá !!!
            </Typography>
            <Grid container item xs={12}>
              <GoalTags iconColor="#131765" />
            </Grid>
            <Divider style={{ margin: '15px 0px' }} />
            <Typography className="sticky-notes__title">
              Metas recentes
            </Typography>
            <GoalCard iconColor="#A9FF74" />
          </Grid> */}

          <Grid key={2}>
            <Typography className="sticky-notes__title">Lembretes</Typography>
            <StickyNotes
              title="Duração da Licença"
              content={`${
                credencials.companyData?.pseudonimo
              }, a sua licença se encerra: ${formattedDateTime(
                credencials.companyData?.endTime,
              )}`}
              actionText="Renovar"
              href="https://api.whatsapp.com/send/?phone=5538998588893&text=Ola, gostaria de renovar a minha licença&type=phone_number&app_absent=0"
            />
          </Grid>
        </Carousel>
      </Grid>
    </Grid>
  );
};
export default HomePage;
