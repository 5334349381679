import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  Grid,
  TextField,
  ListItem,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { Autocomplete } from '@material-ui/lab';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import UIContainer from '../../UI/Container/UIContainer';

import useStorage from '../../../utils/useStorage';

import { allReferenceTable, isEqualObject } from '../../../utils/utils';
import useApi from '../../../utils/useApi';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIModalOldForm from '../../UI/ModalOldForm/UIModalOldForm';
import validationSchema from './validationSchema';

const initialState = {
  name: '',
  description: '',
  value: '',
  metadata: {
    referenceTable: 5,
    referenceId: '',
    type: false,
    recurrent: {
      activate: false,
      frequency: null,
    },
    supplier: null,
  },
};

const CostsNew = (children) => {
  const { collapseNew, changeCollapseNew, suppliersInfo } = children;
  const [open, setOpen] = useState(false);
  const [oldValues, setOldValues] = useStorage('formCosts');
  const [alertList, setAlertList] = useState(false);
  const [advancedSetting, setAdvancedSetting] = useState(false);

  const [registerLoad, registerInfo, setRegisterInfo] = useApi({
    method: 'post',
    url: '/costs',
    onCompleted: () => {
      setAlertList(true);
    },
  });

  function changeModal(isContinue) {
    setOpen(!open);
    if (isContinue) {
      changeCollapseNew();
    }
  }

  function updateStorage(values) {
    setOldValues(values);
  }

  function setFormValues() {
    setOldValues(null);
    setRegisterInfo();
    changeModal();
  }

  useEffect(() => {
    if (oldValues && !isEqualObject(oldValues, initialState)) {
      changeModal();
    }
  }, []);

  function onSubmit(values) {
    const data = { ...values };
    if (!advancedSetting) {
      delete data.metadata;
    } else {
      data.metadata.supplier = data.metadata.supplier
        ? data.metadata.supplier.id
        : '1';
      data.metadata.referenceId = data.metadata.referenceId || 'self';
      data.metadata.type = data.metadata.type ? 'MULTI' : 'SINGLE';
    }
    registerLoad({
      data,
      onCompleted: (res) => {
        if (res) setOldValues(null);
      },
    });
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={oldValues || initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, setFieldValue, submitForm }) => (
          <Form onBlur={() => updateStorage(values)}>
            <UIContainer container direction="row">
              <ListItem
                button
                onClick={changeCollapseNew}
                style={{ justifyContent: 'space-between' }}
              >
                <UIFormTitle title="Novo Custo" />
                {collapseNew ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={collapseNew}
                timeout="auto"
                unmountOnExit
                style={{ width: '100%' }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="name"
                      label="Nome do custo"
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      placeholder="Informe o nome do custo"
                      fullWidth
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={8} md={4}>
                    <Field
                      name="description"
                      label="Descrição do custo"
                      placeholder="Informe a descrição do custo"
                      fullWidth
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CurrencyTextField
                      type="tel"
                      value={values.value}
                      label="Valor do custo"
                      error={Boolean(errors.value)}
                      helperText={errors.value}
                      decimalCharacter=","
                      digitGroupSeparator="."
                      currencySymbol="R$"
                      outputFormat="string"
                      fullWidth
                      onChange={(ev, value) => setFieldValue('value', value)}
                    />
                  </Grid>
                  {advancedSetting && (
                    <>
                      <Grid item xs={12} md={2}>
                        <FormControl fullWidth>
                          <InputLabel shrink id="bills-to-pay__paymentMethod">
                            Tabela de refêrencia
                          </InputLabel>
                          <Select
                            value={values.metadata.referenceTable}
                            onChange={(ev) =>
                              setFieldValue(
                                'metadata.referenceTable',
                                ev.target.value,
                              )
                            }
                            labelId="bills-to-pay__paymentMethod"
                            displayEmpty
                          >
                            <MenuItem disabled key={0} value={0}>
                              Selecione a referencia
                            </MenuItem>
                            {allReferenceTable.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Field
                          name="metadata.referenceId"
                          label="ID do item"
                          placeholder="Informe o ID de referencia"
                          type="number"
                          fullWidth
                          as={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Autocomplete
                          disableClearable
                          noOptionsText="Sem opções"
                          disabled={suppliersInfo.loading}
                          options={
                            suppliersInfo.data !== null
                              ? suppliersInfo.data
                              : []
                          }
                          getOptionLabel={(supplier) => supplier.name}
                          onChange={(ev, supplier) =>
                            setFieldValue('metadata.supplier', supplier)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Fornecedor"
                              name="supplier"
                              variant="standard"
                              placeholder="Informe o fornecedor do custo"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Definição de pagamento
                          </FormLabel>
                          <FormControlLabel
                            label={
                              values.metadata.type
                                ? 'Custo aberto'
                                : 'Custo unico'
                            }
                            labelPlacement="start"
                            control={
                              <Switch
                                color="primary"
                                checked={values.metadata.type}
                                onChange={(ev) =>
                                  setFieldValue(
                                    'metadata.type',
                                    ev.target.checked,
                                  )
                                }
                              />
                            }
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Typography
                      style={{ cursor: 'pointer', color: '#5f6363' }}
                      variant="button"
                      onClick={() => setAdvancedSetting(!advancedSetting)}
                    >
                      {advancedSetting ? '-' : '+'} Configuração avançada
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-end" item xs={12}>
                    <Button
                      variant="contained"
                      className="UIFormContainerSave__buttons-canceled"
                      type="reset"
                      onClick={() => setOldValues(initialState)}
                      style={{ marginRight: '25px' }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={submitForm}
                      className="UIFormContainerSave__buttons-saved"
                      variant="contained"
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </UIContainer>
          </Form>
        )}
      </Formik>
      <UIDialogConfirm
        open={alertList}
        close={() => setAlertList(!alertList)}
        data={registerInfo}
        timeSucess={3000}
        messageSucess="Custo registrado com sucesso"
        setrequestinfo={setRegisterInfo}
      />
      <UIModalOldForm
        open={open}
        changeModal={() => changeModal(true)}
        setFormValues={setFormValues}
      />
    </>
  );
};

export default CostsNew;
