import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import useApi from '../../../utils/useApi';
import validationSchema from './validationSchema';
import UIButtonSearch from '../../UI/Button/Search/Search';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import {
  emptyObject,
  capitalize,
  clearObject,
  formattedCurrency,
  isEqualObject,
  calculateMarkupByValue,
  calculateMarginByValue,
  calculateValueByMargin,
} from '../../../utils/utils';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import UITablePagination from '../../UI/Table/Pagination/UITablePagination';

const initialState = {
  id: '',
  name: '',
  category: '',
  particulars: '',
  cost: '',
  value: '',
  amount: '',
  barCode: '',
  supplier: '',
  lacking: 0,
  margin: 0,
  markup: 0,
};

const addAmount = {
  id: null,
  amount: 1,
  cost: 0,
  costTotal: 0,
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const ProductSearch = (children) => {
  const { referenceId } = children;

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // mensagem de confirmação para operações
  const [alert, setAlert] = useState(false);

  // modais de pesquisa e filtragem
  const [view, setView] = useState(!!referenceId);
  const [filter, setFilter] = useState(false);
  const [modalValues, setModalValues] = useState(null);
  // adicionar quantidade ao produto
  const [amount, setAmount] = useState(addAmount);
  const [addAmountModal, setAddAmountModal] = useState(false);

  // salvar valores do form de pesquisa
  const [search, setSearch] = useState(null);

  // paginação
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // provider do click do lado direito
  const [clickRight, setClickRight] = useState(initialClick);
  const handleClickRight = (event, values) => {
    event.preventDefault();
    setModalValues({
      ...initialState,
      ...values,
      particulars: values.particulars,
    });
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };
  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickLeft = (event, values) => {
    setModalValues({
      ...initialState,
      ...values,
      particulars: values.particulars,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  // chamadas á API
  const [categoryLoad, categoryInfo] = useApi({
    method: 'get',
    url: '/products/categories',
  });

  const [suppliersLoad, suppliersInfo] = useApi({
    method: 'get',
    url: '/suppliers',
  });

  const [filterLoad, filterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/products',
  });

  const [updateLoad, updateInfo, setUpdateInfo] = useApi({
    method: 'put',
    url: '/products',
    onCompleted: (response) => {
      if (response) {
        filterLoad({
          params: search,
        });
      }
    },
  });

  const [addAmountLoad, addAmountInfo, setAddAmountInfo] = useApi({
    method: 'patch',
    url: '/products',
    onCompleted: () => {
      filterLoad({
        params: search,
      });
    },
  });

  useEffect(() => {
    if (referenceId) initialState.id = referenceId;
    const params = referenceId ? { id: referenceId } : '';
    filterLoad({
      params,
      onCompleted: (res) => {
        if (res && referenceId) {
          const [data] = res.data;
          setModalValues({
            ...initialState,
            ...data,
            particulars: data.particulars,
          });
        }
      },
    });
    suppliersLoad();
    categoryLoad({
      params: { isActive: 1 },
    });
  }, []);

  function handleModalFilter() {
    setFilter(!filter);
  }

  function handleModalAmount(values, isClick) {
    if (!addAmountModal && values) {
      setAmount({
        ...addAmount,
        id: values.id,
        cost: values.cost,
        costTotal: values.cost,
      });
    } else setAmount(addAmount);
    if (isClick) {
      setAmount({ ...addAmount, id: modalValues.id, cost: modalValues.cost });
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setAddAmountModal(!addAmountModal);
  }

  function handleModalUpdate(values, isClick) {
    if (values) {
      setModalValues({
        ...initialState,
        ...values,
        particulars: values.particulars,
      });
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function closeAlert(event, reason) {
    setAddAmountInfo();
    setUpdateInfo();
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  }

  function onSubmit(values) {
    const searchValues = {
      ...values,
      lacking: values.lacking === true ? 1 : 0,
      category: values.category ? Number(values.category.id) : '',
      supplier: values.supplier ? Number(values.supplier.id) : '',
    };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
    });
    setSearch(emptyObject(searchValues));
  }

  function onUpdate(values) {
    const updateValues = {
      id: values.id,
      name: values.name,
      barCode: values.barCode,
      category: Number(values.category),
      particulars: values.particulars,
      value: values.value,
      supplier: values.supplier.id,
    };
    updateLoad({
      data: updateValues,
    });
    setView(!view);
    setAlert(true);
  }

  function onAddAmount() {
    const data = {
      id: amount.id,
      amount: amount.amount,
      cost: amount.cost,
    };
    addAmountLoad({
      data,
    });
    setAddAmountModal(!addAmountModal);
    setAlert(true);
  }

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialState}>
        {({ values, submitForm, setFieldValue }) => (
          <Form onChange={() => submitForm()}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Filtrar produtos" />
                <UIFilter onClick={handleModalFilter} />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} md={1}>
                  <Field
                    name="id"
                    label="ID"
                    placeholder="ID"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={6} md={2}>
                <Field
                  name="name"
                  label="Nome"
                  placeholder="Nome do produto"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <Autocomplete
                  clearOnEscape
                  noOptionsText="Sem opções"
                  disabled={categoryInfo.loading}
                  options={categoryInfo.data !== null ? categoryInfo.data : []}
                  getOptionLabel={(category) => category.name}
                  onChange={(event, category) => {
                    setFieldValue('category', category);
                    submitForm();
                  }}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      label="Categoria"
                      name="category"
                      variant="standard"
                      placeholder="Informe a categoria do produto"
                      component={TextField}
                    />
                  )}
                />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} md={2}>
                  <Field
                    name="barCode"
                    label="Código produto"
                    placeholder="Código do produto"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item xs={12} md={2}>
                  <CurrencyTextField
                    type="tel"
                    value={values.value}
                    label="Valor de venda"
                    variant="standard"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    onChange={(ev, value) => setFieldValue('value', value)}
                    fullWidth
                  />
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item xs={12} md={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Ver produtos</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        label="Em falta"
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={values.lacking}
                            onChange={(ev) =>
                              setFieldValue('lacking', ev.target.checked)
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <UIButtonSearch xs={1} />
              </Hidden>
            </UIContainer>

            <UIModalFilter
              open={filter}
              changeModal={handleModalFilter}
              submit={submitForm}
            >
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Field
                    name="id"
                    label="ID"
                    placeholder="ID"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12}>
                <Autocomplete
                  clearOnEscape
                  noOptionsText="Sem opções"
                  value={values.supplier}
                  disabled={suppliersInfo.loading}
                  options={
                    suppliersInfo.data !== null ? suppliersInfo.data : []
                  }
                  getOptionLabel={(supplier) => supplier.name}
                  onChange={(event, supplier) => {
                    setFieldValue('supplier', supplier);
                  }}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      label="Fornecedor"
                      name="supplier"
                      variant="standard"
                      placeholder="Informe o fornecedor do produto"
                      component={TextField}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyTextField
                  decimalPlaces={5}
                  value={values.cost}
                  label="Custo do produto"
                  variant="standard"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  onChange={(ev, value) => setFieldValue('cost', value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="particulars"
                  label="Caracteristica do produto"
                  placeholder="Informe a caracteristica do produto"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="amount"
                  label="Quantidade do produto"
                  type="number"
                  placeholder="Informe a quantidade"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Field
                    name="barCode"
                    label="Código produto"
                    placeholder="Código do produto"
                    fullWidth
                    as={TextField}
                  />
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <CurrencyTextField
                    type="tel"
                    value={values.value}
                    label="Valor de venda"
                    variant="standard"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    onChange={(ev, value) => setFieldValue('value', value)}
                    fullWidth
                  />
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Ver produtos</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        label="Em falta"
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={values.lacking}
                            onChange={(ev) =>
                              setFieldValue('lacking', ev.target.checked)
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Hidden>
            </UIModalFilter>
          </Form>
        )}
      </Formik>
      <UIContainer>
        <Grid item xs>
          <UIFormTitle title="Lista de produtos" />
        </Grid>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Categoria</TableCell>
                  <TableCell>Valor de custo</TableCell>
                  <TableCell>Valor de venda</TableCell>
                  <TableCell>% de Markup</TableCell>
                  <TableCell
                    style={{ backgroundColor: 'yellow', color: 'black' }}
                  >
                    % de Margem
                  </TableCell>
                  <TableCell>Qntd.</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterInfo.loading && <CircularProgress />}
                {filterInfo.data &&
                  categoryInfo.data &&
                  filterInfo.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data) => (
                      <TableRow
                        onDoubleClick={() => handleModalUpdate(data)}
                        onContextMenu={
                          !mobile
                            ? (ev) => handleClickRight(ev, data)
                            : undefined
                        }
                        style={{ cursor: 'context-menu' }}
                        onClick={
                          mobile ? (ev) => handleClickLeft(ev, data) : undefined
                        }
                        hover
                        key={data.id}
                      >
                        <TableCell
                          style={
                            data.lacking === '1'
                              ? { backgroundColor: '#e63946', color: 'white' }
                              : {}
                          }
                          component="th"
                          scope="row"
                        >
                          {data.id}
                        </TableCell>
                        <TableCell
                          style={
                            data.lacking === '1'
                              ? { backgroundColor: '#e63946', color: 'white' }
                              : {}
                          }
                        >
                          {data.name}
                        </TableCell>
                        <TableCell
                          style={
                            data.lacking === '1'
                              ? { backgroundColor: '#e63946', color: 'white' }
                              : {}
                          }
                        >
                          {
                            categoryInfo.data.find(
                              (item) => item.id === data.category,
                            ).name
                          }
                        </TableCell>
                        <TableCell
                          style={
                            data.lacking === '1'
                              ? { backgroundColor: '#e63946', color: 'white' }
                              : {}
                          }
                        >
                          {formattedCurrency(data.cost)}
                        </TableCell>
                        <TableCell
                          style={
                            data.lacking === '1'
                              ? { backgroundColor: '#e63946', color: 'white' }
                              : {}
                          }
                        >
                          {formattedCurrency(data.value)}
                        </TableCell>
                        <TableCell
                          style={
                            data.lacking === '1'
                              ? { backgroundColor: '#e63946', color: 'white' }
                              : {}
                          }
                        >
                          {calculateMarkupByValue(data.cost, data.value)} %
                        </TableCell>
                        <TableCell
                          style={
                            data.lacking === '1'
                              ? { backgroundColor: '#e63946', color: 'white' }
                              : { backgroundColor: 'yellow', color: 'black' }
                          }
                        >
                          {calculateMarginByValue(data.cost, data.value)} %
                        </TableCell>
                        <TableCell
                          style={
                            data.lacking === '1'
                              ? { backgroundColor: '#e63946', color: 'white' }
                              : {}
                          }
                        >
                          {data.amount}
                        </TableCell>
                        <TableCell>
                          <span title="Mais detalhes">
                            <IconButton onClick={() => handleModalUpdate(data)}>
                              <SearchIcon />
                            </IconButton>
                          </span>
                          <span title="Adicionar quantidade">
                            <IconButton onClick={() => handleModalAmount(data)}>
                              <AddCircleIcon />
                            </IconButton>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                <Menu
                  keepMounted
                  open={clickRight.mouseY !== null}
                  onClose={handleCloseClickRight}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    clickRight.mouseY !== null && clickRight.mouseX !== null
                      ? {
                          top: clickRight.mouseY,
                          left: clickRight.mouseX,
                        }
                      : undefined
                  }
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  <MenuItem onClick={() => handleModalAmount(null, true)}>
                    Adicionar ao produto
                  </MenuItem>
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseClickLeft()}
                >
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar/Editar
                  </MenuItem>
                  <MenuItem onClick={() => handleModalAmount(null, true)}>
                    Adicionar ao produto
                  </MenuItem>
                </Menu>
                {filterInfo.error && (
                  <TableRow>
                    <TableCell>Sem resultados encotrados</TableCell>
                  </TableRow>
                )}
                <Dialog
                  open={addAmountModal}
                  onClose={() => handleModalAmount()}
                  fullWidth
                >
                  <UIDialogTitle
                    id="form-dialog-title"
                    onClose={() => handleModalAmount()}
                  >
                    Adicionar quantidade ao produto
                  </UIDialogTitle>
                  <DialogContent>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="space-between"
                      className="UIModalFilter__content"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="amount"
                          label="Quantidade do produto"
                          value={amount.amount}
                          onChange={(ev) => {
                            if (ev.target.value > 0) {
                              setAmount({
                                ...amount,
                                amount: ev.target.value,
                                costTotal: ev.target.value * amount.cost,
                              });
                            }
                          }}
                          type="number"
                          error={!Number.isInteger(Number(amount.amount))}
                          helperText={
                            !Number.isInteger(Number(amount.amount)) &&
                            'Digite um número inteiro'
                          }
                          placeholder="Informe a quantidade"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CurrencyTextField
                          type="tel"
                          value={amount.cost}
                          label="Custo unitário"
                          variant="standard"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          onChange={(ev, value) => {
                            if (value >= 0) {
                              setAmount({
                                ...amount,
                                cost: value,
                                costTotal: value * amount.amount,
                              });
                            }
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CurrencyTextField
                          type="tel"
                          value={amount.costTotal}
                          label="Adicionado ao estoque"
                          variant="standard"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          currencySymbol="R$"
                          outputFormat="string"
                          onChange={(ev, value) => {
                            if (value >= 0) {
                              setAmount({
                                ...amount,
                                costTotal: value,
                                cost: value / amount.amount,
                              });
                            }
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleModalAmount()}>
                      Cancelar
                    </Button>
                    <Button
                      disabled={!Number.isInteger(Number(amount.amount))}
                      onClick={onAddAmount}
                    >
                      Adicionar
                    </Button>
                  </DialogActions>
                </Dialog>
              </TableBody>
            </Table>
            <UITablePagination
              count={filterInfo.data ? filterInfo.data.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </UIContainer>
      {modalValues && categoryInfo.data && suppliersInfo.data && (
        <Dialog
          open={view}
          onClose={() => {
            handleModalUpdate();
            setModalValues(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <Formik
            enableReinitialize
            onSubmit={onUpdate}
            initialValues={modalValues}
            validationSchema={validationSchema}
          >
            {({ values, errors, setFieldValue, submitForm }) => (
              <Form>
                <UIDialogTitle
                  id="form-dialog-title"
                  onClose={() => handleModalUpdate()}
                >
                  {modalValues.name}
                </UIDialogTitle>
                <DialogContent>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="name"
                        label="Nome"
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        placeholder="Nome do produto"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableClearable
                        noOptionsText="Sem opções"
                        value={categoryInfo.data.find(
                          (item) => item.id === values.category,
                        )}
                        disabled={categoryInfo.loading}
                        options={
                          categoryInfo.data !== null ? categoryInfo.data : []
                        }
                        getOptionLabel={(category) => category.name}
                        onChange={(event, category) => {
                          setFieldValue('category', category && category.id);
                          if (event.target.value !== '') {
                            setFieldValue(
                              'particulars',
                              clearObject(category.particulars, true),
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <Field
                            {...params}
                            label="Categoria"
                            name="category"
                            variant="standard"
                            placeholder="Informe a categoria do produto"
                            component={TextField}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableClearable
                        noOptionsText="Sem opções"
                        value={suppliersInfo.data.find(
                          (item) => item.id === values.supplier,
                        )}
                        options={
                          suppliersInfo.data !== null ? suppliersInfo.data : []
                        }
                        getOptionLabel={(supplier) => supplier.name}
                        onChange={(event, supplier) => {
                          setFieldValue('supplier', supplier.id);
                        }}
                        renderInput={(params) => (
                          <Field
                            {...params}
                            label="Fornecedor"
                            name="supplier"
                            variant="standard"
                            placeholder="Informe o fornecedor do produto"
                            component={TextField}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        name="barCode"
                        label="Codigo de produto"
                        placeholder="Código do produto"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        disabled
                        name="amount"
                        label="Quantidade do produto"
                        placeholder="Nome do produto"
                        fullWidth
                        as={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CurrencyTextField
                        decimalPlaces={5}
                        disabled
                        value={values.cost}
                        label="Custo do produto"
                        variant="standard"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CurrencyTextField
                        type="tel"
                        value={calculateMarkupByValue(
                          values.cost,
                          values.value,
                        )}
                        label="Markup %"
                        variant="filled"
                        error={Boolean(errors.value)}
                        helperText={errors.value}
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="%"
                        outputFormat="string"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CurrencyTextField
                        type="tel"
                        value={calculateMarginByValue(
                          values.cost,
                          values.value,
                        )}
                        label="Margem %"
                        variant="standard"
                        error={Boolean(errors.value)}
                        helperText={errors.value}
                        onChange={(ev, value) =>
                          setFieldValue('margin', value) &&
                          setFieldValue(
                            'value',
                            calculateValueByMargin(values.cost, value),
                          )
                        }
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="%"
                        outputFormat="string"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CurrencyTextField
                        type="tel"
                        value={calculateValueByMargin(
                          values.cost,
                          values.margin,
                        )}
                        label="Valor de venda"
                        variant="standard"
                        error={Boolean(errors.value)}
                        helperText={errors.value}
                        onChange={(ev, value) => setFieldValue('value', value)}
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        fullWidth
                      />
                    </Grid>
                    {values.particulars &&
                      categoryInfo.data &&
                      Object.entries(
                        categoryInfo.data.find(
                          (item) => item.id === values.category,
                        ).particulars,
                      ).map(([key, value]) => (
                        <Grid item xs={12} md={4}>
                          <InputLabel shrink id={key}>
                            {capitalize(key)}
                          </InputLabel>
                          <Field
                            name={`particulars.${key}`}
                            disabled={!values.category}
                            labelId={key}
                            displayEmpty
                            fullWidth
                            as={Select}
                          >
                            <MenuItem disabled value="">
                              <em>Informe o(a) {key}</em>
                            </MenuItem>
                            {typeof value === 'object'
                              ? value.map((vl) => (
                                  <MenuItem key={vl} value={vl}>
                                    {vl}
                                  </MenuItem>
                                ))
                              : value && (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                )}
                          </Field>
                        </Grid>
                      ))}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {!isEqualObject(values, modalValues) && (
                    <Button onClick={() => handleModalUpdate()} color="primary">
                      Cancelar
                    </Button>
                  )}
                  <Button
                    onClick={
                      isEqualObject(values, modalValues)
                        ? () => handleModalUpdate()
                        : () => submitForm()
                    }
                    color="primary"
                  >
                    {isEqualObject(values, modalValues) ? 'Fechar' : 'Salvar'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={addAmountInfo}
        setrequestinfo={closeAlert}
        messageSucess="Operação feita com sucesso"
        type="success"
      />
      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={updateInfo}
        setrequestinfo={closeAlert}
        messageSucess={
          updateInfo.data
            ? updateInfo.data.message
            : 'Produto atualizado com sucesso'
        }
        type="success"
      />
    </>
  );
};

export default ProductSearch;
