import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { Autocomplete } from '@material-ui/lab';
import useApi from '../../../utils/useApi';
import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import validationSchema from './validationSchema';
import {
  calculateMarginByValue,
  calculateMarkupByValue,
  calculateValueByMargin,
  capitalize,
  clearObject,
  isEqualObject,
} from '../../../utils/utils';
import UIFormContainerSave from '../../UI/Form/ContainerSave/UIFormContainer';
import UIModalOldForm from '../../UI/ModalOldForm/UIModalOldForm';
import useStorage from '../../../utils/useStorage';

const initialState = {
  name: '',
  category: null,
  supplier: null,
  cost: 0,
  value: 0,
  barCode: '',
  particulars: null,
  amount: 0,
  margin: 0,
  markup: 0,
};

const ProductNew = () => {
  const [oldValues, setOldValues] = useStorage('formProduct');
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [options, setOptions] = useState(null);
  const [costTotal, setCostTotal] = useState(0);

  const [registerLoad, registerInfo, setRegisterInfo] = useApi({
    method: 'post',
    url: '/products',
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [categoryLoad, categoryInfo] = useApi({
    method: 'get',
    url: '/products/categories',
  });

  const [suppliersLoad, suppliersInfo] = useApi({
    method: 'get',
    url: '/suppliers',
  });

  function changeModal() {
    setOpen(!open);
  }

  function setFormValues() {
    setOldValues(null);
    setOptions(null);
    changeModal();
  }

  function updateStorage(values) {
    setOldValues(values);
  }

  useEffect(() => {
    categoryLoad({ params: { isActive: 1 } });
    suppliersLoad();
    if (oldValues && !isEqualObject(oldValues, initialState)) {
      changeModal();
    }
  }, []);

  function onSubmit(values) {
    const valuesProduct = {
      ...values,
      category: values.category.id,
      supplier: values.supplier.id,
    };
    delete valuesProduct.margin;
    delete valuesProduct.markup;
    registerLoad({
      data: valuesProduct,
    });
  }

  return (
    <>
      <Formik
        initialValues={oldValues || initialState}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form onBlur={() => updateStorage(values)}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Novo produto" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name="name"
                  label="Nome"
                  error={Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  placeholder="Informe o nome do produto"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disableClearable
                  noOptionsText="Sem opções"
                  value={values.category}
                  disabled={categoryInfo.loading}
                  options={categoryInfo.data !== null ? categoryInfo.data : []}
                  getOptionLabel={(category) => category.name}
                  onChange={(event, category) => {
                    if (event.target.value !== '') {
                      setFieldValue('category', category);
                      setFieldValue(
                        'particulars',
                        clearObject(category.particulars, true),
                      );
                      setOptions({
                        ...category.particulars,
                      });
                    } else {
                      setFieldValue('category', null);
                      setFieldValue('particulars', null);
                      setOptions(null);
                    }
                  }}
                  defaultValue={
                    oldValues !== null && oldValues.category
                      ? oldValues.category
                      : false
                  }
                  renderInput={(params) => (
                    <Field
                      {...params}
                      label="Categoria"
                      name="category"
                      error={Boolean(errors.category)}
                      helperText={touched.category && errors.category}
                      variant="standard"
                      placeholder="Informe a categoria do produto"
                      component={TextField}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disableClearable
                  noOptionsText="Sem opções"
                  value={values.supplier}
                  disabled={suppliersInfo.loading}
                  options={
                    suppliersInfo.data !== null ? suppliersInfo.data : []
                  }
                  getOptionLabel={(supplier) => supplier.name}
                  onChange={(event, supplier) => {
                    setFieldValue('supplier', supplier);
                  }}
                  defaultValue={
                    oldValues !== null && oldValues.supplier
                      ? oldValues.supplier
                      : false
                  }
                  renderInput={(params) => (
                    <Field
                      {...params}
                      label="Fornecedor"
                      name="supplier"
                      error={Boolean(errors.supplier)}
                      helperText={touched.supplier && errors.supplier}
                      variant="standard"
                      placeholder="Informe o fornecedor do produto"
                      component={TextField}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="amount"
                  label="Quantidade do produto"
                  type="number"
                  error={Boolean(errors.amount)}
                  helperText={touched.amount && errors.amount}
                  placeholder="Informe a quantidade"
                  onChange={(ev) => {
                    setFieldValue('amount', ev.target.value);
                    setCostTotal(ev.target.value * values.cost);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CurrencyTextField
                  decimalPlaces={5}
                  value={values.cost}
                  label="Custo unitário"
                  variant="standard"
                  decimalCharacter=","
                  error={Boolean(errors.cost)}
                  helperText={touched.cost && errors.cost}
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  onChange={(ev, value) => {
                    setFieldValue('cost', value);
                    setCostTotal(value * values.amount);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CurrencyTextField
                  decimalPlaces={5}
                  value={costTotal}
                  label="Custo do estoque"
                  variant="standard"
                  decimalCharacter=","
                  error={Boolean(errors.value)}
                  helperText={errors.value}
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  onChange={(ev, value) => {
                    setFieldValue('cost', value / Number(values.amount));
                    setCostTotal(value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={10} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onClick={handleChange}
                      onChange={() => setFieldValue('barCode', '')}
                    />
                  }
                  label="Código de barras"
                />
                <Field
                  disabled={checked}
                  f
                  name="barCode"
                  label={
                    checked ? 'Gerado automaticamente' : 'Código de barras'
                  }
                  placeholder="Use o leitor de cod. barras"
                  style={{ width: '70%' }}
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrencyTextField
                  type="tel"
                  value={calculateMarginByValue(
                    values.cost ? values.cost : 1,
                    values.value ? values.value : 1,
                  )}
                  label="Margem"
                  variant="standard"
                  decimalCharacter=","
                  error={Boolean(errors.value)}
                  helperText={errors.value}
                  digitGroupSeparator="."
                  currencySymbol="%"
                  outputFormat="string"
                  onChange={(ev, value) =>
                    setFieldValue('margin', value) &&
                    setFieldValue(
                      'value',
                      calculateValueByMargin(values.cost, value),
                    )
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={10} md={3}>
                <CurrencyTextField
                  type="tel"
                  value={calculateMarkupByValue(
                    values.cost ? values.cost : 1,
                    values.value ? values.value : 1,
                  )}
                  label="Markup"
                  variant="standard"
                  decimalCharacter=","
                  error={Boolean(errors.value)}
                  helperText={errors.value}
                  digitGroupSeparator="."
                  currencySymbol="%"
                  outputFormat="string"
                  onChange={(ev, value) => setFieldValue('markup', value)}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CurrencyTextField
                  type="tel"
                  value={calculateValueByMargin(
                    values.cost ? values.cost : 1,
                    values.margin ? values.margin : 2,
                  )}
                  label="Valor de venda"
                  variant="standard"
                  decimalCharacter=","
                  error={Boolean(errors.value)}
                  helperText={errors.value}
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  onChange={(ev, value) => setFieldValue('value', value)}
                  fullWidth
                />
              </Grid>
            </UIContainer>

            <UIContainer container direction="row">
              <Grid item xs={12}>
                <UIFormTitle title="Caracteristicas" />
              </Grid>
              {values.particulars && options !== null ? (
                Object.entries(options).map(([key, value]) => (
                  <Grid item xs={12} md={3}>
                    <InputLabel shrink id={key}>
                      {capitalize(key)}
                    </InputLabel>
                    <Field
                      name={`particulars.${key}`}
                      disabled={!values.category}
                      error={Boolean(errors.particulars)}
                      helperText={touched.particulars && errors.particulars}
                      labelId={key}
                      displayEmpty
                      fullWidth
                      as={Select}
                    >
                      <MenuItem disabled value="">
                        <em>Informe o(a) {key}</em>
                      </MenuItem>
                      {typeof value === 'object'
                        ? value.map((vl) => (
                            <MenuItem key={vl} value={vl}>
                              {vl}
                            </MenuItem>
                          ))
                        : value && (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          )}
                    </Field>
                  </Grid>
                ))
              ) : (
                <Typography noWrap variant="inherit">
                  Selecione uma categoria
                </Typography>
              )}
            </UIContainer>

            <UIFormContainerSave
              container
              justify="center"
              direction="row"
              messageSucess={registerInfo.data && registerInfo.data.message}
              messageError="Alguma coisa está errada, verifique os campos"
              error={registerInfo.error}
              loading={registerInfo.loading || ''}
              data={registerInfo.data}
              setrequestinfo={setRegisterInfo}
              setFormValues={() => setOldValues(null)}
            />

            <UIModalOldForm
              open={open}
              changeModal={changeModal}
              setFormValues={setFormValues}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProductNew;
