import React from 'react';
import CashierNew from '../../../components/Cashier/New/cashierNew';
import PageContent from '../../../components/Main/Content/mainContent';

const CaixasNovo = () => (
  <PageContent>
    <CashierNew />
  </PageContent>
);

export default CaixasNovo;
