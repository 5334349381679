import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  users: Yup.array().min(0),
  startDate: Yup.string()
    .required('Campo obrigatório')
    .nullable('Campo obrigatório'),
  initialValue: Yup.number().required('Campo obrigatório'),
});
