import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import UIContainer from '../../../UI/Container/UIContainer';
import './selectClosure.css';

import CashierLogisticOpen from '../../Logistic/Open/cashierLogisticOpen';

import StoreContext from '../../../Store/context';

const CashierClosures = () => {
  const { setModalCashierSelect, modalCashierSelect, credencials } =
    useContext(StoreContext);
  const { cashSession } = credencials;

  if (cashSession)
    return (
      <UIContainer container>
        <Grid
          container
          item
          justify="center"
          alignItems="center"
          className="cashier-logistic-open"
        >
          <Typography
            className="cashier-logistic-open__text"
            onClick={() => setModalCashierSelect(!modalCashierSelect)}
            style={{ fontSize: 20 }}
          >
            <span>Ainda não há fechamentos para este caixa</span>
          </Typography>
        </Grid>
      </UIContainer>
    );
  return <CashierLogisticOpen />;
};

export default CashierClosures;
