import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useApi from '../../../utils/useApi';
import CostsNew from '../New/costsNew';
import CostsSearch from '../Search/costsSearch';

const CostsShow = () => {
  // verificar se é mobile e constantes para versão mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [collapseNew, setCollapseNew] = useState(false);
  const [collapseSearch, setCollapseSearch] = useState(true);

  const [suppliersLoadSelect, suppliersInfo] = useApi({
    method: 'get',
    url: '/suppliers',
  });

  function changeCollapseSearch() {
    setCollapseNew(!collapseNew);
    setCollapseSearch(!collapseSearch);
  }

  function changeCollapseNew() {
    setCollapseNew(!collapseNew);
    setCollapseSearch(!collapseSearch);
  }

  useEffect(() => {
    suppliersLoadSelect();
  }, []);

  return (
    <>
      <CostsNew
        collapseNew={collapseNew}
        changeCollapseNew={changeCollapseNew}
        suppliersInfo={suppliersInfo}
      />
      <CostsSearch
        mobile={mobile}
        collapseSearch={collapseSearch}
        changeCollapseSearch={changeCollapseSearch}
        suppliersInfo={suppliersInfo}
      />
    </>
  );
};

export default CostsShow;
